import { filterArray } from '../../Services/api/common';
import deviceType from './consts';

const deviceList = [];
const cameraStateById = {};

const defaultState = {
  deviceList,
  cameraStateById
};

// eslint-disable-next-line default-param-last
function devicesReducer(state = defaultState, action) {
  switch (action.type) {
    case deviceType.updateDeviceList: {
      const res = action.payload.map(item => ({
        ...item,
        cameraSystemId: item.cameraSystemId,
        cameraName: item.name,
        panoramaStreamUrl: item.panoramaStreamUrl,
        tacticalStreamUrl: item.tacticalStreamUrl,
        // individualStreamUrl: {
        //   0: '',
        //   1: '',
        //   2: ''
        // },
        cameraStream: item.cameraStream,
        portable: item.isPortable
      }));
      return { ...state, deviceList: res };
    }
    case deviceType.getCameraStateById: {
      const res = filterArray(state.deviceList, 'cameraSystemId', action.payload)[0];
      return {
        ...state,
        cameraStateById: res
      };
    }

    case deviceType.updateRecordingListForCameras: {
      const inProgress = action.payload.filter(item => item.recorded === false);
      const toBeUpload = action.payload.filter(item => item.recorded === true);
      const res = state.deviceList.map(item => {
        return {
          ...item,
          inProgressList:
            inProgress
              .filter(recording => recording.cameraSystemId === item.cameraSystemId)
              .map(recording => ({ ...recording, cameraName: item.cameraName })) || [],
          toBeUploadedList:
            toBeUpload
              .filter(recording => recording.cameraSystemId === item.cameraSystemId)
              .map(recording => ({ ...recording, cameraName: item.cameraName })) || []
        };
      });
      return {
        ...state,
        deviceList: res
      };
    }

    case deviceType.updateScheduledListForCameras: {
      const res = state.deviceList.map(item => {
        return {
          ...item,
          scheduleList:
            action.payload
              .filter(recording => recording.cameraSystemId === item.cameraSystemId)
              .map(recording => ({ ...recording, cameraName: item.cameraName })) || []
        };
      });
      return {
        ...state,
        deviceList: res
      };
    }

    case deviceType.updateStreamLinks: {
      const recordings = action.payload.result;
      const res = state.deviceList.map(device => {
        return device.cameraSystemId !== action.payload.cameraSystemId
          ? device
          : { ...device, individualStreamUrl: recordings };
      });
      const updatedState = filterArray(res, 'cameraSystemId', action.payload.cameraSystemId)[0];
      return { ...state, deviceList: res, cameraStateById: updatedState };
    }

    default:
      return state;
  }
}

export default devicesReducer;
