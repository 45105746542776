import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VideoPlayer from '../../Components/VideoPlayer/VideoPlayer';
import RecordTile from '../../Components/RecordTile/RecordTile';
import { parameters } from '../../Theme/theme';
import { filterArray, findCameraId, findRecordId } from '../../Services/api/common';
import {
  getEventDataSelector,
  getInProgressListSelector,
  getScheduledListSelector
} from '../../Redux/recordings/selectors';
import { cameraStateByIdSelector } from '../../Redux/devices/selectors';
import { getCameraStateByIdAction, getStreamLinksAction } from '../../Redux/devices/actions';

const ScheduledView = locale => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recordId = findRecordId(location);
  const scheduledList = useSelector(getScheduledListSelector);
  const recordingList = useSelector(getInProgressListSelector);
  const eventData = useSelector(getEventDataSelector);
  const scheduledData = filterArray(scheduledList, 'recordId', recordId);
  const recordingData = filterArray(recordingList, 'recordId', recordId);
  const cameraSystemId = findCameraId(location);
  const deviceInfo = useSelector(cameraStateByIdSelector);
  const [streamLinks, setStreamLinks] = useState({
    individual: []
  });
  useEffect(() => {
    if (!deviceInfo?.cameraSystemId) {
      return;
    }
    setStreamLinks({
      individual: deviceInfo?.individualStreamUrl
    });
  }, [deviceInfo]);

  useEffect(() => {
    dispatch(getStreamLinksAction({ cameraSystemId, type: 'MSE' }));
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventData]);

  useEffect(() => {
    setStreamLinks({
      individual: deviceInfo?.individualStreamUrl
    });
  }, [deviceInfo]);

  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventData]);

  useEffect(() => {
    if (!deviceInfo?.cameraSystemId) {
      return;
    }
    if (scheduledData[0]) {
      return;
    }
    if (recordingData[0]) {
      navigate(`/device-list/${cameraSystemId}/watch/${recordingData[0].recordId}`);
      return;
    }
    navigate(`/device-list/${cameraSystemId}/scheduled-recordings`);
  }, [eventData]);

  return !scheduledData[0] ? null : (
    <RecordingWrapper>
      <VideoPlayer
        locale={locale}
        autoplay
        streamType="schedule"
        playerType="liveStream"
        streamLinks={streamLinks}
        buttonPane={(
          <>
            <ButtonPane>
              <BtnInner>
                <RecordTile tileView="flat" visible data={scheduledData[0]} panelType="scheduled" />

                {/* <Button 
                    label={lang(locale).autofocus} 
                    onClick={() => {}} 
                    type="black" 
                    disabled
              /> */}
              </BtnInner>
            </ButtonPane>
          </>
        )}
      />
    </RecordingWrapper>
  );
};

export default ScheduledView;

const RecordingWrapper = styled.div(() => ({
  paddingTop: '80px',
  minHeight: `calc(100vh - ${parameters.footerHeight}px)`,
  '@media (max-width: 535px)': {
    paddingTop: '40px'
  },
  '@media (max-height: 420px)': {
    paddingTop: '40px'
  }
}));

const ButtonPane = styled.div(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '10px'
}));

const BtnInner = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center'
}));
