import axios from 'axios';

class HttpClient {
  constructor(config) {
    this.config = config;
    this.axiosInstance = axios.create(config);
  }

  setAuthToken(token) {
    if (token) {
      this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete this.axiosInstance.defaults.headers.common.Authorization;
    }
  }

  createFetch({ url, ...reqSettings }) {
    return async () => this.axiosInstance({ url, ...reqSettings });
  }
}

export default HttpClient;
