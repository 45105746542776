import dashjs from 'dashjs';

const dashPlayer = (streamLinks, videoIds) => {
  const refs = [];

  videoIds.forEach((item, index) => {
    refs.push({
      video: document.getElementById(item),
      link: streamLinks[index],
      dashjs: dashjs.MediaPlayer().create()
    });
  });

  refs.forEach(ref => {
    ref.dashjs.initialize(ref.video, ref.link, true);
  });
};

export default dashPlayer;
