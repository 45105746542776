/* eslint-disable no-console */
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  callCreateSchedule,
  callDeleteSchedule,
  callEditSchedule,
  callRecordStart,
  callRecordUpload,
  callRecordUploadAll,
  callRecordStop,
  callRecordDelete
} from '../Services/api/recordings';
import { callEnvironments, callUpdateUserLang } from '../Services/api/user';
import videosType from './recordings/consts';
import userType from './user/consts';
import { callCameraStreams } from '../Services/api/devices';
import deviceType from './devices/consts';
import { updateStreamLinksAction } from './devices/actions';
import { handleResponseAction } from './responseHandler/actions';
import { updateEnvironmentsAction } from './user/actions';

export function* createScheduleSaga(body) {
  try {
    yield call(callCreateSchedule, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'createNewSchedule' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'createNewSchedule' }));
  }
}
export function* deleteScheduledSaga(body) {
  try {
    yield call(callDeleteSchedule, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'deleteSchedule' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'deleteSchedule' }));
  }
}
export function* editScheduledSaga(body) {
  try {
    yield call(callEditSchedule, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'editSchedule' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'editSchedule' }));
  }
}

export function* recordStartSaga(body) {
  try {
    yield call(callRecordStart, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'recordStart' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'recordStart' }));
  }
}

export function* recordStopSaga(body) {
  try {
    yield call(callRecordStop, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'recordStop' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'recordStop' }));
  }
}

export function* recordDeleteSaga(body) {
  try {
    yield call(callRecordDelete, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'recordDelete' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'recordDelete' }));
  }
}

export function* recordUploadSaga(body) {
  try {
    yield call(callRecordUpload, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'recordUpload' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'recordUpload' }));
  }
}

export function* recordAllUploadSaga(body) {
  try {
    yield call(callRecordUploadAll, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'recordUploadAll' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'recordUploadAll' }));
  }
}

export function* updateUserLangSaga(body) {
  try {
    yield call(callUpdateUserLang, body.payload);
    yield put(handleResponseAction({ type: 'success', message: 'updateUserLanguage' }));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'updateUserLanguage' }));
  }
}

export function* getStreamLinksSaga(body) {
  try {
    const data = yield call(callCameraStreams, body.payload);
    yield put(
      updateStreamLinksAction({ result: data, cameraSystemId: body.payload.cameraSystemId })
    );
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'getStreamLinks' }));
  }
}

export function* getEnvironmentsSaga() {
  try {
    const data = yield call(callEnvironments);
    yield put(updateEnvironmentsAction(data));
  } catch (e) {
    yield put(handleResponseAction({ type: 'error', message: 'getStreamLinks' }));
  }
}

export function* sagaWatcher() {
  yield takeEvery(videosType.createSchedule, createScheduleSaga);
  yield takeEvery(videosType.deleteSchedule, deleteScheduledSaga);
  yield takeEvery(videosType.editSchedule, editScheduledSaga);
  yield takeEvery(videosType.stopRecording, recordStopSaga);
  yield takeEvery(videosType.startRecording, recordStartSaga);
  yield takeEvery(videosType.uploadRecording, recordUploadSaga);
  yield takeEvery(videosType.uploadAllRecordings, recordAllUploadSaga);

  yield takeEvery(userType.updateUserLang, updateUserLangSaga);
  yield takeEvery(userType.getEnvironments, getEnvironmentsSaga);
  yield takeEvery(deviceType.getStreamLinks, getStreamLinksSaga);
}

// export function* recordEditSaga(body) {
//   try {
//     yield call(callRecordEdit, body.payload);
//   } catch (e) {
//     handleError(e, 'record edit');
//   }
// }

// yield takeEvery(videosType.editRecording, recordEditSaga);
// yield takeEvery(videosType.deleteRecording, recordDeleteSaga);
