import httpCore from '../httpCore';

let idToken = '';
let auth0Id = '';
export const setIdToken = token => {
  idToken = token['https://kogniasports.com/app_metadata']?.kognia_user_id;
  auth0Id = token.sub;
};

export const callUpdateUserLang = async payload => {
  if (!idToken) {
    return null;
  }
  const endpoint = httpCore.createFetch({
    url: '/setLanguage',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      userId: auth0Id,
      ...payload
    }
  });
  const res = await endpoint();
  return res.data;
};

export const callEnvironments = async () => {
  if (!idToken) {
    return null;
  }
  const endpoint = httpCore.createFetch({
    url: `/authorization/${idToken}/clientIds`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const res = await endpoint();
  return res.data;
};
