import React from 'react';
import styled from 'styled-components';
import  logo from '../assets/logo.gif'

const Preloader = () => (
  <PreloaderWrapper>
    <img src={logo} alt="loading..." />
  </PreloaderWrapper>
);

export default Preloader;

const PreloaderWrapper = styled.div(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative,'
}));

