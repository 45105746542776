import React from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { colors, zIndexes } from '../../Theme/theme';
import { lang } from '../../Langs/langSettings';
import { ReactComponent as CloseCross } from '../../assets/close-cross.svg';
import { ReactComponent as PenPic } from '../../assets/edit-pen.svg';
import { ReactComponent as TrashPic } from '../../assets/trash-bin.svg';
// import { ReactComponent as PauseSign } from '../../assets/pause-sign.svg';
import { ReactComponent as StopSign } from '../../assets/stop-sign.svg';
// import { ReactComponent as CloudPic } from '../../assets/upload-to-cloud.svg';
import { stopRecordingAction } from '../../Redux/recordings/actions';

const PopUp = ({
  openModal,
  popUpOpened,
  togglePopUp,
  closePopUp,
  locale,
  data,
  panelType,
  // onUploadClickHandler,
  tileView
}) => {
  const dispatch = useDispatch();
  const screenHeight = window.innerHeight;

  const onStopClickHandler = () => {
    dispatch(stopRecordingAction({ recordId: data.recordId }));
    closePopUp();
  };
  // const isOnUploading = data.uploadStatus === 'ON_UPLOADING' || data.uploadStatus === 'PENDING';

  const outOfScreenHandler = el => {
    const diff = screenHeight - el.getBoundingClientRect().bottom;
    if (diff < 0) {
      window.scrollBy({
        top: -diff + 10,
        left: 0,
        behavior: 'smooth'
      });
    }
  };
  return (
    <PopUpWrapper
      ref={el => {
        if (!el) return;
        outOfScreenHandler(el);
      }}
      opened={popUpOpened}
      onClick={e => e.stopPropagation()}
      tileView={tileView}
    >
      <PopUpHeader>
        <StyledCloseCross onClick={togglePopUp} />
      </PopUpHeader>

      {panelType === 'scheduled' && (
        <>
          <PopUpItem onClick={() => openModal('schedule')}>
            <PenPic />
            <span>{lang(locale).edit}</span>
          </PopUpItem>

          <PopUpItem onClick={() => openModal('delete')}>
            <TrashPic />
            <span>{lang(locale).delete}</span>
          </PopUpItem>
        </>
      )}

      {panelType === 'inProgress' && (
        <>
          <PopUpItem onClick={() => openModal('video')}>
            <PenPic />
            <span>{lang(locale).info}</span>
          </PopUpItem>

          {/* <PopUpItem disabled>
            <PauseSign />
            <span>{lang(locale).pause}</span>
          </PopUpItem> */}

          <PopUpItem onClick={onStopClickHandler}>
            <StopSign />
            <span>{lang(locale).stop}</span>
          </PopUpItem>
        </>
      )}

      {panelType === 'toBeUpload' && (
        <>
          <PopUpItem onClick={() => openModal('video')}>
            <PenPic />
            <span>{lang(locale).info}</span>
          </PopUpItem>

          {/* {isOnUploading && (
            <PopUpItem onClick={() => {}} disabled>
              <StopSign />
              <span>{lang(locale)['stop uploading']}</span>
            </PopUpItem>
          )} */}

          {/* {!isOnUploading && (
            <PopUpItem onClick={onUploadClickHandler} disabled={!data.fulfilled}>
              <StyledCloudPicInPane />
              <span>{lang(locale)['upload to the cloud']}</span>
              {!data.fulfilled && (
                <RequiredFieldsNote>{lang(locale)['fill required fields']}</RequiredFieldsNote>
              )}
            </PopUpItem>
          )} */}

            {/* <PopUpItem onClick={() => openModal('delete')}>
              <TrashPic />
              <span>{lang(locale).delete}</span>
            </PopUpItem> */}
         
        </>
      )}
    </PopUpWrapper>
  );
};

export default PopUp;

// const StyledCloudPicInPane = styled(CloudPic)(() => ({
//   marginLeft: 0,
//   width: '15px',
//   fill: colors.scheduledGray,
//   cursor: 'pointer',
//   '&:hover': {
//     fill: colors.scheduledGray
//   },
//   '&:active, &:hover:active': {
//     fill: colors.scheduledGray
//   }
// }));

const PopUpWrapper = styled.div(props => ({
  display: props.opened ? 'flex' : 'none',
  position: 'absolute',
  flexDirection: 'column',
  justifyContent: 'space-around',
  top: props.tileView === 'flat' ? '75%' : '125%',
  right: props.tileView === 'flat' ? '50px' : '10px',
  width: '230px',
  border: `1px solid ${colors.disabledBtn}`,
  borderRadius: '4px',
  backgroundColor: colors.white,
  paddingBottom: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  cursor: 'default',
  zIndex: zIndexes.modalWrapper,
  '@media(max-width: 320px)': {
    width: '230px',
    padding: '10px'
  }
}));

const PopUpHeader = styled.div(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
}));
const StyledCloseCross = styled(CloseCross)(() => ({
  padding: '5px 10px 0 10px',
  height: '30px',
  width: '32px',
  cursor: 'pointer',
  fill: colors.scheduledGray,
  '&:hover': {
    fill: colors.hoverBlackBtn
  }
}));
const PopUpItem = styled.div(props => ({
  height: '40px',
  padding: '0 15px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 400,
  fontSize: '14px',
  cursor: props.disabled ? 'default' : 'pointer',
  borderBottom: `1px solid ${colors.white}`,
  color: props.disabled ? colors.disabledInputText : colors.defaultInputText,
  '&:hover': {
    backgroundColor: props.disabled ? colors.white : colors.disabledPanel,
    transition: '0.3s'
  },
  '&:active:not([disabled]), &:active:hover:not([disabled])': {
    backgroundColor: colors.disabledPanel,
    transition: '0.3s'
  },
  '& > span': {
    paddingLeft: '10px'
  },
  '& > svg': {
    width: '15px',
    fill: props.disabled ? colors.disabledInputText : colors.scheduledGray,
    cursor: props.disabled ? 'default' : 'pointer'
  }
}));

// const RequiredFieldsNote = styled.div(() => ({
//   width: '80%',
//   color: colors.lightRed,
//   fontSize: '12px',
//   lineHeight: '12px',
//   position: 'absolute',
//   top: '27px',
//   left: '40px'
// }));

PopUp.propTypes = {
  openModal: PropTypes.func,
  popUpOpened: PropTypes.bool,
  togglePopUp: PropTypes.func,
  closePopUp: PropTypes.func,
  // onUploadClickHandler: PropTypes.func,
  panelType: PropTypes.oneOf(['scheduled', 'toBeUpload', 'inProgress']),
  locale: PropTypes.string,
  tileView: PropTypes.oneOf(['default', 'flat']),
  data: PropTypes.shape({
    cameraName: PropTypes.string,
    name: PropTypes.string,
    recordId: PropTypes.string,
    duration: PropTypes.number,
    type: PropTypes.string,
    homeTeamId: PropTypes.string,
    awayTeamId: PropTypes.string,
    startRecordingTime: PropTypes.number,
    endRecordingTime: PropTypes.number,
    uploadStatus: PropTypes.oneOf(['READY_FOR_UPLOADING', 'PENDING', 'ON_UPLOADING', 'ERROR']),
    fulfilled: PropTypes.bool
  })
};

PopUp.defaultProps = {
  openModal: () => {},
  popUpOpened: false,
  togglePopUp: () => {},
  closePopUp: () => {},
  // onUploadClickHandler: () => {},
  panelType: 'scheduled',
  locale: 'en-US',
  tileView: 'default',
  data: {
    cameraName: '',
    name: '',
    recordId: '',
    duration: 0,
    type: 'training',
    homeTeamId: 'Home Team',
    awayTeamId: 'Away Team',
    startRecordingTime: 0,
    endRecordingTime: 0,
    uploadStatus: 'READY_FOR_UPLOADING',
    fulfilled: false
  }
};
