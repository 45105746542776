/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-destructuring */

import { Utf8ArrayToStr } from '../../../Services/api/common';

const msePlayer = (streamLinks, videoIds) => {
  const protocol = location.protocol.indexOf('s') !== -1 ? 'wss' : 'ws';
  const refs = [];

  videoIds.forEach((item, index) => {
    refs.push({
      video: document.getElementById(item),
      link: `${protocol}://${streamLinks[index]}`,
      streamingStarted: false
    });
  });

  const loadPacket = (sourceBuffer, queue, streamObj) => {
    if (!sourceBuffer || (!sourceBuffer.updating && queue.length > 0)) {
      const inp = queue.shift();
      sourceBuffer.appendBuffer(inp);
    } else {
      streamObj.streamingStarted = false;
    }
  };

  const pushPacket = (arr, sourceBuffer, queue, streamObj) => {
    if (!sourceBuffer) {
      return;
    }
    if (!streamObj.streamingStarted && !sourceBuffer.updating && queue.length > 0) {
      sourceBuffer.appendBuffer(arr);
      streamObj.streamingStarted = true;
      return;
    }
    queue.push(arr);
    if (!sourceBuffer.updating) {
      loadPacket(sourceBuffer, queue, streamObj);
    }
  };

  const opened = (ms, streamObj) => {
    const queue = [];
    let sourceBuffer;
    let mimeCodec;
    let decodedArr;
    const ws = new WebSocket(streamObj.link);
    ws.binaryType = 'arraybuffer';

    ws.onopen = event => {
      console.log('Opened');
    };
    ws.onmessage = event => {
      if (ms.readyState !== 'open') return;
      const data = new Uint8Array(event.data);
      if (data[0] === 9) {
        decodedArr = data.slice(1);
        window.TextDecoder
          ? (mimeCodec = new TextDecoder('utf-8').decode(decodedArr))
          : (mimeCodec = Utf8ArrayToStr(decodedArr));
        sourceBuffer = ms.addSourceBuffer(`video/mp4; codecs="${mimeCodec}"`);
        sourceBuffer.mode = 'segments';
        sourceBuffer.addEventListener('updatestart', function(e) {});
        sourceBuffer.addEventListener('update', function(e) {
          if (queue.length > 0 && !sourceBuffer.updating) {
            sourceBuffer.appendBuffer(queue.shift());
          }
        });
        sourceBuffer.addEventListener('updateend', function(e) {
          loadPacket(sourceBuffer, queue, streamObj);
        });
        sourceBuffer.addEventListener('error', function(e) {});
        sourceBuffer.addEventListener('abort', function(e) {});

        loadPacket(sourceBuffer, queue, streamObj);
      } else {
        pushPacket(event.data, sourceBuffer, queue, streamObj);
      }
    };
  };

  const startup = (ms, streamObj) => {
    ms.addEventListener('sourceopen', function(e) {
      opened(ms, streamObj);
    });
    ms.addEventListener('sourceended', function(e) {});
    ms.addEventListener('sourceclose', function(e) {});
    ms.addEventListener('error', function(e) {});
    streamObj.video.src = window.URL.createObjectURL(ms);
  };

  refs.forEach(ref => startup(new MediaSource(), ref));
};

export default msePlayer;
