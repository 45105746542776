import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import RedRecordSign from '../StatusMarkers/RedRecordSign';
import ToBeUploadStatus from '../StatusMarkers/ToBeUploadStatus';
import { colors, zIndexes } from '../../Theme/theme';
import PopUp from './PopUp';
import { ReactComponent as CameraPic } from '../../assets/camera.svg';
import { ReactComponent as DotsPic } from '../../assets/vertical-dots.svg';
import { ReactComponent as CalendarPic } from '../../assets/calendar.svg';
import { ReactComponent as TimerPic } from '../../assets/timer.svg';
import { ReactComponent as SmallCameraPic } from '../../assets/camera-small.svg';
import { ReactComponent as CloudPic } from '../../assets/upload-to-cloud.svg';
import { ReactComponent as FlagPic } from '../../assets/flag.svg';
import { ReactComponent as PlayTrianglePic } from '../../assets/play-triangle.svg';
import PanelTemplate from '../TileWrapper';
import { lang } from '../../Langs/langSettings';
import ScheduledModal from '../../Containers/ModalWindows/ScheduledModal';
import VideoModal from '../../Containers/ModalWindows/VideoModal';
import DeleteRecordModal from '../../Containers/ModalWindows/DeleteRecordModal';
import { findCameraId, recordTimer } from '../../Services/api/common';
import StopRecordModal from '../../Containers/ModalWindows/StopRecordModal';

const tilePicture = {
  scheduled: {
    color: colors.disabledBtn,
    hoverColor: colors.scheduledGray,
    active: colors.activePurpleBtn,
    activeBorder: 'none'
  },
  toBeUpload: {
    color: colors.defaultPurpleBtn,
    hoverColor: colors.hoverPurpleBtn,
    active: colors.activePurpleBtn,
    activeBorder: 'none'
  },
  inProgress: {
    color: colors.defaultBlackBtn,
    hoverColor: colors.hoverBlackBtn,
    active: colors.defaultBlackBtn,
    activeBorder: `0px 0px 0px 4px ${colors.pressedBtnBorder}`
  }
};

const RecordTile = ({
  data,
  panelType,
  onUploadClick,
  locale,
  showError,
  tileView,
  visible,
  children,
  onPicClickNavLink,
  nearestScheduled
}) => {
  const navigate = useNavigate();
  const [popUpOpened, setPopUpOpened] = useState(false);
  const [videoModalOpened, setVideoModalOpened] = useState(false);
  const [scheduledModalOpened, setScheduledModalOpened] = useState(false);
  const [deleteModalOpened, setDeletedModalOpened] = useState(false);
  const [stopModalOpened, setStopModalOpened] = useState(false);
  const location = useLocation();
  const [gameTimer, setGameTimer] = useState(0);
  const cameraSystemId = findCameraId(location);
  const timer = useRef();

  const openModal = modal => {
    modal === 'video' && setVideoModalOpened(true);
    modal === 'schedule' && setScheduledModalOpened(true);
    modal === 'delete' && setDeletedModalOpened(true);
    setPopUpOpened(false);
  };
  const closeModal = modal => {
    modal === 'video' && setVideoModalOpened(false);
    modal === 'schedule' && setScheduledModalOpened(false);
    modal === 'delete' && setDeletedModalOpened(false);
  };

  const togglePopUp = () => setPopUpOpened(!popUpOpened);
  const closePopUp = () => setPopUpOpened(false);

  useEffect(() => {
    if (popUpOpened) {
      document.addEventListener('click', closePopUp);
    } else {
      document.removeEventListener('click', closePopUp);
    }
    return () => document.removeEventListener('click', closePopUp);
  }, [popUpOpened]);

  const onUploadClickHandler = () => {
    data.fulfilled && onUploadClick();
    data.fulfilled && closePopUp();
  };

  const handleNavClick = useCallback(() => navigate(onPicClickNavLink, { cameraSystemId }), []);

  useEffect(() => {}, [gameTimer]);
  useEffect(() => {
    if (panelType === 'inProgress' && data.endRecordingTime) {
      recordTimer(timer.current, data.endRecordingTime, val => setGameTimer(val));
    }
    return () => clearTimeout(timer.current);
  }, [data]);

  const namesAndValues = {
    recordingName: data?.name || moment(data.startRecordingTime).format('DD/MM/YYYY HH:mm:ss'),
    startData: moment(data.startRecordingTime).format('DD MMM YYYY'),
    startEndTime: `${moment(data.startRecordingTime).format('HH:mm')} - ${moment(
      data.endRecordingTime
    ).format('HH:mm')}`,
    recordingLength: `${Math.ceil((data.endRecordingTime - data.startRecordingTime) / 60000)}'`,
    gameOrTraining: data.type === 'TRAINING' ? lang(locale).training : lang(locale).game
  };

  const isReadyToUpload =
    panelType === 'toBeUpload' &&
    data.fulfilled &&
    (data.uploadStatus === 'READY_FOR_UPLOADING' || data.uploadStatus === 'ERROR');

  const isNeedToShowError = () => {
    if (panelType !== 'toBeUpload') {
      return null;
    }
    if (!data.fulfilled && showError) {
      return <ToBeUploadStatus status="VALID_ERROR" locale={locale} />;
    }
    if (data.errorMessage && showError) {
      return <ToBeUploadStatus status="ERROR" locale={locale} />;
    }
    return null;
  };
  return (
    <>
      {tileView === 'default' && (
        <PanelTemplate
          visible={visible}
          innerContent={(
            <>
              {(panelType === 'inProgress' || panelType === 'toBeUpload') && (
                <StyledPlayPic type={panelType} onClick={handleNavClick} />
              )}
              {panelType === 'scheduled' && (
                <StyledCameraPic type={panelType} onClick={handleNavClick} />
              )}
              <PopUp
                openModal={openModal}
                locale={locale}
                data={data}
                closePopUp={closePopUp}
                popUpOpened={popUpOpened}
                togglePopUp={togglePopUp}
                panelType={panelType}
                onUploadClickHandler={onUploadClickHandler}
              />
            </>
          )}
          bottomContent={(
            <BottomContent>
              <UpperRow>
                <RecordName panelType={panelType} tileView="default">
                  <NameInner>{namesAndValues.recordingName}</NameInner>
                  {/* {isReadyToUpload && <StyledCloudPic onClick={onUploadClickHandler} />} */}
                  <Decoration />
                </RecordName>

                {panelType === 'inProgress' && (
                  <RedRecordSign margin="2px 30px" whiteBackground={1} locale={locale} />
                )}
                {panelType === 'toBeUpload' && (
                  <ToBeUploadStatus status={data.uploadStatus} locale={locale} />
                )}
                {isNeedToShowError()}

                <StyledDotsPic onClick={togglePopUp} />
              </UpperRow>

              <BottomRow>
                {panelType === 'scheduled' && (
                  <>
                    <InfoItem>
                      <CalendarPic fill={colors.scheduledGray} />
                      {namesAndValues.startData}
                    </InfoItem>

                    <InfoItem>
                      <TimerPic />
                      {namesAndValues.startEndTime}
                    </InfoItem>

                    <InfoItem>
                      <SmallCameraPic fill={colors.scheduledGray} />
                      {data.cameraName}
                    </InfoItem>
                  </>
                )}

                {panelType !== 'scheduled' && (
                  <>
                    <InfoItem>
                      <FlagPic fill={colors.scheduledGray} />
                      {namesAndValues.gameOrTraining}
                    </InfoItem>

                    <InfoItem>
                      <CalendarPic fill={colors.scheduledGray} />
                      {namesAndValues.startData}
                    </InfoItem>

                    <InfoItem>
                      <TimerPic fill={colors.scheduledGray} />
                      {panelType === 'inProgress' && gameTimer}
                      {panelType === 'toBeUpload' && namesAndValues.recordingLength}
                    </InfoItem>
                  </>
                )}
              </BottomRow>
            </BottomContent>
          )}
        />
      )}
      {tileView === 'flat' && ( // tiles under streams
        <>
          <PopUp
            openModal={openModal}
            locale={locale}
            data={data}
            closePopUp={closePopUp}
            popUpOpened={popUpOpened}
            togglePopUp={togglePopUp}
            panelType={panelType}
            onUploadClickHandler={onUploadClickHandler}
            tileView="flat"
          />
          <FlatPaneWrapper visible={visible}>
            <FlatPaneInfoContainer panelType={panelType}>
              <RecordName panelType={panelType} tileView="flat">
                <NameInner>{namesAndValues.recordingName}</NameInner>
                {isReadyToUpload && <StyledCloudPic onClick={onUploadClickHandler} />}
                <Decoration />
              </RecordName>
              <div>
                <InfoItem>
                  <FlagPic fill={colors.scheduledGray} />
                  {namesAndValues.gameOrTraining}
                </InfoItem>
                <InfoItem>
                  <CalendarPic fill={colors.scheduledGray} />
                  {namesAndValues.startData}
                </InfoItem>
                <InfoItem>
                  <TimerPic />
                  {panelType === 'inProgress' && gameTimer}
                  {panelType === 'toBeUpload' && namesAndValues.recordingLength}
                  {panelType === 'scheduled' && namesAndValues.startEndTime}
                </InfoItem>
              </div>
            </FlatPaneInfoContainer>

            <FlatPaneButtonContainer visible={visible}>{children}</FlatPaneButtonContainer>

            <StyledDotsWrapper>
              <StyledDotsPic onClick={togglePopUp} />
            </StyledDotsWrapper>
          </FlatPaneWrapper>
        </>
      )}
      <ScheduledModal
        modalOpened={scheduledModalOpened}
        closeModal={() => closeModal('schedule')}
        cameraSystemId={cameraSystemId}
        defaultValue={data}
        locale={locale}
      />
      <VideoModal
        modalOpened={videoModalOpened}
        closeModal={() => closeModal('video')}
        defaultValue={{
          ...data,
          name: data.name || moment(data.startRecordingTime).format('DD/MM/YYYY HH:mm:ss')
        }}
        locale={locale}
      />
      <DeleteRecordModal
        modalOpened={deleteModalOpened}
        closeModal={() => closeModal('delete')}
        recordId={data.recordId}
        cameraSystemId={cameraSystemId}
        isSchedule={panelType === 'scheduled'}
        locale={locale}
        nearestScheduled={nearestScheduled}
      />
      <StopRecordModal
        modalOpened={stopModalOpened}
        closeModal={() => closeModal('stop')}
        recordId={data.recordId}
        locale={locale}
      />
    </>
  );
};

export default RecordTile;

const StyledCameraPic = styled(CameraPic)(props => ({
  fill: tilePicture[props.type].color,
  cursor: 'pointer',
  '&:hover': {
    fill: tilePicture[props.type].hoverColor,
    transition: '0.3s'
  },
  '&:active, &:active:hover': {
    fill: tilePicture[props.type].active,
    boxShadow: tilePicture[props.type].activeBorder,
    transition: '0.3s'
  }
}));

const StyledPlayPic = styled(PlayTrianglePic)(props => ({
  fill: tilePicture[props.type].color,
  width: '37px',
  height: '37px',
  cursor: 'pointer',
  borderRadius: '50%',
  '&:hover': {
    fill: tilePicture[props.type].hoverColor,
    transition: '0.3s'
  },
  '&:active, &:active:hover': {
    fill: tilePicture[props.type].active,
    boxShadow: tilePicture[props.type].activeBorder,
    transition: '0.3s'
  }
}));

const BottomContent = styled.div(() => ({
  width: '100%',
  height: '100%',
  padding: '10px 7px 0 1px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflowX: 'hidden',
  position: 'relative',
  zIndex: zIndexes.recordTileBottomPane
}));

const UpperRow = styled.div(() => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 500,
  fontSize: '14px'
}));

const BottomRow = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 400,
  fontSize: '12px',
  color: colors.scheduledGray,
  flexWrap: 'wrap'
}));

const InfoItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '21px',
  marginBottom: '5px',
  cursor: 'default',
  '& > svg': {
    marginRight: '11px',
    width: '14px',
    height: '14px'
  }
}));

const RecordName = styled.div(props => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  width: props.panelType !== 'inProgress' ? '90%' : '70%'
}));

const NameInner = styled.div(() => ({
  fontWeight: 500,
  fontSize: '14px',
  color: colors.defaultBlackBtn,
  margin: '2px 0',
  position: 'relative',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  cursor: 'default',
  maxWidth: '100%'
}));

const StyledCloudPic = styled(CloudPic)(() => ({
  marginLeft: '7px',
  width: '15px',
  minWidth: '15px',
  zIndex: zIndexes.recordTileCloudPic,
  fill: colors.scheduledGray,
  cursor: 'pointer',
  '&:hover': {
    fill: colors.defaultPurpleBtn
  },
  '&:active, &:hover:active': {
    fill: colors.activePurpleBtn
  }
}));

const StyledDotsPic = styled(DotsPic)(() => ({
  cursor: 'pointer',
  fill: colors.scheduledGray,
  width: '30px',
  '&:hover': {
    fill: colors.defaultBlackBtn,
    transition: '0.3s'
  },
  '&:active:hover, &:active': {
    fill: colors.activePurpleBtn,
    transition: '0.3s'
  }
}));

const Decoration = styled.div(() => ({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '25px',
  fontSize: '12px',
  background:
    'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 70%, rgba(255,255,255,1) 95%)'
}));

const FlatPaneWrapper = styled.div(props => ({
  display: props.visible ? 'flex' : 'none',
  position: 'relative',
  width: '95%',
  padding: '5px 10px',
  backgroundColor: colors.white,
  fontWeight: 400,
  fontSize: '12px',
  borderRadius: '4px'
}));

const FlatPaneInfoContainer = styled.div(props => ({
  width: props.panelType !== 'inProgress' ? '90%' : '33%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  flexWrap: 'wrap',
  '@media(max-width: 350px)': {
    width: '95%'
  },
  '& > div': {
    width: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '5px'
  },
  '& > div: last-child': {
    '@media(max-width: 350px)': {
      flexWrap: 'wrap'
    }
  }
}));

const FlatPaneButtonContainer = styled.div(props => ({
  width: '33%',
  height: '100%',
  display: props.visible ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  '@media(max-width: 800px)': {
    justifyContent: 'flex-end',
    width: '70%'
  }
  // '@media(max-width: 480px)':{
  //   width: '95%',
  //   justifyContent: 'center',
  //   paddingTop: '10px',
  // },
}));

const StyledDotsWrapper = styled.div(() => ({
  width: '33%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '@media(max-width: 800px)': {
    width: '5%'
  }
}));

RecordTile.propTypes = {
  onUploadClick: PropTypes.func,
  panelType: PropTypes.oneOf(['scheduled', 'toBeUpload', 'inProgress']),
  locale: PropTypes.string,
  showError: PropTypes.bool,
  tileView: PropTypes.oneOf(['default', 'flat']),
  visible: PropTypes.bool,
  children: PropTypes.node,
  onPicClickNavLink: PropTypes.string,
  data: PropTypes.shape({
    cameraName: PropTypes.string,
    name: PropTypes.string,
    recordId: PropTypes.string,
    duration: PropTypes.number,
    type: PropTypes.string,
    homeTeamId: PropTypes.string,
    awayTeamId: PropTypes.string,
    startRecordingTime: PropTypes.number,
    endRecordingTime: PropTypes.number,
    uploadStatus: PropTypes.oneOf(['READY_FOR_UPLOADING', 'PENDING', 'ON_UPLOADING', 'ERROR']),
    fulfilled: PropTypes.bool,
    errorMessage: PropTypes.string
  })
};

RecordTile.defaultProps = {
  onUploadClick: () => {},
  panelType: 'scheduled',
  locale: 'en-US',
  showError: false,
  visible: true,
  tileView: 'default',
  children: React.createElement('div'),
  onPicClickNavLink: '',
  data: {
    cameraName: '',
    name: '',
    recordId: '',
    duration: 0,
    type: 'training',
    homeTeamId: 'Home Team',
    awayTeamId: 'Away Team',
    startRecordingTime: 0,
    endRecordingTime: 0,
    uploadStatus: 'READY_FOR_UPLOADING',
    fulfilled: false,
    errorMessage: ''
  }
};
