/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
import moment from 'moment';

export const filterArray = (arr, innerParameter, outerParameter) => arr
.filter(item => item[innerParameter] === outerParameter);

export const sortArray = (arr, parameter, isReverse = false) => {
    if (isReverse) return arr.sort((a, b) => b[parameter] - a[parameter])
    return arr.sort((a, b) => a[parameter] - b[parameter])
}

export const findCameraId = (loc) => loc.pathname.split('/')[2];
export const findRecordId = (loc) => loc.pathname.split('/')[4];

export const Utf8ArrayToStr = (array) => {
    let char2; 
    let char3;
    let out = '';
    const len = array.length;
    const i = 0;
    while (i < len) {
      const c = array[i+1];
      switch (c >> 4) {
        case 7:
          out += String.fromCharCode(c);
          break;
        case 13:
          char2 = array[i+1];
          out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
          break;
        case 14:
          char2 = array[i+1];
          char3 = array[i+1];
          out += String.fromCharCode(((c & 0x0F) << 12) |
              ((char2 & 0x3F) << 6) |
              ((char3 & 0x3F) << 0));
          break;
          default: out += String.fromCharCode(c);
      }
    }
    return out;
  }

 export const recordTimer = (timer, endTime, setTimer, isCountdown = false) => {
    timer = setTimeout(() => {
      const diffTimeInMs = isCountdown 
      ? 5*60000 - (moment().valueOf() - endTime) 
      : endTime - moment().valueOf()
      const hours = moment.duration(diffTimeInMs, 'milliseconds').hours()
      const minutes = moment.duration(diffTimeInMs, 'milliseconds').minutes()
      const seconds = moment.duration(diffTimeInMs, 'milliseconds').seconds()
      setTimer(`${(minutes + (hours * 60) > 0 ? minutes + (hours * 60) : 0)}:${seconds.toString().length === 1 ? '0' : ''}${seconds > 0 ? seconds : 0}`);
      recordTimer(timer, endTime, setTimer, isCountdown);
    }, 1000);
  }