import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors, zIndexes } from '../../Theme/theme';
import Button from '../Button/Button';
import { ReactComponent as CloseCross } from '../../assets/close-cross.svg';
import { lang } from '../../Langs/langSettings';

// Implement modal to a new page:
//  const [modalOpened, setModalOpened] = useState(false);
// const openModal = () => { setModalOpened(true); };
// const closeModal = () => { setModalOpened(false); };
// <ModalContainer isOpened={modalOpened} closeModal={closeModal} />

const ModalContainer = ({
  children,
  headerText,
  footerButton,
  isOpened,
  closeModal,
  width,
  className,
  subheader,
  locale
}) => {
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    setOpened(isOpened);
  }, [isOpened]);
  useEffect(() => {}, [opened]);

  return (
    <ModalWrapper opened={opened} className="modal-wrapper">
      <ModalMiddleware>
        <ModalInner
          width={width}
          className={`${className} modal-inner`}
          onMouseDown={e => e.stopPropagation()}
        >
          <InnerHeader>
            <div>
              {headerText}
              <Subheader>{subheader}</Subheader>
            </div>
            <StyledCloseCross onClick={closeModal} />
          </InnerHeader>
          {children}
          <InnerFooter hidden={footerButton.hidden}>
            <Button
              label={lang(locale).cancel}
              type="white"
              onClick={closeModal}
              width="110px"
              height="32px"
            />
            <Button
              label={footerButton.label}
              onClick={() => footerButton.onClick()}
              height="32px"
              disabled={footerButton.disabled}
            />
          </InnerFooter>
        </ModalInner>
      </ModalMiddleware>
    </ModalWrapper>
  );
};

export default ModalContainer;

const ModalWrapper = styled.div(props => ({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  minWidth: '100%',
  minHeight: '100vh',
  maxHeight: '100vh',
  margin: 0,
  zIndex: zIndexes.modalWrapper,
  backgroundColor: colors.modalWrapper,
  display: props.opened ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'flex-start',
  overflowY: 'scroll',
  whiteSpace: 'nowrap',
  '::-webkit-scrollbar': {
    display: 'none'
  }
}));

const ModalMiddleware = styled.div(() => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflowY: 'visible'
}));

const ModalInner = styled.div(props => ({
  width: props.width,
  minWidth: '240px',
  backgroundColor: colors.white,
  borderRadius: '8px',
  padding: '20px',
  maxWidth: '1200px',

  '@media(max-width: 450px)': {
    width: '100%',
    padding: '10px',
    borderRadius: '0'
  }
}));

const InnerHeader = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  color: colors.defaultBlackBtn,
  fontWeight: 500,
  fontSize: '20px',
  marginBottom: '20px',
  whiteSpace: 'normal',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
    overflow: 'hidden'
  }
}));

const Subheader = styled.span(() => ({
  color: colors.recordingDot,
  fontSize: '14px',
  fontWeight: 400,
  paddingTop: '5px'
}));

const StyledCloseCross = styled(CloseCross)(() => ({
  padding: '5px 0 5px 5px',
  height: '25px',
  width: '25px',
  cursor: 'pointer',
  fill: colors.scheduledGray,
  '&:hover': {
    fill: colors.hoverBlackBtn
  }
}));

const InnerFooter = styled.div(props => ({
  display: props.hidden ? 'none' : 'flex',
  justifyContent: 'flex-end',
  '& > div': {
    marginRight: 0,
    marginLeft: '10px',
    minWidth: '110px'
  }
}));

ModalContainer.propTypes = {
  children: PropTypes.node,
  headerText: PropTypes.string,
  isOpened: PropTypes.bool,
  subheader: PropTypes.string,
  footerButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool
  }),
  closeModal: PropTypes.func,
  width: PropTypes.string,
  className: PropTypes.string,
  locale: PropTypes.string
};

ModalContainer.defaultProps = {
  children: React.createElement('div'),
  headerText: 'Header text',
  isOpened: false,
  subheader: '',
  footerButton: {
    label: lang('en-US').apply,
    onClick: () => {},
    disabled: false,
    hidden: false
  },
  closeModal: () => {},
  width: 'fit-content',
  className: '',
  locale: 'en-US'
};
