import httpCore from '../httpCore';

let idToken = '';
export const setIdToken = (token) => {
  idToken = token['https://kogniasports.com/app_metadata']?.kognia_user_id;
};

// export const callScheduleList = async () => {
//   const endpoint = httpCore.createFetch({
//     url: '/schedule/scheduleList',
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     data: {
//       clientId: idToken,
//     },
//   });
//   const res = await endpoint();
//   return res.data;
// };

export const callCreateSchedule = async (payload) => {
  const endpoint = httpCore.createFetch({
    url: '/schedule/create',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { clientId: idToken, ...payload },
  });
  const res = await endpoint();
  return res.data;
};

export const callDeleteSchedule = async (payload) => {
  const endpoint = httpCore.createFetch({
    url: '/schedule/delete',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { clientId: idToken, ...payload },
  });
  const res = await endpoint();
  return res.data;
};

export const callEditSchedule = async (payload) => {
  const endpoint = httpCore.createFetch({
    url: '/schedule/edit',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { clientId: idToken, ...payload },
  });
  const res = await endpoint();
  return res.data;
};

// export const callRecordList = async (payload) => {
//   const endpoint = httpCore.createFetch({
//     url: '/record/list',
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     data: { clientId: idToken, ...payload },
//   });
//   const res = await endpoint();
//   return res.data;
// };

export const callRecordStart = async (payload) => {
  const endpoint = httpCore.createFetch({
    url: '/record/start',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { clientId: idToken, ...payload },
  });
  const res = await endpoint();
  return res.data;
};

export const callRecordStop = async (payload) => {
  const endpoint = httpCore.createFetch({
    url: '/record/stop',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { clientId: idToken, ...payload },
  });
  const res = await endpoint();
  return res.data;
};

// export const callRecordEdit = async (payload) => {
//   const endpoint = httpCore.createFetch({
//     url: '/record/edit',
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     data: { clientId: idToken, ...payload },
//   });
//   const res = await endpoint();
//   return res.data;
// };

export const callRecordUpload = async (payload) => {
  const endpoint = httpCore.createFetch({
    url: '/record/upload',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { clientId: idToken, recordId: payload },
  });
  const res = await endpoint();
  return res.data;
};

export const callRecordUploadAll = async (payload) => {
  const endpoint = httpCore.createFetch({
    url: '/record/uploadAll',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { clientId: idToken, cameraSystemId: payload },
  });
  const res = await endpoint();
  return res.data;
};

export const callRecordDelete = async (payload) => {
  const endpoint = httpCore.createFetch({
    url: '/record/delete',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { clientId: idToken, ...payload },
  });
  const res = await endpoint();
  return res.data;
};
