import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { colors } from '../../Theme/theme';
import { lang } from '../../Langs/langSettings';
import ScheduledModal from '../ModalWindows/ScheduledModal';
import Button from '../../Components/Button/Button';
import RecordTile from '../../Components/RecordTile/RecordTile';
import SubHeader from '../../Components/SubHeader/SubHeader';
import { cameraStateByIdSelector, getDeviceListSelector } from '../../Redux/devices/selectors';
import { getEventDataSelector } from '../../Redux/recordings/selectors';
import { filterArray, findCameraId } from '../../Services/api/common';
import { getCameraStateByIdAction } from '../../Redux/devices/actions';
import { getEnvironmentSelector } from '../../Redux/user/selectors';

const ScheduledPanel = ({ locale, BodyInner }) => {
  const location = useLocation();
  const cameraSystemId = findCameraId(location);
  const cameraInfo = useSelector(cameraStateByIdSelector);
  const dispatch = useDispatch();
  const [scheduleModalOpened, setScheduleModalOpened] = useState(false);
  const eventData = useSelector(getEventDataSelector);
  const deviceList = useSelector(getDeviceListSelector);
  const environmentList = useSelector(getEnvironmentSelector);
  const deviceInfo = filterArray(deviceList, 'cameraSystemId', cameraSystemId)[0];

  const openModal = () => setScheduleModalOpened(true);
  const closeModal = () => setScheduleModalOpened(false);

  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventData]);

  const modalDefault = {
    cameraSystemId,
    cameraName: deviceInfo?.cameraName,
    type: 'TRAINING',
    startRecordingTime: moment()
      .add(10, 'minute')
      .valueOf(),
    endRecordingTime: moment()
      .add(105, 'minute')
      .valueOf(),
    homeTeamId: '',
    awayTeamId: '',
    duration: 95,
    name: '',
    matchDay: '',
    competitionName: '',
    client: environmentList[0]?.id
  };
  useEffect(() => () => window.scrollTo(0, 0), []);

  return (
    <BodyInner>
      <SubHeader subheader={lang(locale).schedules}>
        <StyledScheduledBtn
          label={` +  ${lang(locale)['schedule new recording'].toUpperCase()}`}
          onClick={openModal}
          lettersSpaced
        />
      </SubHeader>
      <ScheduledListBody>
        {cameraInfo?.scheduleList?.length > 0 ? (
          <>
            {cameraInfo?.scheduleList?.map(item => (
              <RecordTile
                data={item}
                key={`${item.recordId}`}
                panelType="scheduled"
                locale={locale}
                onPicClickNavLink={`/device-list/${item.cameraSystemId}/scheduled/${item.recordId}`}
              />
            ))}
          </>
        ) : (
          <EmptyWarning>{lang(locale)['you have no scheduled recordings']}</EmptyWarning>
        )}
      </ScheduledListBody>
      <ScheduledModal
        modalOpened={scheduleModalOpened}
        closeModal={closeModal}
        isNew
        defaultValue={modalDefault}
        cameraSystemId={cameraSystemId}
        locale={locale}
      />
    </BodyInner>
  );
};

export default ScheduledPanel;

const ScheduledListBody = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  width: '100%',
  minWidth: '230px',
  boxSizing: 'border-box',
  minHeight: '65vh',
  paddingTop: '20px'
}));

const EmptyWarning = styled.div(() => ({
  width: '100%',
  color: colors.disabledBtn,
  padding: '0 10px 20px 0',
  fontSize: '24px'
}));

const StyledScheduledBtn = styled(Button)(() => ({
  fontWeight: 500,
  fontSize: '11px',
  margin: '5px 0'
}));

ScheduledPanel.propTypes = {
  locale: PropTypes.string
};

ScheduledPanel.defaultProps = {
  locale: 'en-US'
};
