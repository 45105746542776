import userType from './consts';

const environments = [];

const defaultState = {
  environments
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case userType.updateEnvironments: {
      const result = action.payload.map(item => ({name: item.name, id: item.id}));
      return { ...state, environments: result };
    }
    default:
      return state;
  }
};

export default userReducer;
