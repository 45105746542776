import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../Components/Preloader';
import ProtectedRoute from './ProtectedRoute';
import httpCore from '../../Services/httpCore';
import Header from '../../Components/Header';
import DeviceList from '../DeviceList';
import ActionList from '../ActionList';
import VideoActivity from '../VideoActivity';
import RecordingPanel from '../StreamingWindow';
import ScheduledPanel from '../ScheduledPanel/ScheduledPanel';
import Footer from '../../Components/Footer';
import { parameters, colors } from '../../Theme/theme';
import { setIdToken as setIdTokenForRecords } from '../../Services/api/recordings';
import { setIdToken as setIdTokenForDeviceList } from '../../Services/api/devices';
import { setIdToken as setIdTokenForUser } from '../../Services/api/user';
import { lang } from '../../Langs/langSettings';
import { getEnvironmentsAction, updateUserLangAction } from '../../Redux/user/actions';
import ToBeUploadedWatch from '../StreamingWindow/ToBeUploadedWatch';
import ScheduledView from '../StreamingWindow/ScheduledView';
import ResponseFromServer from '../../Components/ResponseNotificator';
// import TestPage from '../TestPage';

const BodyInner = styled.div(() => ({
  paddingTop: '80px',
  width: '80vw',
  minHeight: `calc(100vh - ${parameters.footerHeight}px)`,
  height: 'fit-content',
  maxWidth: '1600px',
  '@media (max-width: 535px)': {
    paddingTop: '40px'
  },
  '@media (max-width: 420px)': {
    width: '96vw',
    paddingTop: '60px'
  },
  '@media (max-height: 420px)': {
    paddingTop: '40px'
  }
}));
const PageRouter = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
  const [locale, setLocale] = useState(
    user ? user['https://kogniasports.com/user_metadata']?.kognia_locale : 'en-US'
  );
  const dispatch = useDispatch();
  const setToken = useCallback(async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      httpCore.setAuthToken(token);
      setIdTokenForRecords(idToken);
      setIdTokenForDeviceList(idToken);
      setIdTokenForUser(idToken, token);
      dispatch(getEnvironmentsAction());
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(async () => {
    if (isAuthenticated) {
      await setToken();
    }
  }, [isAuthenticated, setToken, dispatch]);

  const updateLang = langValue => {
    const langs = {
      [lang(locale).english]: 'en-US',
      [lang(locale).spanish]: 'es-ES',
      [lang(locale).german]: 'de-DE'
    };
    dispatch(updateUserLangAction({ language: langs[langValue] }));
    setLocale(() => langs[langValue]);
  };

  useEffect(() => {
    const local = user ? user['https://kogniasports.com/user_metadata']?.kognia_locale : 'en-US';
    setLocale(local);
    document.documentElement.setAttribute('lang', local.slice(0, 2));
  }, [user]);

  return isLoading ? (
    <Preloader />
  ) : (
    <>
      <BodyWrapper>
        {isAuthenticated && <Header locale={locale} updateLang={updateLang} />}
        {/* <BodyInner> */}
        <ResponseFromServer locale={locale} />
        <Routes>
          <Route exact path="/" element={<Navigate to="/device-list" />} />
          <Route
            exact
            path="/device-list"
            element={
              <ProtectedRoute component={DeviceList} locale={locale} BodyInner={BodyInner} />
            }
          />
          <Route
            exact
            path="/device-list/:cameraSystemId"
            element={
              <ProtectedRoute component={ActionList} locale={locale} BodyInner={BodyInner} />
            }
          />
          <Route
            exact
            path="/device-list/:cameraSystemId/video-library"
            element={
              <ProtectedRoute component={VideoActivity} locale={locale} BodyInner={BodyInner} />
            }
          />
          <Route
            exact
            path="/device-list/:cameraSystemId/recording"
            element={<ProtectedRoute component={RecordingPanel} locale={locale} />}
          />
          <Route
            exact
            path="/device-list/:cameraSystemId/scheduled-recordings"
            element={
              <ProtectedRoute component={ScheduledPanel} locale={locale} BodyInner={BodyInner} />
            }
          />

          <Route
            exact
            path="/device-list/:cameraSystemId/watch/:recordId"
            element={<ProtectedRoute component={ToBeUploadedWatch} locale={locale} />}
          />

          <Route
            exact
            path="/device-list/:cameraSystemId/scheduled/:recordId"
            element={<ProtectedRoute component={ScheduledView} locale={locale} />}
          />
          {/* <Route
            exact
            path="/test"
            element={<ProtectedRoute component={TestPage} locale={locale} />}
          /> */}
        </Routes>
        {/* </BodyInner> */}
      </BodyWrapper>
      {isAuthenticated && <Footer locale={locale} />}
    </>
  );
};

export default PageRouter;

const BodyWrapper = styled.div(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '240px',
  backgroundColor: colors.disabledInputBackground,
  boxSizing: 'border-box',
  minHeight: `calc(100vh - ${parameters.footerHeight}px)`
  // '@media(max-width: 535px)': {
  //   minHeight: 'fit-content',
  // },
}));
