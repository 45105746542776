import deviceType from './consts';

export const getDeviceListAction = () => ({
  type: deviceType.getDeviceList
});
export const updateDeviceListAction = payload => ({
  type: deviceType.updateDeviceList,
  payload
});

export const getStreamLinksAction = payload => ({
  type: deviceType.getStreamLinks,
  payload
});

export const getCameraStateByIdAction = payload => ({
  type: deviceType.getCameraStateById,
  payload
});

export const updateStreamLinksAction = payload => ({
  type: deviceType.updateStreamLinks,
  payload
});

export const updateRecordingListForCamerasAction = payload => ({
  type: deviceType.updateRecordingListForCameras,
  payload
});

export const updateScheduledListForCamerasAction = payload => ({
  type: deviceType.updateScheduledListForCameras,
  payload
});
