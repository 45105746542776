import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors } from '../../Theme/theme';
import { lang } from '../../Langs/langSettings';

// set status of toBeUpload video. Implemented, but bot used now.
const ToBeUploadStatus = ({ status, locale }) => {

  const statusStyle = {
    'READY_FOR_UPLOADING': {
      text: '',
    },
    'ON_UPLOADING': {
      text: lang(locale)['on uploading'],
      color: colors.successInputBorder,
    },
    'PENDING': {
      text: lang(locale).pending,
      color: colors.focusedInputBorder,
    },
    'VALID_ERROR': {
      text: lang(locale)['fill required fields'],
      color: colors.recordingDot,
    },
    'ERROR': {
      text: lang(locale)['loading error please try again'],
      color: colors.recordingDot,
    },
  };
  return (
    <>
      <Info status={status} color={statusStyle[status].color}>
        <Decoration />
        {statusStyle[status].text}
      </Info>
    </>
  
)};

export default ToBeUploadStatus;

const Decoration = styled.div(() => ({
  position: 'absolute',
  right: '100%',
  height: '20px',
  width: '25px',
  background: 'linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,1) 80%)',
  '@media(max-width: 700px)': {
    fontSize: '12px',
  }
}))

const Info = styled.div((props) => ({
  position: 'absolute',
  maxWidth: '140px',
  right: '30px',
  top: '2px',
  height: '15px',
  lineHeight: '15px',
  color: props.color,
  fontWeight: 500,
  fontSize: '14px',
  display: 'flex',
  textAlign: 'end',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  margin: 0,
  backgroundColor: colors.white,
}));

ToBeUploadStatus.propTypes = {
  status: PropTypes.string,
  locale: PropTypes.string,
};

ToBeUploadStatus.defaultProps = {
  status: 'READY_FOR_UPLOADING',
  locale: 'en-US',
};
