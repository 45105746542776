import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors } from '../../Theme/theme';
import { ReactComponent as RedCircle } from '../../assets/red-circle.svg';
import { lang } from '../../Langs/langSettings';

const RedRecordSign = ({ margin, locale, whiteBackground }) => (
  <RecordingSign margin={margin}>
    <Decoration whitebackground={whiteBackground} />
    <PulsingRedCircle whitebackground={whiteBackground} />
    <div>{lang(locale).recording}</div>
  </RecordingSign>
)

export default RedRecordSign;

const Decoration = styled.div((props) => ({
  position: 'absolute',
  right: '100%',
  height: '20px',
  width: '40px',
  background: props.whiteBackground && 'linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,1) 80%)',
  '@media(max-width: 700px)': {
    fontSize: '12px',
  }
}))

const RecordingSign = styled.div((props) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  width: '66px',
  height: '15px',
  color: colors.recordingDot,
  fontWeight: 500,
  fontSize: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: props.margin,
  backgroundColor: props.whiteBackground && colors.white,

  '& > div': {
    marginLeft: '15px',
  },

  '@media (max-width: 535px)': {
    width: '15px',

    '& > div': {
      display: 'none',
    },
  },
}));
const PulsingRedCircle = styled(RedCircle)(() => ({
  position: 'absolute',
  height: '12px',
  width: '12px',
  animationName: 'pulsing',
  animationDuration: '2s',
  animationIterationCount: 'infinite',
  backgroundColor: colors.white,

  '@keyframes pulsing': {
    '0%': {
      transform: 'scale(0.8)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(0.8)',
    },
  },
}));

RedRecordSign.propTypes = {
  margin: PropTypes.string,
  locale: PropTypes.string, 
  whiteBackground: PropTypes.number,
};

RedRecordSign.defaultProps = {
  margin: '16px',
  locale: 'en-US',
  whiteBackground: 1,
};
