export const colors = {
  white: '#ffffff',
  mainBackground: '#F5F5F7',

  defaultPurpleBtn: '#5440F7',
  hoverPurpleBtn: '#4C3AE0',
  activePurpleBtn: '#3F30BA',

  defaultBlackBtn: '#171928',
  hoverBlackBtn: '#111135',
  pressedBtnBorder: '#E1E4E8',
  disabledBtn: '#CECED9',

  hoverWhiteBtn: '#F0F0F0',

  defaultPanelsButton: 'RGBA(255, 255, 255, 0.6)',
  hoverPanelsBtn: 'RGBA(255, 255, 255, 0.8)',
  disabledPanelBtn: 'RGBA(206, 206, 217, 0.6)',

  recordingPanel: '#BBB3FF',
  videoActivityPanel: '#FACD75',
  scheduledPanel: '#C8E8E7',
  disabledPanel: '#EDEDF2',
  tacticalAnalysisPanel: '#86E3E3',
  panelsText: '#262940',

  defaultInputBorder: '#C9CED6',
  successInputBorder: '#0BB07B',
  focusedInputBorder: '#5440F7',
  errorInputBorder: '#DD2727',
  defaultInputText: '#171928',
  disabledInputText: '#C9CED6',
  disabledInputBackground: '#F5F5F7',

  recordingDot: '#DD2727',
  scheduledGray: '#6F738C',
  modalWrapper: 'RGBA(0, 0, 0, 0.5)',
  calendarDayOut: '#B7B7C1',
  lightRed: '#EC7777',
  successColor: '#009D83'
};

export const parameters = {
  headerHeight: 80,
  footerHeight: 80
};

export const zIndexes = {
  deviceTileLongNameBox: 2,
  headerWrapper: 3,
  inputBackground: 2,
  textInputsLayer: 3,
  popUpInput: 5,
  timeScrollClockPic: 4,
  modalWrapper: 3,
  recordTileBottomPane: 1,
  recordTileCloudPic: 2,
  videoPlayerMovePaneButton: 3,
  deviceListNearestScheduledSubPanel: 2,
  cloudPicInModals: 3,
  userPopUp: 5,
  videoPlayerSideCover: 3,
  videoPlayerVideo: 2,
  videoTypeMenu: 2,
  selectBackground: 4
};
