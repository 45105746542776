import httpCore from '../httpCore';

let idToken = '';
export const setIdToken = token => {
  idToken = token['https://kogniasports.com/app_metadata']?.kognia_user_id;
};

export const callDeviceList = async () => {
  const endpoint = httpCore.createFetch({
    url: '/camera/list',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      clientId: idToken
    }
  });
  const res = await endpoint();
  return res.data;
};

export const callCameraStreams = async payload => {
  if (!idToken) {
    return;
  }
  const endpoint = httpCore.createFetch({
    url: '/camera/stream',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: { clientId: idToken, ...payload }
  });
  const res = await endpoint();
  // eslint-disable-next-line consistent-return
  return res.data;
};
