/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { colors } from '../../Theme/theme';
import { lang } from '../../Langs/langSettings';

import TextInput from '../../Components/Inputs/TextInput';
import Calendar from '../../Components/Inputs/Calendar';
import RadioButton from '../../Components/Inputs/RadioButton';
import ModalContainer from '../../Components/ModalContainer';
import { getEnvironmentSelector } from '../../Redux/user/selectors';
// import { editRecordingAction } from '../../Redux/recordings/actions';
// import { isNumber } from '../../Services/validation/validationRules';

// Now is used in read-only format
const VideoModal = ({ modalOpened, closeModal, defaultValue, locale, disabled }) => {
  // const dispatch = useDispatch();
  const [value, setValue] = useState(defaultValue);
  const environmentList = useSelector(getEnvironmentSelector);

  // const onInputChange = (input, val) => {
  //    if ((input === 'homeTeamScore' || input === 'awayTeamScore') && !isNumber(val)){return};
  //   setValue(prevVal => ({ ...prevVal, [input]: val }));
  // };
  // useEffect(() => {
  //   if (value.type === 'TRAINING') {
  //     setValue(prevVal => ({
  //       ...prevVal,
  //       homeTeamId: '',
  //       awayTeamId: '',
  //     }));
  //   }
  // }, [value.type]);

  useEffect(() => {
    modalOpened && setValue(defaultValue);
  }, [modalOpened]);

  const applyButton = {
    label: lang(locale).update,
    disabled,
    hidden: true,
    onClick: () => {
      // dispatch(editRecordingAction({
      //   recordId: defaultValue.recordId,
      //   date: new Date(defaultValue.startRecordingTime),
      //   type: value.type,
      //   homeTeamId: value.homeTeamId?.trim() || '',
      //   awayTeamId: value.awayTeamId?.trim() || '',
      //   matchDay: value.matchDay?.trim() ||  '',
      //   name: value.name?.trim()
      // ? value.name?.trim()
      // : moment(defaultValue.startRecordingTime).format('DD/MM/YYYY HH:mm:ss'),
      //   competitionName: value.competitionName?.trim() || '',
      //   homeTeamScore: value.homeTeamScore ? +value.homeTeamScore : null,
      //   awayTeamScore: value.awayTeamScore ? +value.awayTeamScore : null,
      // }));
      closeModal();
    }
  };

  return (
    <StyledModalContainer
      isOpened={modalOpened}
      headerText={lang(locale).info}
      closeModal={closeModal}
      footerButton={applyButton}
      width="80%"
      locale={locale}
    >
      <TextInput
        label={lang(locale)['recording name']}
        value={value.name}
        // onChange={e => onInputChange('name', e.target.value)}
        required
        disabled
      />
      <TextInput
        label={lang(locale).environment}
        value={environmentList?.find(client => client.id === value.client)?.name}
        // onChange={e => onInputChange('name', e.target.value)}
        required
        disabled
      />
      <RadioButton
        options={[lang(locale).training, lang(locale).game]}
        activeOption={value.type === 'GAME' ? lang(locale).game : lang(locale).training}
        // onChange={e => onInputChange('type', e === lang(locale).training ? 'TRAINING' : 'GAME')}
        disabled
      />
      <StyledCalendar
        label={lang(locale).date}
        calendarValue={value.startRecordingTime}
        className="video-calendar"
        disabled
        readOnly
      />
      <TextInput
        label={lang(locale)['competition name']}
        value={value.competitionName}
        visible={value.type === 'GAME'}
        // onChange={e => onInputChange('competitionName', e.target.value)}
        disabled
      />
      <TextInput
        label={lang(locale).matchDay}
        value={value.matchDay}
        visible={value.type === 'GAME'}
        // onChange={e => onInputChange('matchDay', e.target.value)}
        disabled
      />
      <PanelWrapper>
        <WidePanel visible={value.type === 'GAME'}>
          <div>
            <TeamHeader>{lang(locale)['home team'].toUpperCase()}</TeamHeader>
            <TextInput label={lang(locale).name} value={value.homeTeamId} disabled />
          </div>

          <div>
            <TeamHeader>{lang(locale)['away team'].toUpperCase()}</TeamHeader>
            <TextInput label={lang(locale).name} value={value.awayTeamId} disabled />
          </div>
        </WidePanel>
      </PanelWrapper>
    </StyledModalContainer>
  );
};

export default VideoModal;

const StyledModalContainer = styled(ModalContainer)(() => ({
  '& > div': {
    // marginBottom: '15px',
    width: '100%',
    minWidth: '220px'
  }
}));

// const Panel = styled.div(() => ({
//   display: 'flex',
//   justifyContent: 'space-between',
//   flexWrap: 'wrap',

//   '& > div': {
//     minWidth: '220px',
//     position: 'relative',

//     '@media(max-width: 620px)': {
//       width: '100%',
//       marginBottom: '25px',

//       '&:last-child': {
//         marginBottom: 0
//       }
//     }
//   }
// }));

const PanelWrapper = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%'
}));

const WidePanel = styled.div(props => ({
  display: props.visible ? 'flex' : 'none',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  '& > div': {
    width: '48%',
    minWidth: '220px',
    '@media(max-width: 620px)': {
      width: '100%',
      marginBottom: '0'
    },
    '& > div': {
      width: '100%',
      minWidth: '220px'
    }
  }
}));

const StyledCalendar = styled(Calendar)(() => ({
  '&.video-calendar': {
    width: '48%',
    '@media(max-width: 620px)': {
      width: '100%'
    }
  }
}));
const TeamHeader = styled.div(() => ({
  fontWeight: 500,
  fontSize: '11px',
  letterSpacing: '1.6px',
  color: colors.scheduledGray,
  margin: '20px 0 10px 0'
}));

VideoModal.propTypes = {
  modalOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  locale: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.shape({
    recordId: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
    homeTeamId: PropTypes.string,
    awayTeamId: PropTypes.string,
    matchDay: PropTypes.string,
    name: PropTypes.string,
    competitionName: PropTypes.string,
    homeTeamScore: PropTypes.number,
    awayTeamScore: PropTypes.number,
    startRecordingTime: PropTypes.number
  })
};

VideoModal.defaultProps = {
  modalOpened: false,
  closeModal: () => {},
  locale: 'en-US',
  disabled: false,
  defaultValue: PropTypes.shape({
    recordId: '',
    date: '',
    type: '',
    homeTeamId: '',
    awayTeamId: '',
    matchDay: '',
    name: '',
    competitionName: '',
    homeTeamScore: null,
    awayTeamScore: null,
    startRecordingTime: 0
  })
};
