import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { colors, parameters } from '../../Theme/theme';
import { lang } from '../../Langs/langSettings';
import VideoPlayer from '../../Components/VideoPlayer/VideoPlayer';
import Button from '../../Components/Button/Button';
import { getEventDataSelector } from '../../Redux/recordings/selectors';
import { findCameraId, recordTimer, sortArray } from '../../Services/api/common';
import StopRecordModal from '../ModalWindows/StopRecordModal';
import { ReactComponent as RecordingDot } from '../../assets/recording-circle.svg';

import RecordTile from '../../Components/RecordTile/RecordTile';
import { getCameraStateByIdAction, getStreamLinksAction } from '../../Redux/devices/actions';
import { cameraStateByIdSelector, getDeviceListSelector } from '../../Redux/devices/selectors';
import StartRecordingModal from '../ModalWindows/StartRecordingModal';
import { getEnvironmentSelector } from '../../Redux/user/selectors';

const RecordingPanel = ({ locale }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cameraSystemId = findCameraId(location);
  const deviceInfo = useSelector(cameraStateByIdSelector);
  const deviceList = useSelector(getDeviceListSelector);
  const timer = useRef(0);
  const [timerUntilStart, setTimerUntilStart] = useState(0);
  const [warningMessage, setWarningMessage] = useState('');
  const eventDataSelector = useSelector(getEventDataSelector);
  const [eventData, setEventData] = useState({});
  const [disabledStart, setDisabledStart] = useState(true);
  const environmentList = useSelector(getEnvironmentSelector);
  const [lastRecording, setLastRecording] = useState(
    deviceInfo?.toBeUploadedList?.length > 0
      ? sortArray(deviceInfo.toBeUploadedList, 'endRecordingTime', true)[0]
      : {}
  );
  const [modalOpened, setModalOpened] = useState({ start: false });
  const [streamLinks, setStreamLinks] = useState({
    individual: []
  });
  useEffect(() => {
    dispatch(getStreamLinksAction({ cameraSystemId, type: 'MSE' }));
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventData]);

  useEffect(() => {
    setStreamLinks({
      individual: deviceInfo?.individualStreamUrl
    });
  }, [deviceList]);

  const modalDefault = {
    cameraSystemId,
    cameraName: deviceInfo?.cameraName,
    type: 'TRAINING',
    endRecordingTime: moment()
      .add(95, 'minute')
      .valueOf(),
    homeTeamId: '',
    awayTeamId: '',
    duration: 95,
    name: moment().format('DD/MM/YYYY HH:mm:ss'),
    matchDay: '',
    competitionName: '',
    client: environmentList[0]?.id
  };

  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
    setEventData(eventDataSelector);
  }, [eventDataSelector]);

  const openModal = startOrStop => {
    if (disabledStart) {
      return;
    }
    startOrStop === 'start' && setModalOpened({ ...modalOpened, start: true });
    // startOrStop === 'stop' && setModalOpened({ ...modalOpened, stop: true });
  };
  const closeModal = startOrStop => {
    startOrStop === 'start' && setModalOpened({ ...modalOpened, start: false });
    // startOrStop === 'stop' && setModalOpened({ ...modalOpened, stop: false });
  };

  const handleDisable = useCallback(
    forcedDisable => {
      if (forcedDisable) {
        setDisabledStart(true);
        setEventData({});
        setTimeout(handleDisable, 2000);
        return;
      }
      if (!forcedDisable) {
        clearTimeout(handleDisable, 2000);
      }
      if (!eventDataSelector?.records) {
        setDisabledStart(true);
        return;
      }
      if (
        lastRecording.recordId &&
        // !deviceInfo?.inProgressList?.length > 0 &&
        moment(lastRecording.endRecordingTime).add(5, 'minutes') >= moment()
      ) {
        setDisabledStart(true);
        recordTimer(
          timer.current,
          lastRecording.endRecordingTime,
          val => setTimerUntilStart(val),
          true
        );
        setWarningMessage(
          `${lang(locale)['the next recording start is available in']} ${timerUntilStart}`
        );
        return;
      }
      setDisabledStart(false);
      setWarningMessage('');
      clearTimeout(timer.current);
    },
    [deviceInfo, lastRecording, timerUntilStart, eventData, modalOpened]
  );

  useEffect(() => {
    setLastRecording(() =>
      deviceInfo?.toBeUploadedList?.length > 0
        ? sortArray(deviceInfo.toBeUploadedList, 'endRecordingTime', true)[0]
        : {}
    );
    if (deviceInfo?.inProgressList?.length > 0) {
      navigate(`/device-list/${cameraSystemId}/watch/${deviceInfo.inProgressList[0].recordId}`);
    }
    handleDisable();
  }, [eventData, deviceInfo]);

  useEffect(() => {
    handleDisable();
  });

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <RecordingWrapper>
      <VideoPlayer
        locale={locale}
        autoplay
        streamType="schedule"
        playerType="liveStream"
        streamLinks={streamLinks}
        buttonPane={(
          <>
            <ButtonPane>
              <BtnInner>
                <Button
                  label={(
                    <>
                      <StyledRecordingDot />
                      {lang(locale)['start recording']}
                    </>
                  )}
                  onClick={() => openModal('start')}
                  disabled={disabledStart}
                  visible={!deviceInfo?.cameraStream}
                />
                {/* <RecordTile
                tileView="flat"
                visible={deviceInfo?.cameraStream}
                data={deviceInfo || {}}
                panelType="inProgress"
              /> */}

                {/* <Button
                    label={lang(locale).autofocus}
                    onClick={() => {}}
                    type="black"
                    disabled
              /> */}
              </BtnInner>
              {disabledStart && <Warning>{warningMessage}</Warning>}
            </ButtonPane>

            <StartRecordingModal
              modalOpened={modalOpened.start}
              closeModal={() => closeModal('start')}
              cameraSystemId={cameraSystemId}
              locale={locale}
              handleDisable={() => handleDisable(true)}
              defaultValue={modalDefault}
            />
          </>
        )}
      />
    </RecordingWrapper>
  );
};

export default RecordingPanel;

const RecordingWrapper = styled.div(() => ({
  paddingTop: '80px',
  minHeight: `calc(100vh - ${parameters.footerHeight}px)`,
  '@media (max-width: 535px)': {
    paddingTop: '40px'
  },
  '@media (max-height: 420px)': {
    paddingTop: '40px'
  }
}));

const ButtonPane = styled.div(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '10px'
}));

const BtnInner = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center'
}));

const Warning = styled.div(() => ({
  color: colors.lightRed,
  fontSize: '12px',
  width: '100%',
  textAlign: 'center'
}));

const StyledRecordingDot = styled(RecordingDot)(() => ({
  backgroundColor: 'transparent',
  fill: 'transparent',
  stroke: 'transparent',
  marginRight: '6px'
}));

RecordingPanel.propTypes = {
  locale: PropTypes.string
};

RecordingPanel.defaultProps = {
  locale: 'en-US'
};
