import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../Components/Button/Button';
import RecordTile from '../../Components/RecordTile/RecordTile';
import VideoPlayer from '../../Components/VideoPlayer/VideoPlayer';
import { lang } from '../../Langs/langSettings';
import { getCameraStateByIdAction } from '../../Redux/devices/actions';
import { cameraStateByIdSelector } from '../../Redux/devices/selectors';
// import { uploadRecordingAction } from '../../Redux/recordings/actions';
import { getEventDataSelector, getInProgressListSelector } from '../../Redux/recordings/selectors';
import { ReactComponent as StopSign } from '../../assets/stop-sign-square.svg';
import { filterArray, findCameraId, findRecordId } from '../../Services/api/common';
import { colors, parameters } from '../../Theme/theme';
import StopRecordModal from '../ModalWindows/StopRecordModal';

const ToBeUploadedWatch = ({ locale }) => {
  const location = useLocation();
  const recordId = findRecordId(location);
  const [recordInfo, setRecordInfo] = useState({});
  const navigate = useNavigate();
  const cameraSystemId = findCameraId(location);
  const [disabledStop, setDisabledStop] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  // const inProgressList = useSelector(getInProgressListSelector);
  // const toBeUploadedList = useSelector(getInProgressListSelector);
  // const inProgress = filterArray(inProgressList, 'recordId', recordId);
  const eventData = useSelector(getEventDataSelector);
  const dispatch = useDispatch();
  const deviceInfo = useSelector(cameraStateByIdSelector);
  const [streamLinks, setStreamLinks] = useState({
    individual: [],
    panorama: '',
    tactical: ''
  });
  const openModal = () => {
    setModalOpened(true);
  };
  const closeModal = () => {
    setModalOpened(false);
  };
  const handleDisable = useCallback(
    forcedDisable => {
      if (forcedDisable) {
        setDisabledStop(true);
        setTimeout(handleDisable, 2000);
        return;
      }
      if (!deviceInfo || !deviceInfo?.inProgressList) {
        return;
      }
      if (!deviceInfo?.inProgressList[0]) {
        setDisabledStop(true);
        return;
      }
      setDisabledStop(false);
    },
    [deviceInfo]
  );
  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventData]);

  useEffect(() => {
    handleDisable();
  }, [deviceInfo]);

  useEffect(() => {
    if (!deviceInfo?.cameraSystemId) {
      return;
    }
    const commonList = deviceInfo?.cameraSystemId
      ? [...deviceInfo.inProgressList, ...deviceInfo.toBeUploadedList]
      : [];
    const recording = commonList.find(item => item.recordId === recordId) || {};
    setRecordInfo(recording);
    if (!recording?.recordId) {
      navigate(`/device-list/${cameraSystemId}/recording`);
    }
  }, [deviceInfo]);

  useEffect(() => {
    setStreamLinks({
      individual: deviceInfo?.individualStreamUrl,
      panorama: deviceInfo?.panoramaStreamUrl,
      tactical: deviceInfo?.tacticalStreamUrl
    });
  }, [deviceInfo]);

  // useEffect(() => {
    // if (!eventData.updatedCameras) {
    //   return;
    // }
    // if (deviceInfo?.inProgressList[0]) {
    // }
    // navigate(`/device-list/${cameraSystemId}/video-library`);
  // }, [eventData]);

  // const singleRecordUploading = useCallback(() => {
  //   dispatch(uploadRecordingAction(recordId));
  // }, []);

  return (
    <RecordingWrapper>
      <VideoPlayer
        locale={locale}
        autoplay
        streamType={recordInfo.recorded ? 'toBeUpload' : 'recording'}
        playerType="liveRecording"
        streamLinks={{
          individual: streamLinks.individual || [],
          panorama: [streamLinks.panorama] || [],
          tactical: [streamLinks.tactical] || []
        }}
        buttonPane={
          recordInfo?.recordId ? (
            <>
              <ButtonPane>
                <BtnInner>
                  <RecordTile
                    tileView="flat"
                    visible
                    data={recordInfo}
                    panelType={recordInfo.recorded ? 'toBeUpload' : 'inProgress'}
                  >
                    {!recordInfo.recorded && (
                      <Button
                        label={(
                          <>
                            <StyledStopSign disabled={disabledStop} />
                            {lang(locale)['stop recording']}
                          </>
                        )}
                        onClick={openModal}
                        visible={deviceInfo?.cameraStream}
                        type="stopRecording"
                        disabled={disabledStop}
                      />
                    )}
                  </RecordTile>
                </BtnInner>
              </ButtonPane>
              <StopRecordModal
                modalOpened={modalOpened}
                closeModal={closeModal}
                recordId={
                  deviceInfo?.inProgressList.length > 0
                    ? deviceInfo?.inProgressList[0].recordId
                    : ''
                }
                locale={locale}
                handleDisable={() => handleDisable(true)}
              />
            </>
          ) : null
        }
      />
    </RecordingWrapper>
  );
};

export default ToBeUploadedWatch;

const RecordingWrapper = styled.div(() => ({
  paddingTop: '80px',
  minHeight: `calc(100vh - ${parameters.footerHeight}px)`,
  '@media (max-width: 535px)': {
    paddingTop: '40px'
  },
  '@media (max-height: 420px)': {
    paddingTop: '40px'
  }
}));

const ButtonPane = styled.div(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px 0'
}));

const BtnInner = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center'
}));

const StyledStopSign = styled(StopSign)(props => ({
  fill: props.disabled ? colors.white : colors.recordingDot,
  marginRight: '6px'
}));
