import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import dashPlayer from '../PlayerSystems/Mpeg-dash-player';

const Tactical = ({ autoplay, streamLinks, isVisible = true, videoIds }) => {
  const widthHeightRatio = 3 / 5;
  const [windowParams, setWindowParams] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const updateWidthAndHeight = useCallback(() => {
    setWindowParams({ width: window.innerWidth, height: window.innerHeight });
  }, [window]);

  const resizeWindow = useCallback(() => {
    if (window.innerWidth > window.innerHeight) {
      return (window.innerHeight / widthHeightRatio) * 0.4;
    }
    return window.innerWidth;
  }, [windowParams]);

  const [width, setWidth] = useState(resizeWindow());
  const [height, setHeight] = useState(resizeWindow() * widthHeightRatio);

  useEffect(() => {
    setWidth(resizeWindow());
    setHeight(resizeWindow() * widthHeightRatio);
  }, [resizeWindow, windowParams.width]);

  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  }, []);

  useEffect(() => {
    if (!streamLinks || !videoIds) {
      return;
    }
    try {
      streamLinks[0] && dashPlayer(streamLinks, videoIds);
    } catch (error) {
      console.log(error);
    }
  }, [streamLinks]);

  return (
    <PanelsInner width={`${width}px`} height={`${height}px`} isVisible={isVisible}>
      <video id={videoIds[0]} autoPlay={autoplay} muted width={width} height={height} />
    </PanelsInner>
  );
};

export default Tactical;

const PanelsInner = styled.div(props => ({
  display: props.isVisible ? 'flex' : 'none',
  justifyContent: 'center',
  width: props.width,
  minWidth: '240px',
  height: props.height
}));
