import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors, zIndexes } from '../../Theme/theme';
import { ReactComponent as TickPic } from '../../assets/down-arrow.svg';

const SelectInput = ({ width, options, chosenOpt, label, onChange, className, children }) => {
  const [opened, setOpened] = useState(false);
  const [chosenOption, setChosenOption] = useState('');

  const toggleDisplay = () => {
    setOpened(!opened);
  };
  const chooseOption = option => {
    onChange(option);
    toggleDisplay();
  };

  useEffect(() => {
    setChosenOption(chosenOpt);
  }, [chosenOpt]);

  useEffect(() => {
    if (opened) {
      document.addEventListener('click', () => setOpened(false), { once: true });
    }
  }, [opened]);

  return (
    <InputWrapper width={width} className={className}>
      <SelectHeader>{label}</SelectHeader>
      <StyledTextInput onClick={toggleDisplay} value={chosenOption} readOnly inFocus={opened} />
      {children}
      <StyledTickPic onClick={toggleDisplay} opened={opened ? 1 : 0} />
      <StyledOptionsWrapper opened={opened} width={width} className={`${className}-options`}>
        {options.map(opt => (
          <StyledOption
            key={`${opt}_option`}
            width={width}
            onClick={() => chooseOption(opt)}
            isChosen={opt === chosenOption}
          >
            {opt}
          </StyledOption>
        ))}
      </StyledOptionsWrapper>
    </InputWrapper>
  );
};

export default SelectInput;

const InputWrapper = styled.div(props => ({
  width: props.width,
  position: 'relative'
}));

const StyledTextInput = styled.input(props => ({
  width: '100%',
  height: '40px',
  boxShadow: `0px 0px 0px 1px ${
    props.inFocus ? colors.focusedInputBorder : colors.defaultInputBorder
  }`,
  outline: 'none',
  border: 'none',
  padding: '5px',
  borderRadius: '4px',
  color: colors.defaultBlackBtn,
  fontSize: '14px',
  fontWeight: 400,
  cursor: 'pointer',
  '&[disabled]': {
    backgroundColor: colors.disabledInputBackground
  },
  '&:focus': {
    border: 'none',
    outline: 'none',
    boxShadow: `0px 0px 0px 1px ${colors.focusedInputBorder}`
  }
}));

const StyledTickPic = styled(TickPic)(props => ({
  width: '10px',
  height: '40px',
  position: 'absolute',
  right: '10px',
  bottom: '0',
  cursor: 'pointer',
  transform: props.opened && 'rotateX(180deg)',
  fill: colors.scheduledGray
}));

const StyledOptionsWrapper = styled.div(props => ({
  display: props.opened ? 'block' : 'none',
  backgroundColor: colors.white,
  padding: '7px',
  position: 'absolute',
  marginTop: '5px',
  // top: '50px',
  left: '0px',
  width: 'fit-content',
  minHeight: '30px',
  minWidth: '100%',
  borderRadius: '4px',
  boxShadow: ' 0px 4px 8px rgba(0, 0, 0, 0.2)',
  zIndex: zIndexes.selectBackground,
  maxHeight: '300px',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none'
  }
}));
const StyledOption = styled.div(props => ({
  width: '100%',
  height: '40px',
  lineHeight: '40px',
  paddingLeft: '5px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: props.isChosen ? colors.disabledPanel : colors.hoverWhiteBtn,
    color: colors.defaultBlackBtn
  },
  backgroundColor: props.isChosen ? colors.disabledPanel : colors.white,
  color: colors.defaultBlackBtn
}));

const SelectHeader = styled.div(() => ({
  fontWeight: 500,
  fontSize: '14px',
  padding: ' 0 5px 3px 0',
  marginBottom: '5px'
}));

SelectInput.propTypes = {
  width: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  chosenOpt: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node
};
SelectInput.defaultProps = {
  width: '150px',
  options: ['one', 'two'],
  chosenOpt: '',
  label: '',
  onChange: () => {},
  className: '',
  children: React.createElement('div')
};
