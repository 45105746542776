import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors, zIndexes } from '../../Theme/theme';

const lettersReplacement = [
  ['´A', 'Á'],
  ['´a', 'á'],
  ['´E', 'É'],
  ['´e', 'é'],
  ['´O', 'Ó'],
  ['´o', 'ó'],
  ['´I', 'Í'],
  ['´i', 'í'],
  ['´U', 'Ú'],
  ['´u', 'ú'],
  ['´ ', '´\u200C'],

  ['`A', 'À'],
  ['`a', 'à'],
  ['`E', 'È'],
  ['`e', 'è'],
  ['`O', 'Ò'],
  ['`o', 'ò'],
  ['`I', 'Ì'],
  ['`i', 'ì'],
  ['`U', 'Ù'],
  ['`u', 'ù'],
  ['` ', '`\u200C'],
  ['¨u', 'ü']
];
const TextInput = ({
  width,
  type,
  disabled,
  errorText,
  label,
  value,
  children,
  className,
  onChange,
  onFocus,
  onBlur,
  visible,
  required
}) => {
  const [val, setVal] = useState(value);
  useEffect(() => {
    if (value === undefined) {
      return;
    }
    let res = value;
    if (value === null) {
      setVal('');
      return;
    }
    ['´', '`'].forEach(sign => {
      const replacementPosition = res?.toString().indexOf(sign);
      if (replacementPosition !== -1 && replacementPosition !== res.length - 1) {
        const replacer = lettersReplacement.find(
          item => item[0] === res.slice(replacementPosition, replacementPosition + 2)
        );
        if (replacer) {
          res = res.replace(replacer[0], replacer[1]);
        }
      }
    });

    setVal(res);
  }, [value]);

  return (
    <InputWrapper width={width} visible={visible}>
      <Header>
        {label}
        {required && <RequiredStar>*</RequiredStar>}
      </Header>
      <InputBody>
        <StyledTextInput
          type={type}
          disabled={disabled}
          value={val}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={e => {
            onChange(e);
          }}
          className={className}
        />
        {children}
      </InputBody>
      <ErrorTextWrapper>
        {errorText && <ErrorText width={width}>{errorText}</ErrorText>}
      </ErrorTextWrapper>
    </InputWrapper>
  );
};

const inputColor = {
  default: colors.defaultInputBorder,
  success: colors.successInputBorder,
  error: colors.errorInputBorder
};

export default TextInput;

const InputWrapper = styled.div(props => ({
  display: props.visible ? 'block' : 'none',
  width: props.width,
  position: 'relative',
  height: '90px'
}));

const InputBody = styled.div(() => ({
  position: 'relative'
}));

const StyledTextInput = styled.input(props => ({
  width: '100%',
  minWidth: '200px',
  backgroundColor: 'transparent',
  height: '40px',
  boxShadow: `0px 0px 0px 1px ${inputColor[props.type]}`,
  border: 'none',
  padding: '5px',
  borderRadius: '4px',
  color: colors.defaultBlackBtn,
  fontSize: '14px',
  fontWeight: 400,
  position: 'relative',
  zIndex: zIndexes.textInputsLayer,
  fontFamily: 'Rubik, sans-serif',
  '&:focus': {
    border: 'none',
    outline: 'none',
    boxShadow: `0px 0px 0px 1px ${colors.focusedInputBorder}`
  },
  '&[disabled]': {
    backgroundColor: colors.disabledInputBackground,
    color: colors.disabledInputText,
    '&::placeholder': {
      color: colors.disabledInputText
    }
  }
}));
const ErrorTextWrapper = styled.div(() => ({
  width: '100%',
  height: '10px',
  paddingTop: '3px'
}));
const ErrorText = styled.div(() => ({
  minWidth: '200px',
  fontSize: '14px',
  fontWeight: 400,
  color: colors.errorInputBorder,
  whiteSpace: 'normal'
}));

const Header = styled.div(() => ({
  fontWeight: 500,
  fontSize: '14px',
  padding: ' 0 5px 8px 0'
}));

const RequiredStar = styled.span(() => ({
  color: colors.defaultInputText,
  marginLeft: '3px'
}));

TextInput.propTypes = {
  width: PropTypes.string,
  type: PropTypes.oneOf(['default', 'success', 'error']),
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  visible: PropTypes.bool
};

TextInput.defaultProps = {
  width: '150px',
  type: 'default',
  disabled: false,
  errorText: undefined,
  label: '',
  value: '',
  children: React.createElement('div'),
  className: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  required: false,
  visible: true
};
