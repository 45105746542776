const deviceType = {
  updateDeviceList: 'UPDATE_DEVICE_LIST',
  getStreamLinks: 'GET_STREAM_LINKS',
  updateStreamLinks: 'UPDATE_STREAM_LINKS',
  getCameraStateById: 'GET_CAMERA_STATE_BY_ID',
  updateRecordingListForCameras: 'UPDATE_RECORDING_LINKS_FOR_CAMERAS',
  updateScheduledListForCameras: 'UPDATE_SCHEDULED_LIST_FOR_CAMERAS'
};

export default deviceType;
