/* eslint-disable consistent-return */
import moment from 'moment';
import { lang } from '../../Langs/langSettings';
import {
  isAroundOverlapping,
  isEmpty,
  isInThePast,
  isOverlapping,
  numberInBetween
} from '../../Services/validation/validationRules';

const timeViewer = (rec, startOrEnd) => {
  if (!rec || !rec.startRecordingTime) {
    return;
  }
  let convertedTime = '';
  convertedTime =
    startOrEnd === 'start'
      ? moment(rec.startRecordingTime).format('HH:mm')
      : moment(rec.endRecordingTime).format('HH:mm');
  // eslint-disable-next-line consistent-return
  return convertedTime;
};

const errMess = (locale, overlapRec) => {
  const [firstPart, secPart] = lang(locale)[
    'another recording overlaps with the current one'
  ].split('|');
  return {
    empty: lang(locale)['the field is empty'],
    fiveMinFromNow: lang(locale)['you should schedule a record at least in 5 minutes from now'],
    startOverlap: `${lang(locale)['select time which starts in 5 minutes after']} ${
      overlapRec?.record?.name
    } (${timeViewer(overlapRec?.record, 'start')} - ${timeViewer(overlapRec?.record, 'end')})`,
    endOverlap: `${lang(locale)['select duration to end recording in 5 minutes before']} ${
      overlapRec?.record?.name
    } (${timeViewer(overlapRec?.record, 'start')} - ${timeViewer(overlapRec?.record, 'end')})`,
    aroundOverlap: `${firstPart} ${overlapRec?.record?.name} (${timeViewer(
      overlapRec?.record,
      'start'
    )}) - (${timeViewer(overlapRec?.record, 'end')}) ${secPart}`,
    timeRange: lang(locale)['set time from 15 to 240 minutes']
  };
};

export const validateScheduled = (
  field,
  value,
  times,
  defaultValue,
  validErrors,
  defaultErrors,
  commonRecordList,
  locale,
  editDisable
) => {
  let validation = true;
  let schedValidErrors = validErrors;


  // record, which overlaps with start time of the current one
  const resStart = isOverlapping(
    defaultValue.recordId,
    value.startRecordingTime + 1 * 60000,
    commonRecordList
  );

  // record, which overlaps with end time of the current one
  const resEnd = isOverlapping(
    defaultValue.recordId,
    value.endRecordingTime - 1 * 60000,
    commonRecordList
  );
  // record, which is around the current one
  const resAround = isAroundOverlapping(
    defaultValue.recordId,
    value.startRecordingTime,
    value.endRecordingTime,
    commonRecordList
  );

  field === 'all' ? (schedValidErrors = { ...defaultErrors }) : (schedValidErrors[field] = '');

  if (field === 'all' && times.time.length <= 1) {
    schedValidErrors.startRecordingTime = errMess(locale).empty;
    validation = false;
  }
  if (field === 'name' || field === 'all') {
    if (isEmpty(value.name.trim()) === 'isEmptyError') {
      schedValidErrors.name = errMess(locale).empty;
      validation = false;
    }
  }
  if (
    (field === 'startRecordingTime' || field === 'all') &&
    !editDisable &&
    times.time.length > 1
  ) {
    if (isInThePast(value.startRecordingTime - 4 * 60000) === 'pastError') {
      schedValidErrors.startRecordingTime = errMess(locale).fiveMinFromNow;
      validation = false;
    }
    if (resStart.isOverlap === true) {
      schedValidErrors.startRecordingTime = errMess(locale, resStart).startOverlap;
      validation = false;
    }
  }
  if (field === 'duration' || field === 'all') {
    if (numberInBetween(+value.duration, 15, 240)) {
      schedValidErrors.duration = errMess(locale).timeRange;
      validation = false;
    }
  }
  if ((field === 'duration' || field === 'startRecordingTime' || field === 'all') && !editDisable) {
    if (resEnd.isOverlap) {
      schedValidErrors.duration = errMess(locale, resEnd).endOverlap;
      validation = false;
    }

    if (resAround.isOverlap) {
      schedValidErrors.duration = errMess(locale, resAround).aroundOverlap;
      validation = false;
    }
    if (isEmpty(value.duration).trim() === 'isEmptyError') {
      schedValidErrors.duration = errMess(locale).empty;
      validation = false;
    }
  }

  if (field === 'homeTeamId' || field === 'all') {
    if (value.type === 'GAME' && isEmpty(value.homeTeamId.trim()) === 'isEmptyError') {
      schedValidErrors.homeTeamId = errMess(locale).empty;
      validation = false;
    }
  }
  if (field === 'awayTeamId' || field === 'all') {
    if (value.type === 'GAME' && isEmpty(value.awayTeamId.trim()) === 'isEmptyError') {
      schedValidErrors.awayTeamId = errMess(locale).empty;
      validation = false;
    }
  }
  if (field === 'matchDay' || field === 'all') {
    if (value.type === 'GAME' && isEmpty(value.matchDay.trim()) === 'isEmptyError') {
      schedValidErrors.matchDay = errMess(locale).empty;
      validation = false;
    }
  }
  if (field === 'competitionName' || field === 'all') {
    if (value.type === 'GAME' && isEmpty(value.competitionName.trim()) === 'isEmptyError') {
      schedValidErrors.competitionName = errMess(locale).empty;
      validation = false;
    }
  }
  return { validation, schedValidErrors };
};

export const validateStartRecording = (field, value, validErrors, commonRecordList, locale) => {
  let validation = true;
  // eslint-disable-next-line prefer-const
  let startRecordErrors = validErrors;

  const resStart = isOverlapping(
    '',
    moment()
      .subtract(1, 'minutes')
      .valueOf(),
    commonRecordList
  );

  const resEnd = isOverlapping('', value.endRecordingTime, commonRecordList);

  const resAround = isAroundOverlapping(
    '',
    moment().valueOf(),
    value.endRecordingTime,
    commonRecordList
  );

  if (field === 'duration' || field === 'all') {
    if (resStart.isOverlap === true) {
      startRecordErrors.duration = errMess(locale, resStart).startOverlap;
    }

    if (numberInBetween(value.duration, 15, 240)) {
      startRecordErrors.duration = errMess(locale).timeRange;
      validation = false;
    }

    if (resEnd.isOverlap) {
      startRecordErrors.duration = errMess(locale, resEnd).endOverlap;
      validation = false;
    }

    if (resAround.isOverlap) {
      startRecordErrors.duration = errMess(locale, resAround).aroundOverlap;
      validation = false;
    }
    if (value.type === 'GAME' && isEmpty(`${value.duration}`) === 'isEmptyError') {
      startRecordErrors.duration = errMess(locale).empty;
      validation = false;
    }
  }
  if (field === 'name' || field === 'all') {
    if (isEmpty(value.name.trim()) === 'isEmptyError') {
      startRecordErrors.name = errMess(locale).empty;
      validation = false;
    }
  }
  if (field === 'homeTeamId' || field === 'all') {
    if (value.type === 'GAME' && isEmpty(value.homeTeamId.trim()) === 'isEmptyError') {
      startRecordErrors.homeTeamId = errMess(locale).empty;
      validation = false;
    }
  }
  if (field === 'awayTeamId' || field === 'all') {
    if (value.type === 'GAME' && isEmpty(value.awayTeamId.trim()) === 'isEmptyError') {
      startRecordErrors.awayTeamId = errMess(locale).empty;
      validation = false;
    }
  }
  if (field === 'matchDay' || field === 'all') {
    if (value.type === 'GAME' && isEmpty(value.matchDay.trim()) === 'isEmptyError') {
      startRecordErrors.matchDay = errMess(locale).empty;
      validation = false;
    }
  }
  if (field === 'competitionName' || field === 'all') {
    if (value.type === 'GAME' && isEmpty(value.competitionName.trim()) === 'isEmptyError') {
      startRecordErrors.competitionName = errMess(locale).empty;
      validation = false;
    }
  }
  return { validation, startRecordErrors };
};

