import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { lang } from '../../Langs/langSettings';
import { colors } from '../../Theme/theme';
import Button from '../../Components/Button/Button';
import ScheduledModal from '../ModalWindows/ScheduledModal';
import { ReactComponent as RecordingPic } from '../../assets/recording-panel-pic.svg';
import { ReactComponent as VideoActivityPic } from '../../assets/video-activity-panel-pic.svg';
import { ReactComponent as ScheduledPic } from '../../assets/scheduled-panel-pic.svg';
import { findCameraId } from '../../Services/api/common';
import { getCameraStateByIdAction } from '../../Redux/devices/actions';
import { cameraStateByIdSelector } from '../../Redux/devices/selectors';
import { getEventDataSelector } from '../../Redux/recordings/selectors';
import { getEnvironmentSelector } from '../../Redux/user/selectors';

const panelParams = {
  recording: colors.recordingPanel,
  'video-library': colors.videoActivityPanel,
  scheduled: colors.scheduledPanel
};

const ActionList = ({ locale, BodyInner }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const cameraSystemId = findCameraId(location);
  const eventDataSelector = useSelector(getEventDataSelector);
  const [scheduledModalOpened, setScheduledModalOpened] = useState(false);
  const deviceInfo = useSelector(cameraStateByIdSelector);
  const environmentList = useSelector(getEnvironmentSelector);

  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventDataSelector]);

  const closeModal = () => setScheduledModalOpened(false);
  const openModal = () => setScheduledModalOpened(true);

  const modalDefault = {
    cameraSystemId,
    cameraName: deviceInfo?.cameraName || '',
    type: 'TRAINING',
    startRecordingTime: moment()
      .add(10, 'minute')
      .valueOf(),
    endRecordingTime: moment()
      .add(105, 'minute') // start time + duration
      .valueOf(),
    homeTeamId: '',
    awayTeamId: '',
    duration: 95,
    name: '',
    matchDay: '',
    competitionName: '',
    client: environmentList[0]?.id
  };

  const onClickNavHandler = nav => {
    navigate(`/device-list/${cameraSystemId}/${nav}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BodyInner>
      {!modalDefault.cameraName ? null : (
        <ActionListWrapper>
          <Subheader>{lang(locale)['what would you like to do']}</Subheader>

          <StyledActionList>
            <ActionListInner>
              <PanelWrapper actionType="recording">
                <PanelHeader>{lang(locale)['live recording']}</PanelHeader>
                <StyledRecordingPic />
                <ButtonPane>
                  <StyledButton
                    className="panel-btn"
                    label={lang(locale)['start recording'].toUpperCase()}
                    onClick={() => onClickNavHandler('recording')}
                    type="panel"
                    lettersSpaced
                  />
                </ButtonPane>
              </PanelWrapper>

              <PanelWrapper actionType="video-library">
                <PanelHeader>{lang(locale)['video library']}</PanelHeader>
                <StyledVideoPic />
                <ButtonPane>
                  <StyledButton
                    className="panel-btn"
                    label={lang(locale)['start watching'].toUpperCase()}
                    onClick={() => onClickNavHandler('video-library')}
                    type="panel"
                    lettersSpaced
                  />
                </ButtonPane>
              </PanelWrapper>

              <PanelWrapper actionType="scheduled">
                <PanelHeader>{lang(locale).schedules}</PanelHeader>
                <StyledScheduledPic />
                <ButtonPane>
                  <StyledButton
                    className="panel-btn"
                    label={lang(locale)['scheduled recordings'].toUpperCase()}
                    onClick={() => onClickNavHandler('scheduled-recordings')}
                    type="panel"
                    lettersSpaced
                  />
                  <StyledButton
                    className="panel-btn"
                    label={lang(locale)['start scheduling'].toUpperCase()}
                    onClick={openModal}
                    type="panel"
                    lettersSpaced
                  />
                </ButtonPane>
              </PanelWrapper>
            </ActionListInner>
          </StyledActionList>
          <ScheduledModal
            modalOpened={scheduledModalOpened}
            closeModal={() => closeModal('scheduledModal')}
            defaultValue={modalDefault}
            isNew
            cameraSystemId={cameraSystemId}
            locale={locale}
          />
        </ActionListWrapper>
      )}
    </BodyInner>
  );
};

export default ActionList;

const ActionListWrapper = styled.div(() => ({
  width: '100%',
  paddingBottom: '30px'
}));

const StyledActionList = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const ActionListInner = styled.div(() => ({
  maxWidth: '1100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media(max-width: 1070px)': {
    flexWrap: 'wrap'
  }
}));

const Subheader = styled.div(() => ({
  fontWeight: 600,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: colors.defaultBlackBtn,
  fontSize: '36px',
  padding: '40px',

  '@media(max-width: 1000px)': {
    padding: '30px'
  },
  '@media(max-width: 670px)': {
    fontSize: '28px'
  },
  '@media(max-width: 550px)': {
    fontSize: '24px',
    padding: '15px'
  }
}));

const PanelWrapper = styled.div(props => ({
  width: '270px',
  height: '360px',
  borderRadius: '8px',
  backgroundColor: panelParams[props.actionType],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  margin: '10px',
  padding: '5px',

  '@media(max-width: 1070px)': {
    height: '200px',
    width: '100%',
    padding: '45px 45px 45px 40%',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  '@media(max-width: 630px)': {
    height: '170px',
    margin: '10px 0 10px 0'
  },
  '@media(max-width: 500px)': {
    padding: '20px 15px 20px 30%',
    width: '100%'
  },
  '@media(max-width: 300px)': {
    padding: '5px',
    height: '130px'
  }
}));

const PanelHeader = styled.div(() => ({
  fontWeight: 500,
  fontSize: '24px',
  padding: '20px 0',
  textAlign: 'center',
  '@media(max-width: 1050px)': {
    width: '300px',
    textAlign: 'end'
  },
  '@media(max-width: 600px)': {
    fontSize: '20px'
  },
  '@media(max-width: 460px)': {
    width: '120px',
    textAlign: 'center'
  }
}));

const ButtonPane = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  height: '100px',

  '@media(max-width: 700px)': {
    width: '235px',
    justifyContent: 'flex-end'
  }
}));

const StyledButton = styled(Button)(() => ({
  '&.panel-btn': {
    padding: '0 10px'
  }
}));

const pics = {
  '@media(max-width: 1070px)': {
    position: 'absolute',
    left: '25px',
    top: '25px'
  },
  '@media(max-width: 630px)': {
    width: '150px',
    height: '100px',
    left: '20px'
  },
  '@media(max-width: 560px)': {
    width: '120px',
    height: '80px',
    left: '15px'
  },
  '@media(max-width: 300px)': {
    left: '0',
    width: '100px',
    height: '60px'
  }
};

const StyledRecordingPic = styled(RecordingPic)(() => pics);
const StyledVideoPic = styled(VideoActivityPic)(() => pics);
const StyledScheduledPic = styled(ScheduledPic)(() => pics);

ActionList.propTypes = {
  locale: PropTypes.string
};

ActionList.defaultProps = {
  locale: 'en-US'
};
