import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { colors, parameters } from '../../Theme/theme';
import { ReactComponent as KogniaSmallLogo } from '../../assets/kognia-logo-short.svg';
import { lang } from '../../Langs/langSettings';


const Footer = ({locale}) => {
  const phrases = {
    copyrightPh: `${lang(locale).copyright} ${moment().format('YYYY')}`,
    policyPh: lang(locale)['kognia sports intelligence'],
    allRightsPh: lang(locale)['all rights reserved']
  }
  return (
    <FooterWrapper>
      <FooterInner>
        <Logo className="footer-logo" />
        <Policy>
          {phrases.copyrightPh}
          <PolicyLink href='https://kogniasports.com/privacy-policy/'>{phrases.policyPh}</PolicyLink>
          {phrases.allRightsPh}
        </Policy>
      </FooterInner>
    </FooterWrapper>
)};

export default Footer;

const FooterWrapper = styled.div(() => ({
  // position: 'absolute',
  // top: '100%',
  width: '100%',
  minWidth: '240px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: `${parameters.footerHeight}px`,
  maxHeight: `${parameters.footerHeight}px`,
  backgroundColor: colors.white,
  fontWeight: 400,
  fontSize: '14px',
  color: colors.defaultBlackBtn,

  '@media (max-width: 630px)': {
    fontSize: '12px',
  },
  '@media (max-width: 470px)': {
    fontSize: '8px',
  },

}));

const FooterInner = styled.div(() => ({
  width: '80vw',
  height: '100%',
  maxWidth: '1600px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  whiteSpace: 'nowrap',
  '@media (max-width: 420px)': {
    width: '100%',
  },
}));

const Logo = styled(KogniaSmallLogo)(() => ({
  width: '40px',
  height: '40px',
  minWidth: '50px',
  marginRight: '7px',
  '@media (max-width: 620px)': {
    width: '30px',
    height: '30px',
  },
}));

const Policy = styled.div(() => ({
  display: 'flex',
  '@media (max-width: 450px)': {
    flexWrap: 'wrap',
  },
}));

const PolicyLink = styled.a(() => ({
  textDecoration: 'underline',
  margin: '0 7px',
  color: colors.defaultInputText,
}));

Footer.propTypes = {
  locale: PropTypes.string,
};
Footer.defaultProps = {
  locale: 'en-US'
};