export const getScheduledListSelector = state => state.videosReducer.scheduledList;
export const getFilteredScheduleSelector = state => state.videosReducer.filteredSchedule;
export const getNearestScheduleSelector = state => state.videosReducer.nearestSchedule;
export const getToBeUploadedListSelector = state => state.videosReducer.toBeUploadedList;
export const getInProgressListSelector = state => state.videosReducer.inProgressList;
export const getEventDataSelector = state => state.videosReducer.eventData;
export const getScheduledListFilteredByCameraIdSelector = state =>
  state.videosReducer.scheduledListFilteredByCameraId;
export const getToBeUploadedListFilteredByCameraIdSelector = state =>
  state.videosReducer.toBeUploadedListFilteredByCameraId;
export const getInProgressListFilteredByCameraIdSelector = state =>
  state.videosReducer.inProgressListFilteredByCameraId;
export const getCameraStateByCameraIdSelector = state => state.videosReducer.cameraStateByCameraId;