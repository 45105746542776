import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { colors, zIndexes } from '../../Theme/theme';
import { lang } from '../../Langs/langSettings';

import { getDeviceListSelector } from '../../Redux/devices/selectors';
import { getNearestScheduleSelector } from '../../Redux/recordings/selectors';
import { ReactComponent as TacticalAnalysisPic } from '../../assets/tactical-analysis.svg';

import RecordTile from '../../Components/RecordTile/RecordTile';
import Button from '../../Components/Button/Button';
import DeviceTile from '../../Components/DeviceTile';
import { panelParameters } from '../../Components/TileWrapper/TileWrapper';
import SubHeader from '../../Components/SubHeader';
import { filterArray } from '../../Services/api/common';

const DeviceList = ({ locale, BodyInner }) => {
  const deviceList = useSelector(getDeviceListSelector);
  const nearestScheduled = useSelector(getNearestScheduleSelector);

  const getCameraName = cameraSystemId =>
    deviceList[0]
      ? filterArray(deviceList, 'cameraSystemId', cameraSystemId)[0].cameraName
      : 'Undefined Camera';

  useEffect(() => () => window.scrollTo(0, 0), []);

  return (
    <BodyInner>
      <StyledDeviceList>
        <SubHeader subheader={lang(locale)['device list']} />
        <DeviceListBody>
          {deviceList.length > 0 ? (
            <SubPanel>
              {deviceList.map(item => (
                <DeviceTile
                  key={item.cameraSystemId}
                  cameraSystemId={item.cameraSystemId}
                  cameraName={item.cameraName}
                  recording={item.cameraStream}
                  portable={item.portable}
                  scheduledVideoCount={item.scheduleList?.length}
                  locale={locale}
                />
              ))}
            </SubPanel>
          ) : (
            <EmptyWarning>{lang(locale)['you have no available device']}</EmptyWarning>
          )}

          <ScheduleHeader>
            {lang(locale)['nearest scheduled recordings'].toUpperCase()}
          </ScheduleHeader>
          {nearestScheduled.length > 0 ? (
            <ScheduledSubPanel>
              {nearestScheduled.map(item => (
                <RecordTile
                  data={Object.assign(item, { cameraName: getCameraName(item.cameraSystemId) })}
                  key={`${item.recordId}`}
                  panelType="scheduled"
                  locale={locale}
                  nearestScheduled
                  onPicClickNavLink={`/device-list/${item.cameraSystemId}/scheduled/${item.recordId}`}
                />
              ))}
            </ScheduledSubPanel>
          ) : (
            <EmptyWarning>{lang(locale)['you have no scheduled recordings']}</EmptyWarning>
          )}
        </DeviceListBody>
        <TacticalAnalysis>
          <div>
            <StyledTacticalAnalysisPic />
            <TacticalAnalysisText>
              {lang(locale)['click to see tactical analysis for uploaded videos']}
            </TacticalAnalysisText>
          </div>

          <TacticalAnalysisBtn
            lettersSpaced
            label={lang(locale)['go to tactical analysis'].toUpperCase()}
            onClick={() => {
              location.assign('https://app.kogniasports.com');
            }}
            type="panel"
            className="tacticalAnalysisBtn"
          />
        </TacticalAnalysis>
      </StyledDeviceList>
    </BodyInner>
  );
};

export default DeviceList;

const StyledDeviceList = styled.div(() => ({
  width: '101%',
  position: 'relative',
  minHeight: '85vh',
  paddingBottom: '20px',
  '@media(max-width: 535px)': {
    minHeight: 'fit-content'
  }
}));

const DeviceListBody = styled.div(() => ({
  width: '100%',
  minWidth: '230px',
  boxSizing: 'border-box',
  minHeight: '65vh',
  '@media(max-width: 535px)': {
    minHeight: 'fit-content'
  }
}));

const SubPanel = styled.div(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap'
}));

const ScheduleHeader = styled.div(() => ({
  width: '100%',
  fontWeight: 500,
  fontSize: '14px',
  letterSpacing: '1.6px',
  color: colors.scheduledGray,
  margin: '40px 0 25px 0'
}));

const ScheduledSubPanel = styled.div(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  height: '330px',
  overflowY: 'hidden',
  '& > div': {
    marginBottom: '120px'
  }
}));

const EmptyWarning = styled.div(() => ({
  width: '100%',
  height: `${panelParameters.width * (2 / 3) + 20}px`,
  color: colors.disabledBtn,
  fontSize: '24px'
}));

const TacticalAnalysis = styled.div(() => ({
  width: '98.5%',
  height: '110px',
  padding: '24px',
  backgroundColor: colors.tacticalAnalysisPanel,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: '0px 10px 30px rgba(38, 41, 64, 0.15)',
  borderRadius: '8px',
  '@media(max-width: 1100px)': {
    height: '90px'
  },
  '@media(max-width: 480px)': {
    justifyContent: 'center'
  },
  '& > div': {
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 480px)': {
      '&:first-child': {
        display: 'none'
      }
    }
  }
}));

const StyledTacticalAnalysisPic = styled(TacticalAnalysisPic)(() => ({
  marginRight: '40px',
  width: '100px',
  minWidth: '80px',
  height: '60px',
  '@media(max-width: 1050px)': {
    marginRight: '15px'
  }
}));
const TacticalAnalysisText = styled.div(() => ({
  fontWeight: 500,
  fontSize: '24px',
  fontColor: colors.defaultBlackBtn,
  marginRight: '5px',
  '@media(max-width: 1350px)': {
    fontSize: '20px'
  },
  '@media(max-width: 950px)': {
    fontSize: '17px'
  },
  '@media(max-width: 770px)': {
    display: 'none'
  }
}));

const TacticalAnalysisBtn = styled(Button)(() => ({
  '&.tacticalAnalysisBtn': {
    backgroundColor: colors.white,
    fontSize: '11px',
    width: '250px',
    minWidth: '200px',
    '@media(max-width: 830px)': {
      width: '200px'
    },
    '@media(max-width: 770px)': {
      width: '200px'
    }
  }
}));

DeviceList.propTypes = {
  locale: PropTypes.string
};

DeviceList.defaultProps = {
  locale: 'en-US'
};
