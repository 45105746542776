import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors } from '../../Theme/theme';

export const panelParameters = {
  width: 368,
  device: {
    inner: 0.45 * (2 / 3),
    smallInner: 0.45 * (2 / 3),
    bottom: 0.55 * (2 / 3),
    smallBottom: 0.55 * (2 / 3)
  },
  record: {
    inner: 0.7 * (2 / 3),
    smallInner: 0.55 * (2 / 3),
    bottom: 0.3 * (2 / 3),
    smallBottom: 0.45 * (2 / 3)
  }
};
// Template of device and recording tiles
const PanelTemplate = ({ innerContent, bottomContent, templateType, visible }) => (
  <PanelWrapper width={panelParameters.width} visible={visible}>
    <PanelInner templateType={templateType} width={panelParameters.width}>
      {innerContent}
    </PanelInner>

    <PanelBottom templateType={templateType} width={panelParameters.width}>
      {bottomContent}
    </PanelBottom>
  </PanelWrapper>
);

export default PanelTemplate;

const PanelWrapper = styled.div(props => ({
  width: `${props.width}px`,
  minWidth: '240px',
  height: `${panelParameters.width * (2 / 3)}px`,
  margin: '0 10px 20px 0',
  padding: '10px',
  borderRadius: '4px',
  backgroundColor: colors.white,
  display: props.visible ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'center',

  '@media (max-width: 535px)': {
    boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.2)',
    margin: '0 0 15px 0'
  },
  '@media (max-width: 420px)': {
    width: '100%'
  }
}));

const PanelInner = styled.div(props => ({
  position: 'relative',
  width: '100%',
  height: `${props.width * panelParameters.record.inner}px`,
  backgroundColor: colors.mainBackground,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media (max-width: 535px)': {
    height: `${props.width * panelParameters[props.templateType].inner}px`
  },
  '@media (max-width: 350px)': {
    height: `${props.width * panelParameters[props.templateType].smallInner}px`
  }
}));

const PanelBottom = styled.div(props => ({
  width: '100%',
  height: `${props.width * panelParameters.record.bottom}px`,
  minHeight: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  '@media (max-width: 535px)': {
    height: `${props.width * panelParameters[props.templateType].bottom}px`,

    '& > div': {
      width: '100%'
    }
  },
  '@media (max-width: 350px)': {
    height: `${props.width * panelParameters[props.templateType].smallBottom}px`
  }
}));

PanelTemplate.propTypes = {
  innerContent: PropTypes.node,
  bottomContent: PropTypes.node,
  templateType: PropTypes.oneOf(['device', 'record']),
  visible: PropTypes.bool
};

PanelTemplate.defaultProps = {
  innerContent: () => {},
  bottomContent: () => {},
  templateType: 'record',
  visible: true
};
