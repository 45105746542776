import React, { useCallback, useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { nanoid } from 'nanoid';
import store from './Redux/store';
import PageRouter from './Containers/Router/Router';
import Auth0ProviderWithHistory from './Containers/Router/Auth0ProviderWithHistory';

import {
  updateEventDataAction,
  updateRecordingListAction,
  updateScheduleListAction
} from './Redux/recordings/actions';
import {
  updateDeviceListAction,
  updateRecordingListForCamerasAction,
  updateScheduledListForCamerasAction
} from './Redux/devices/actions';
import { handleResponseAction } from './Redux/responseHandler/actions';

const SseLayout = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const serverAddress = process.env.REACT_APP_SERVER_URL;
  const addressMixin = serverAddress.slice(-1) !== '/' ? '/' : '';

  // let token;
  // const setToken = useCallback(async() => {
  //   if(isAuthenticated){
  //     token = await getAccessTokenSilently()
  //   }}, [isAuthenticated])

  //   useEffect(() => {setToken()}, [user])

  const handleSSEData = useCallback(event => {
    const eventData = JSON.parse(event.data);
    dispatch(updateRecordingListAction(eventData.records));
    dispatch(updateScheduleListAction(eventData.scheduledRecords));
    dispatch(updateDeviceListAction(eventData.updatedCameras));
    dispatch(updateRecordingListForCamerasAction(eventData.records));
    dispatch(updateScheduledListForCamerasAction(eventData.scheduledRecords));
    dispatch(updateEventDataAction(eventData));
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    const requestId = user['https://kogniasports.com/app_metadata']?.kognia_user_id;
    const randomId = nanoid();
    const evtSource = new EventSource(
      `${serverAddress}${addressMixin}subscription/${requestId}/web_${randomId}`,
      {
        withCredentials: false
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
      }
    );
    evtSource.onopen = () => {
      console.log('Opened');
    };
    evtSource.onmessage = event => {
      handleSSEData(event);
    };
    evtSource.onerror = error => {
      dispatch(handleResponseAction({ type: 'error', message: 'sse' }));
      evtSource.close();
    };
    // eslint-disable-next-line consistent-return
    return () => evtSource.close();
  }, [user]);
  return null;
};

const App = () => (
  <Router>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <SseLayout />
        <PageRouter />
      </Provider>
    </Auth0ProviderWithHistory>
  </Router>
);

export default App;
