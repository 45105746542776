import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { lang } from '../../Langs/langSettings';
import { colors, zIndexes } from '../../Theme/theme';
import Button from '../Button/Button';
import RedRecordSign from '../StatusMarkers/RedRecordSign';
import PanelTemplate from '../TileWrapper';
import { ReactComponent as RecordingCamera } from '../../assets/recording-camera.svg';

// tile with device info on the device list
const DeviceTile= ({
  cameraSystemId, cameraName, portable, scheduledVideoCount, recording, locale
}) => {
  const navigate = useNavigate();
  const [hintVisible, setHintVisible] = useState(false);

  const showHint = () => {
    setHintVisible(true);
  };
  const hideHint = () => {
    setHintVisible(false);
  };

  const phrases = {
    portablePh: portable ? lang(locale).portable.toUpperCase() : lang(locale).fixed.toUpperCase(),
    scheduledPh:`${scheduledVideoCount} ${lang(locale)[scheduledVideoCount === 1 ? 'scheduled recording' : 'scheduled recordings'].toLowerCase()}`,
    connectPh: lang(locale).connect,
  }

  return (
    <PanelTemplate
      templateType="device"
      innerContent={(
        <>
          {recording && <RedRecordSign locale={locale} />}
          <RecordingCamera />
        </>
    )}
      bottomContent={(
        <>
          <InfoField>
            <InfoInner>
              <Device
                cameraSystemId={cameraSystemId}
                cameraName={cameraName}
                onMouseOver={showHint}
                onMouseOut={hideHint}
              >
                {cameraName}
                <LongNameBox hintVisible={hintVisible}>{cameraName}</LongNameBox>
              </Device>
              <Fixed>{phrases.portablePh}</Fixed>
            </InfoInner>
            {scheduledVideoCount !== 0 && <Scheduled>{phrases.scheduledPh}</Scheduled>}
          </InfoField>
          <Button
            label={phrases.connectPh}
            type="connect"
            height="33px"
            width="90px"
            onClick={() => navigate(`/device-list/${cameraSystemId}`)}
          />
        </>
      )}
    />
  );
};

export default DeviceTile;

const InfoField = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '10px',
  cursor: 'default',
  width: '70%',

  '@media (max-width: 535px)': {
    width: '100%',
  },
}));

const InfoInner = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

const Device = styled.div(() => ({
  fontWeight: 500,
  fontSize: '16px',
  width: '100%',
  marginRight: '15px',
  marginBottom: '5px',
  position: 'relative',
  overflow: 'hidden',
  cursor: 'default',
  '@media (max-width: 535px)': {
    width: '100%',
    fontSize: '14px',
  },
}));

const LongNameBox = styled.div((props) => ({
  display: props.hintVisible ? 'block' : 'none',
  position: 'absolute',
  border: `1px solid ${colors.disabledBtn}`,
  padding: '3px',
  borderRadius: '4px',
  backgroundColor: colors.white,
  fontWeight: 400,
  fontSize: '13px',
  zIndex: zIndexes.deviceTileLongNameBox,
  cursor: 'default',
}));

const Fixed = styled.div(() => ({
  color: colors.focusedInputBorder,
  fontWeight: 500,
  fontSize: '11px',
  letterSpacing: '1.6px',
  width: '100%',

  '@media (max-width: 535px)': {
    width: '100%',
  },

}));
const Scheduled = styled.span(() => ({
  fontWeight: 400,
  fontSize: '12px',
  color: colors.scheduledGray,
  marginTop: '5px',
}));

DeviceTile.propTypes = {
  cameraSystemId: PropTypes.string,
  cameraName: PropTypes.string,
  portable: PropTypes.bool,
  scheduledVideoCount: PropTypes.number,
  recording: PropTypes.bool,
  locale: PropTypes.string,

};
DeviceTile.defaultProps = {
  cameraSystemId: 'Camera ID',
  cameraName: 'Camera Name',
  portable: false,
  scheduledVideoCount: 0,
  recording: false,
  locale: 'en-US'
};
