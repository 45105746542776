/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, zIndexes } from '../../Theme/theme';
import { lang } from '../../Langs/langSettings';
import { ReactComponent as FlipSmartphonePic } from '../../assets/flip-smartphone.svg';
import { ReactComponent as CameraPic } from '../../assets/camera-small.svg';
// import { ReactComponent as ControlBackTime } from '../../assets/video-controls/back-time.svg';
// import { 
//   ReactComponent as ControlForwardTime 
// } from '../../assets/video-controls/forward-time.svg';
// import { ReactComponent as ControlPause } from '../../assets/video-controls/pause.svg';
// import { ReactComponent as ControlPlay } from '../../assets/video-controls/play.svg';
// import { ReactComponent as ControlToStart } from '../../assets/video-controls/to-start.svg';
import SelectInput from '../Inputs/SelectInput';
import DifferentCameras from './PlayerViews/DifferentCameras';
import Panorama from './PlayerViews/Panorama';
import Tactical from './PlayerViews/Tactical';

const VideoPlayer = ({ locale, buttonPane, playerType, streamType, streamLinks }) => {
  // const panoramaView = document.getElementById('recordedPanorama');
  // const tacticalView = document.getElementById('recordedTactical');

  // const [currentVideo, setCurrentVideo] = useState(panoramaView);
  // const [videoTimes, setVideoTimes] = useState([0, 0]);
  // const [isPlaying, setIsPlaying] = useState(false);
  const [videoType, setVideoType] = useState('');
  const [videoTypeVariants, setVideoTypeVariants] = useState([]);

  // const processTime = useCallback(
  //   seconds => {
  //     if (!seconds || !isFinite(seconds)) {
  //       return '00:00';
  //     }
  //     const mins = Math.floor(seconds / 60);
  //     const secs = Math.floor(seconds - mins * 60);
  //     return `${mins.toString().length === 1 ? '0' : ''}${mins}:${
  //       secs.toString().length === 1 ? '0' : ''
  //     }${secs}`;
  //   },
  //   [currentVideo?.currentTime]
  // );

  // useEffect(() => {
  //   if (!currentVideo) return;
  //   currentVideo.ontimeupdate = () => {
  //     setVideoTimes([currentVideo.currentTime, currentVideo.duration]);
  //   };
  // }, [currentVideo]);

  useEffect(() => {}, [streamLinks]);

  // options in the drop-down
  const videoTypes = {
    individual: {
      longName: lang(locale)['3 individual cameras'],
      shortName: lang(locale)['3 cameras']
    },
    panorama: {
      longName: lang(locale).panorama,
      shortName: lang(locale).panorama
    },
    tactical: {
      longName: lang(locale)['tactical camera'],
      shortName: lang(locale)['tactical camera']
    }
  };

  // if recording is not started (playerType === liveStream) --> drop-down should not be visible
  // if we have no link for specific type streaming, this type should be removed from the drop-down
  // if type of video is toBeUpload, video from 3 cameras should be removed from drop-down
  const videoTypeSelectorCreate = () => {
    if (!playerType || !streamLinks) {
      return [];
    }
    if (playerType === 'liveStream') {
      // eslint-disable-next-line consistent-return
      return [];
    }
    const res = [];

    if (streamLinks?.individual[0] && streamType !== 'toBeUpload') {
      res.push(videoTypes.individual);
    }
    if (streamLinks?.panorama[0]) {
      res.push(videoTypes.panorama);
    }
    if (streamLinks?.tactical[0]) {
      res.push(videoTypes.tactical);
    }
    // eslint-disable-next-line consistent-return
    return res;
  };

  // sets video type
  const onChangeVideoTypeHandler = useCallback(value => {
    let res = '';
    Object.keys(videoTypes).forEach(key => {
      if (videoTypes[key].longName === value) {
        res = key;
        setVideoType(key);
      }
      setVideoType(res);
    });
    // isPlaying && videoControllers('play');
  }, []);

  useEffect(() => {
    setVideoTypeVariants(videoTypeSelectorCreate().map(item => item.longName));
  }, [streamLinks, playerType]);

  // if video type was removed wile user watching it,
  // user should be switched to another available video type
  useEffect(() => {
    if (videoTypeVariants.length === 0) {
      return;
    }
    if (videoTypeVariants.indexOf(videoTypes[videoType]?.longName) !== -1) {
      return;
    }
    onChangeVideoTypeHandler(videoTypeVariants[0]);
  }, [videoTypeVariants]);

  useEffect(() => {}, [videoType]);

  // const videoControllers = (request, setTime) => {
  //   if (streamType !== 'toBeUpload' || !currentVideo) {
  //     return;
  //   }
  //   const { currentTime, duration } = currentVideo;

  //   switch (request) {
  //     case 'play':
  //       currentVideo.play();
  //       break;
  //     case 'pause':
  //       currentVideo.pause();
  //       break;
  //     case '5secBack':
  //       if (currentTime >= 5) {
  //         currentVideo.currentTime = currentTime - 5;
  //       } else {
  //         currentVideo.currentTime = 0;
  //       }
  //       break;
  //     case '5secForward':
  //       if (currentTime + 5 <= duration) {
  //         currentVideo.currentTime = currentTime + 5;
  //       } else {
  //         currentVideo.currentTime = duration;
  //       }
  //       break;
  //     case 'toStart':
  //       currentVideo.currentTime = 0;
  //       break;
  //     case 'setTime':
  //       currentVideo.currentTime = setTime;
  //       break;

  //     default:
  //       break;
  //   }
  // }
  // Update of video types list, on player type change
  useEffect(() => {
    const value = videoTypeSelectorCreate();
    // console.log(value);
    onChangeVideoTypeHandler(value?.length > 0 ? value[0].longName : '');
  }, [playerType]);

  // useEffect(() => {}, [currentVideo?.currentTime]);

  // useEffect(() => {
  //   if (isPlaying) {
  //     videoControllers('play');
  //   }
  //   if (!isPlaying) {
  //     videoControllers('pause');
  //   }
  // }, [isPlaying]);

  // useEffect(() => {
  //   videoControllers('setTime', videoTimes[0]);
  //   isPlaying && videoControllers('play');
  // }, [currentVideo]);

  // useEffect(() => {
  //   if (!tacticalView || !panoramaView) {
  //     return;
  //   }
  //   videoControllers('pause');
  //   if (videoType === 'tactical') {
  //     setCurrentVideo(tacticalView);
  //     return;
  //   }
  //   setCurrentVideo(panoramaView);
  // }, [videoType]);

  return (
    <>
      <HorizontalFlipNote>
        <StyledFlipPic />
        {lang(locale)['for the best user experience, flip your smartphone horizontally']}
      </HorizontalFlipNote>
      <Player>
        <PanelsWrapper>
          {videoTypeSelectorCreate()[0] && (
            <VideoMenuWrapper>
              <VideoTypeMenu
                className="video-type-menu"
                options={videoTypeVariants}
                chosenOpt={videoTypes[videoType]?.shortName?.toUpperCase()}
                width="250px"
                onChange={value => onChangeVideoTypeHandler(value)}
              >
                <StyledCameraPic />
              </VideoTypeMenu>
            </VideoMenuWrapper>
          )}

          {streamType === 'schedule' && (
            <DifferentCameras
              streamLinks={streamLinks.individual}
              autoplay
              videoIds={['live1', 'live2', 'live3']}
              isVisible
            />
          )}
          {streamType === 'recording' && (
            <>
              {streamLinks &&
                streamLinks.individual[0]?.length > 0 &&
                videoType === 'individual' && (
                  <DifferentCameras
                    streamLinks={streamLinks.individual}
                    autoplay
                    videoIds={['live1', 'live2', 'live3']}
                    isVisible={videoType === 'individual'}
                  />
                )}
              {streamLinks && streamLinks.panorama[0]?.length > 0 && videoType === 'panorama' && (
                <Panorama
                  streamLinks={streamLinks.panorama}
                  autoplay
                  videoIds={['livePanorama']}
                  isVisible={videoType === 'panorama'}
                />
              )}

              {streamLinks && streamLinks.tactical[0]?.length > 0 && videoType === 'tactical' && (
                <Tactical
                  streamLinks={streamLinks.tactical}
                  videoIds={['liveTactical']}
                  autoplay
                  isVisible={videoType === 'tactical'}
                />
              )}
            </>
          )}
          {streamType === 'toBeUpload' && (
            <>
              <Panorama
                streamLinks={streamLinks.panorama}
                videoIds={['recordedPanorama']}
                isVisible={videoType === 'panorama'}
                autoplay={false}
              />
              <Tactical
                streamLinks={streamLinks.tactical}
                videoIds={['recordedTactical']}
                isVisible={videoType === 'tactical'}
                autoplay={false}
              />
            </>
          )}

          {/* {streamType === 'toBeUpload' && (
            <ControlsWrapper>
              <ButtonsWrapper>
                <StyledControlToStart onClick={() => videoControllers('toStart')} />
                <StyledControlBackTime onClick={() => videoControllers('5secBack')} />
                <StyledControlPlay onClick={() => setIsPlaying(true)} />
                <StyledControlPause onClick={() => setIsPlaying(false)} />
                <StyledControlForwardTime onClick={() => videoControllers('5secForward')} />
              </ButtonsWrapper>

              <VideoBar
                min={0}
                max={videoTimes[0]}
                value={videoTimes[1]}
                step="1"
                trackStyle={[{ width: `${(videoTimes[0] / videoTimes[1]) * 100}%` }]}
              />
              <TimeWrapper>
                {`${processTime(currentVideo?.currentTime)}/${processTime(currentVideo?.duration)}`}
              </TimeWrapper>
            </ControlsWrapper>
          )} */}
        </PanelsWrapper>
        {buttonPane}
      </Player>
    </>
  );
};
export default VideoPlayer;

const Player = styled.div(() => ({
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}));

const PanelsWrapper = styled.div(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '50px 0',
  overflow: 'hidden',
  height: 'fit-content',
  minHeight: '250px',
  backgroundColor: colors.panelsText,
  '@media (max-height: 350px)': {
    minHeight: '200px'
  }
}));

const VideoMenuWrapper = styled.div(() => ({
  width: '100vw',
  height: '50px',
  position: 'absolute',
  top: 0
}));
const VideoTypeMenu = styled(SelectInput)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 0,
  zIndex: zIndexes.videoTypeMenu,
  '&.video-type-menu > *': {
    backgroundColor: colors.defaultInputText,
    color: colors.white,
    borderRadius: 0,
    boxShadow: 'none',
    fill: colors.white
  },
  '&.video-type-menu > input': {
    paddingLeft: '45px',
    fontSize: '11px',
    fontWeight: 400,
    letterSpacing: 2,
    position: 'relative'
  },
  '& .video-type-menu-options': {
    position: 'absolute',
    backgroundColor: colors.white,
    top: '50px',
    left: '120px',
    borderRadius: '4px',
    '@media(max-width: 380px)': {
      left: 0
    }
  }
}));

const StyledCameraPic = styled(CameraPic)(() => ({
  position: 'absolute',
  top: '12px',
  left: '10px',
  width: '20px',
  height: '20px',
  fill: colors.white
}));

const HorizontalFlipNote = styled.div(() => ({
  position: 'relative',
  // top: '80px',
  width: '100%',
  height: '70px',
  padding: '10px',
  display: 'none',
  borderRadius: '8px',
  backgroundColor: colors.white,
  margin: '15px 0',
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 500,
  '@media (orientation: portrait)': {
    display: 'flex',
    alignItems: 'center'
  }
  // '@media(max-width: 535px)': {
  //   top: '60px',
  // },
}));

const StyledFlipPic = styled(FlipSmartphonePic)(() => ({
  width: '50px',
  height: '50px',
  marginRight: '15px'
}));

// const ControlsWrapper = styled.div(() => ({
//   position: 'absolute',
//   bottom: '15px',
//   width: '100%',
//   padding: '0 5px',
//   // height: '5px',
//   display: 'flex',
//   color: colors.white,
//   fontSize: '14px',
//   alignItems: 'center'
//   // justifyContent: 'space-between',
// }));

// const ButtonsWrapper = styled.div(() => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   width: '130px',
//   minWidth: '130px',
//   padding: '0 10px'
// }));

// const VideoBar = styled(Slider)(() => ({
//   '&.rc-slider': {
//     width: 'calc(100% - 130px - 85px - 20px)',
//     height: '3px',
//     overflow: 'hidden'
//   },

//   '& > *.rc-slider-rail': {
//     backgroundColor: 'RGBA(255, 255, 255, 0.5)',
//     height: '3px'
//   },
//   '& > *.rc-slider-track': {
//     backgroundColor: colors.white,
//     height: '3px',
//     position: 'absolute',
//     top: 0
//   }
// }));
// const TimeWrapper = styled.div(() => ({
//   width: '85px',
//   minWidth: '85px',
//   padding: '0 10px'
// }));

// const buttonsStyle = {
//   fill: colors.white,
//   cursor: 'pointer',
//   '&:hover': {
//     fill: colors.disabledPanelBtn,
//     transform: '0.3s'
//   }
// };

// const StyledControlToStart = styled(ControlToStart)(() => buttonsStyle);
// const StyledControlBackTime = styled(ControlBackTime)(() => buttonsStyle);
// const StyledControlPlay = styled(ControlPlay)(() => buttonsStyle);
// const StyledControlPause = styled(ControlPause)(() => buttonsStyle);
// const StyledControlForwardTime = styled(ControlForwardTime)(() => buttonsStyle);
