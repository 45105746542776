import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { handleResponseAction } from '../../Redux/responseHandler/actions';
import { getRespSelector } from '../../Redux/responseHandler/selectors';
import { colors } from '../../Theme/theme';
import { lang } from '../../Langs/langSettings';

const ResponseFromServer = locale => {
  const message = {
    sse: {
      error: lang(locale)['sse fail']
    },
    createNewSchedule: {
      success: lang(locale)['create schedule success'],
      error: lang(locale)['create schedule fail']
    },
    deleteSchedule: {
      success: lang(locale)['delete recording success'],
      error: lang(locale)['delete recording fail']
    },
    editSchedule: {
      success: lang(locale)['update recording success'],
      error: lang(locale)['update recording fail']
    },
    recordStart: {
      success: lang(locale)['start recording success'],
      error: lang(locale)['start recording fail']
    },
    recordStop: {
      success: lang(locale)['stop recording success'],
      error: lang(locale)['stop recording fail']
    },
    recordDelete: {
      success: lang(locale)['delete recording success'],
      error: lang(locale)['delete recording fail']
    },
    recordUpload: {
      success: lang(locale)['upload recording success'],
      error: lang(locale)['upload recording fail']
    },
    recordUploadAll: {
      success: lang(locale)['upload recording success'],
      error: lang(locale)['upload recording fail']
    },
    updateUserLanguage: {
      success: lang(locale)['update language success'],
      error: lang(locale)['update language fail']
    },
    getStreamLinks: {
      error: lang(locale)['sse fail']
    }
  };
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const dispatch = useDispatch();
  const [msg, setMsg] = useState('');
  const [type, setType] = useState('');
  const responseFromServer = useSelector(getRespSelector);

  const closeMessage = useCallback(() => {
    dispatch(handleResponseAction({ type: '', message: '' }));
  }, []);

  useEffect(() => {
    if (responseFromServer.type !== '') {
      setMsg(message[responseFromServer.message][responseFromServer.type]);
      setType(responseFromServer.type);
      setIsMessageVisible(true);
      setTimeout(closeMessage, 3000);
      return;
    }
    setMsg('');
    setType('');
    setIsMessageVisible(false);
  }, [responseFromServer]);

  return (
    <PaneWrapper visible={isMessageVisible} type={type}>
      {msg}
    </PaneWrapper>
  );
};

export default ResponseFromServer;

const PaneWrapper = styled.div(props => ({
  position: 'fixed',
  right: '10px',
  top: '90px',
  zIndex: 5,
  display: props.visible ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  width: '300px',
  height: '50px',
  borderRadius: '4px',
  color: colors.white,
  backgroundColor: props.type === 'success' ? colors.successColor : colors.recordingDot
}));
