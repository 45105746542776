import userType from './consts';

// export const editUserMetadataAction = (payload) => ({
//   type: userType.editUserMetadata,
//   payload,
// });

export const updateUserLangAction = (payload) => ({
  type: userType.updateUserLang,
  payload,
});

export const getEnvironmentsAction = (payload) => ({
  type: userType.getEnvironments,
  payload,
})

export const updateEnvironmentsAction = (payload) => ({
  type: userType.updateEnvironments,
  payload,
})