import styled from 'styled-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { colors, zIndexes } from '../../../Theme/theme';
import TextInput from '../TextInput';
import { ReactComponent as ClockPic } from '../../../assets/clock.svg';
import MainScrollPicker from './MainScrollPicker';

const hoursArray = [];
for (let i = 0; i <= 23; i += 1) {
  hoursArray.push(`0${i}`.slice(-2));
}
const minutesArray = [];
for (let i = 0; i <= 59; i += 1) {
  minutesArray.push(`0${i}`.slice(-2));
}

const TimeScrollPicker = ({
  onChange, width, label, errorText, type, value, onFocus, onBlur, disabled, required
}) => {
  const [activePanel, setActivePanel] = useState(false);
  // time comes in format hh:mm; if time is not defined ":" comes
  const hours = useRef(value?.split(':')[0]);
  const mins = useRef(value?.split(':')[0]);

  useEffect(() => {
    hours.current = value?.split(':')[0];
    mins.current = value?.split(':')[1];
  }, [value]);

  
  const toggleTimePicker = (e) => {
    activePanel && e.target.blur();
    setActivePanel(!activePanel);
  };
  const closeTimePicker = () => {
    setActivePanel(false);
  };

  const onHoursChange = useCallback((val) => {
    onChange(`${val}:${mins.current}`);
  }, []);
  const onMinutesChange = useCallback((val) => {
    onChange(`${hours.current}:${val}`);
  }, []);

  // set time on open picker: defined or default
  useEffect(() => {
    if (activePanel) {
      hours.current = hours.current || moment().add(10, 'minute').format('HH');
      mins.current =  mins.current || moment().add(10, 'minute').format('mm');
      onChange(`${hours.current}:${mins.current}`);
      document.addEventListener('click', (e) => closeTimePicker(e), { once: 'true' });
      onFocus();
    }
    if (!activePanel) {
      onBlur();
    }
  }, [activePanel]);

  return (
    <TimePickerWrapper
      disabled={disabled}
      onClick={(e) => { !disabled && toggleTimePicker(e); }}
      width={width}
      active={activePanel}
    >
      <ModalMiddleware opened={activePanel} />
      <StyledTextInput
        value={hours.current && mins.current ? `${hours.current}:${mins.current}` : '--:--'}
        width='100%'
        label={label}
        errorText={errorText}
        type={type}
        className="text-in-scroll-select"
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        readOnly
        required={required}
        active={activePanel}
      >
        <StyledClockPic />
      </StyledTextInput>
   
      <TimePickerBody
        active={activePanel}
        onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
        onMouseDown={(e) => { e.stopPropagation(); e.preventDefault(); }}
      >
        <MainScrollPicker
          options={hoursArray}
          onChange={(val) => { onHoursChange(val); }}
          pickerValue={hours.current || moment().add(10, 'minute').format('HH')}
        />
        <span>:</span>
        <MainScrollPicker
          options={minutesArray}
          onChange={(val) => { onMinutesChange(val); }}
          pickerValue={mins.current || moment().add(10, 'minute').format('mm')}
        />
      </TimePickerBody>
    </TimePickerWrapper>
  );
};

export default TimeScrollPicker;

const TimePickerWrapper = styled.div((props) => ({
  width: props.width,
  cursor: 'pointer',
  boxShadow: 'none',
  position:'relative',
}));

const StyledClockPic = styled(ClockPic)(() => ({
  position: 'absolute',
  right: '12px',
  top: '12px',
  zIndex: zIndexes.timeScrollClockPic,
}));

const StyledTextInput = styled(TextInput)((props) => ({
  '&.text-in-scroll-select': {
    width: props.width,
    cursor: props.disabled ? 'default' : 'pointer',
    caretColor: 'transparent',
    boxShadow: props.active && `0px 0px 0px 1px ${colors.defaultPurpleBtn}`,
    zIndex: zIndexes.textInputsLayer,
  },
}));

const TimePickerBody = styled.div((props) => ({
  boxShadow: '2px 4px 8px rgba(63,48,186, 0.2)',
  border: `1px solid ${colors.defaultPurpleBtn}`,
  backgroundColor: colors.white,
  width: '130px',
  display: props.active ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderRadius: '8px',
  padding: '20px',
  marginTop: 0,
  position: 'absolute',
  zIndex: zIndexes.popUpInput,
  '@media(max-width: 500px)': {
    padding: '10px',
  },
  '& > span': {
    color: colors.activePurpleBtn,
    fontWeight: 500,
  },
}));

const ModalMiddleware = styled.div((props) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: props.opened ? 'block' : 'none',
  zIndex: zIndexes.inputBackground,
  cursor: 'default',
}));

TimeScrollPicker.propTypes = {
  onChange: PropTypes.func,
  width: PropTypes.string,
  label: PropTypes.string,
  errorText: PropTypes.string,
  type: PropTypes.oneOf(['default', 'success', 'error']),
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
TimeScrollPicker.defaultProps = {
  onChange: () => {},
  width: 'fit-content',
  label: '',
  errorText: '',
  type: 'default',
  value: '',
  onFocus: () => {},
  onBlur: () => {},
  disabled: false,
  required: false
};


