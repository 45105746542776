import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Preloader from "../../Components/Preloader";

const ProtectedRoute = ({ component, ...propsForComponent }) => {
  const AuthComponent = withAuthenticationRequired(component, {
    onRedirecting: () => <Preloader />
  });
  return <AuthComponent {...propsForComponent} />;
};

export default ProtectedRoute;
