import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { lang } from '../../Langs/langSettings';
import { isNumber } from '../../Services/validation/validationRules';
import { validateStartRecording } from './validation';

import ModalContainer from '../../Components/ModalContainer';
import TextInput from '../../Components/Inputs/TextInput';
import SelectInput from '../../Components/Inputs/SelectInput';
import RadioButton from '../../Components/Inputs/RadioButton';

import {
  filterInProgressListByCameraIdAction,
  filterScheduleListByCameraIdAction,
  startRecordingAction
} from '../../Redux/recordings/actions';

import { colors } from '../../Theme/theme';
import { cameraStateByIdSelector } from '../../Redux/devices/selectors';
import { getEventDataSelector } from '../../Redux/recordings/selectors';
import { getEnvironmentSelector } from '../../Redux/user/selectors';
import { getCameraStateByIdAction } from '../../Redux/devices/actions';

const StartRecordingModal = ({
  modalOpened,
  closeModal,
  cameraSystemId,
  locale,
  handleDisable,
  defaultValue
}) => {
  const dispatch = useDispatch();
  const deviceInfo = useSelector(cameraStateByIdSelector);
  const eventDataSelector = useSelector(getEventDataSelector);
  const [value, setValue] = useState(defaultValue);
  const environmentList = useSelector(getEnvironmentSelector);

  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventDataSelector]);

  useEffect(() => {
    dispatch(filterInProgressListByCameraIdAction(cameraSystemId));
    dispatch(filterScheduleListByCameraIdAction(cameraSystemId));
  }, [eventDataSelector]);

  const commonRecordList = deviceInfo?.cameraSystemId
    ? [...deviceInfo.scheduleList, ...deviceInfo.inProgressList]
    : [];

  const defaultErrors = {
    duration: '',
    homeTeamId: '',
    awayTeamId: '',
    matchDay: '',
    competitionName: ''
  };
  const [validErrors, setValidErrors] = useState(defaultErrors);

  const validateValue = useCallback(
    field => {
      const validRes = validateStartRecording(field, value, validErrors, commonRecordList, locale);
      setValidErrors(() => ({ ...validRes.startRecordErrors }));
      return validRes.validation;
    },
    [value, validateStartRecording]
  );

  useEffect(() => {}, [validErrors.startRecordErrors]);

  const onInputChange = (input, val) => {
    if (input === 'duration' && !isNumber(val)) {
      return;
    }
    setValue(prevVal => ({ ...prevVal, [input]: val }));
  };

  useEffect(() => {
    setValue(val => ({
      ...val,
      endRecordingTime: moment()
        .add(value.duration, 'minute')
        .valueOf()
    }));
  }, [value.duration]);

  const clearError = useCallback(
    field => {
      field === 'all'
        ? setValidErrors({ ...defaultErrors })
        : setValidErrors(val => ({ ...val, [field]: '' }));
    },
    [validErrors]
  );

  useEffect(() => {
    if (modalOpened) {
      setValue(defaultValue);
      clearError('all');
      setValue(val => ({ ...val, duration: 95 }));
    }
  }, [modalOpened]);

  useEffect(() => {
    if (value.type === 'TRAINING') {
      setValue(prevVal => ({
        ...prevVal,
        homeTeamId: '',
        awayTeamId: ''
      }));
    }
  }, [value.type]);

  const applyButton = {
    label: lang(locale)['start recording'],
    onClick: () => {
      if (validateValue('all')) {
        dispatch(
          startRecordingAction({
            cameraSystemId,
            endRecordingTime: moment().valueOf() + value.duration * 60000,
            type: value.type,
            homeTeamId: value.homeTeamId?.trim() || '',
            awayTeamId: value.awayTeamId?.trim() || '',
            competitionName: value.competitionName?.trim() || '',
            name: value.name?.trim()
              ? value.name?.trim()
              : moment(defaultValue.startRecordingTime).format('DD/MM/YYYY HH:mm:ss'),
            matchDay: value.matchDay?.trim() || '',
            client: value.client || ''
          })
        );
        handleDisable();
      }
      validateValue('all') && closeModal();
    }
  };

  return (
    <StyledModalContainer
      isOpened={modalOpened}
      headerText={lang(locale)['new recording']}
      closeModal={closeModal}
      footerButton={applyButton}
      width="80%"
      locale={locale}
    >
      <TextInput
        label={lang(locale)['recording name']}
        value={value.name}
        onChange={e => onInputChange('name', e.target.value)}
        onFocus={() => clearError('name')}
        onBlur={() => validateValue('name')}
        errorText={validErrors.name}
        type={validErrors.name ? 'error' : 'default'}
        width="100%"
        required
      />
      <SelectInput
        label={lang(locale).environment}
        options={environmentList?.map(client => client.name)}
        width="100%"
        chosenOpt={environmentList?.find(client => client.id === value.client)?.name}
        onChange={e =>
          onInputChange('client', environmentList?.find(client => client.name === e)?.id)
        }
      />
      <RadioButton
        options={[lang(locale).training, lang(locale).game]}
        defaultOption={value.type === 'TRAINING' ? lang(locale).training : lang(locale).game}
        activeOption={value.type === 'TRAINING' ? lang(locale).training : lang(locale).game}
        onChange={e => onInputChange('type', e === lang(locale).training ? 'TRAINING' : 'GAME')}
        height="30px"
      />
      <TextInput
        label={lang(locale).duration}
        value={value.duration}
        onChange={e => onInputChange('duration', e.target.value)}
        onFocus={() => clearError('duration')}
        onBlur={() => validateValue('duration')}
        errorText={validErrors.duration}
        type={validErrors.duration ? 'error' : 'default'}
        width="48%"
        required
      />
      <Panel visible={value.type === 'GAME'}>
        <TextInput
          label={lang(locale)['competition name']}
          value={value.competitionName || ''}
          onChange={e => onInputChange('competitionName', e.target.value)}
          onFocus={() => clearError('competitionName')}
          onBlur={() => validateValue('competitionName')}
          errorText={validErrors.competitionName}
          type={validErrors.competitionName ? 'error' : 'default'}
          width="100%"
          required
        />
        <TextInput
          label={lang(locale).matchDay}
          value={value.matchDay || ''}
          onChange={e => onInputChange('matchDay', e.target.value)}
          onFocus={() => clearError('matchDay')}
          onBlur={() => validateValue('matchDay')}
          errorText={validErrors.matchDay}
          type={validErrors.matchDay ? 'error' : 'default'}
          width="100%"
          required
        />
      </Panel>
      <PanelWrapper>
        <WidePanel visible={value.type === 'GAME'}>
          <div>
            <TeamHeader>{lang(locale)['home team'].toUpperCase()}</TeamHeader>
            <TextInput
              label={lang(locale).name}
              value={value.homeTeamId}
              onChange={e => onInputChange('homeTeamId', e.target.value)}
              onFocus={() => clearError('homeTeamId')}
              onBlur={() => validateValue('homeTeamId')}
              errorText={validErrors.homeTeamId}
              type={validErrors.homeTeamId ? 'error' : 'default'}
              required
            />
          </div>

          <div>
            <TeamHeader>{lang(locale)['away team'].toUpperCase()}</TeamHeader>
            <TextInput
              label={lang(locale).name}
              value={value.awayTeamId}
              onChange={e => onInputChange('awayTeamId', e.target.value)}
              onFocus={() => clearError('awayTeamId')}
              onBlur={() => validateValue('awayTeamId')}
              errorText={validErrors.awayTeamId}
              type={validErrors.awayTeamId ? 'error' : 'default'}
              required
              visible
            />
          </div>
        </WidePanel>
      </PanelWrapper>
    </StyledModalContainer>
  );
};

export default StartRecordingModal;

const StyledModalContainer = styled(ModalContainer)(() => ({
  width: '60%',
  minWidth: '240px'
}));

const TeamHeader = styled.div(() => ({
  fontWeight: 500,
  fontSize: '11px',
  letterSpacing: '1.6px',
  color: colors.scheduledGray,
  margin: '15px 0 10px 0'
}));

const PanelWrapper = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%'
}));

const WidePanel = styled.div(props => ({
  display: props.visible ? 'flex' : 'none',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  '& > div': {
    width: '48%',
    minWidth: '220px',
    '@media(max-width: 820px)': {
      width: '100%',
      marginBottom: '0'
    },
    '& > div': {
      width: '100%',
      minWidth: '220px'
    }
  }
}));
const Panel = styled.div(props => ({
  display: props.visible ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',

  '& > div': {
    width: '100%',
    minWidth: '220px',
    position: 'relative',

    '@media(max-width: 620px)': {
      width: '100%',
      marginBottom: '25px',

      '&:last-child': {
        marginBottom: 0
      }
    }
  }
}));

StartRecordingModal.propTypes = {
  modalOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  cameraSystemId: PropTypes.string,
  locale: PropTypes.string,
  handleDisable: PropTypes.func,
  defaultValue: PropTypes.shape({
    cameraSystemId: PropTypes.string,
    cameraName: PropTypes.string,
    type: PropTypes.string,
    startRecordingTime: PropTypes.number,
    endRecordingTime: PropTypes.number,
    homeTeamId: PropTypes.string,
    awayTeamId: PropTypes.string,
    duration: PropTypes.number,
    name: PropTypes.string,
    environment: PropTypes.string
  })
};

StartRecordingModal.defaultProps = {
  modalOpened: false,
  closeModal: () => {},
  cameraSystemId: '',
  locale: 'en-US',
  handleDisable: () => {},
  defaultValue: {
    cameraSystemId: '',
    cameraName: '',
    type: 'TRAINING',
    startRecordingTime: 0,
    endRecordingTime: 0,
    homeTeamId: '',
    awayTeamId: '',
    duration: 95,
    name: '',
    environment: ''
  }
};
