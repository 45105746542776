import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors } from '../../Theme/theme';
import { ReactComponent as RadioButtonOuter } from '../../assets/radio-button-outer.svg';

const RadioButton = ({ options, onChange, activeOption, disabled, height }) => {
  const [active, setActive] = useState(activeOption);
  const handleClick = option => {
    onChange(option);
  };
  useEffect(() => {
    setActive(activeOption);
  }, [activeOption]);

  return (
    <OptionContainer height={height}>
      {options.map(item => (
        <Option key={`${item}_option`}>
          <RadioOuter
            active={active === item ? 1 : 0}
            onClick={() => !disabled && handleClick(item)}
            disabled={disabled}
          />
          <OptionText disabled={disabled}>{item}</OptionText>
        </Option>
      ))}
    </OptionContainer>
  );
};

export default RadioButton;

const OptionContainer = styled.div(props => ({
  height: props.height,
  display: 'flex',
  alignItems: 'center',
  margin: '15px 0'
}));

const Option = styled.div(() => ({
  display: 'flex'
}));

const OptionText = styled.div(() => ({
  cursor: 'default',
  marginRight: '30px',
  color: colors.defaultInputText
}));

const colorList = {
  fill: ['none', 'none', colors.disabledBtn, colors.activePurpleBtn],
  stroke: [
    colors.disabledInputText,
    colors.disabledInputText,
    colors.disabledBtn,
    colors.activePurpleBtn
  ]
};

// if active and not disabled: 3
// if active and disabled: 2
// if not active and not disabled: 1
// if not active and disabled: 0

const radioColor = (active, disabled) => {
  let res = 1;
  if (active) {
    res += 2;
  }
  if (disabled) {
    res -= 1;
  }
  return res;
};

const RadioOuter = styled(RadioButtonOuter)(props => ({
  stroke: colorList.stroke[radioColor(props.active, props.disabled)],
  fill: colorList.fill[radioColor(props.active, props.disabled)],
  cursor: props.disabled ? 'default' : 'pointer',
  marginRight: '5px'
}));

RadioButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  activeOption: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string
};

RadioButton.defaultProps = {
  options: [],
  onChange: () => {},
  activeOption: '',
  disabled: false,
  height: '25px'
};
