import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { colors, zIndexes } from '../../Theme/theme';
import { ReactComponent as LeftArrow } from '../../assets/big-panorama-left-button.svg';
import { ReactComponent as RightArrow } from '../../assets/big-panorama-right-button.svg';
import { ReactComponent as CalendarPic } from '../../assets/calendar.svg';

const Calendar = ({
  width,
  label,
  className,
  onChange,
  disabled,
  calendarValue,
  onFocus,
  onBlur,
  required
}) => {
  const [calendarOpened, setCalendarOpened] = useState(false);
  // current chosen components of a date
  const [currentDay, setCurrentDay] = useState(moment(calendarValue).date());
  const [currentMonth, setCurrentMonth] = useState(moment(calendarValue).month() + 1);
  const [currentYear, setCurrentYear] = useState(moment(calendarValue).year());

  const [weekFromSunday, setWeekFromSunday] = useState(false);
  const screenHeight = window.innerHeight;
  const weeks = [1, 2, 3, 4, 5, 6, 7].map(item => moment().day(weekFromSunday ? item - 1 : item));

  const openCalendar = useCallback(() => {
    if (!disabled) {
      setCalendarOpened(true);
    }
  }, []);
  const closeCalendar = useCallback(() => {
    setCalendarOpened(false);
  }, []);

  const toggleCalendar = useCallback(() => {
    !disabled && setCalendarOpened(() => !calendarOpened);
  }, []);

  useEffect(() => {
    if (calendarOpened) {
      document.addEventListener('click', e => closeCalendar(e), { once: 'true' });
      onFocus();
    } else {
      onBlur();
    }
  }, [calendarOpened, closeCalendar]);

  // finds node of modal wrapper to correct scroll down
  const findNodeToScroll = useCallback(el => {
    const res = el;
    if (el.classList.value.split(' ').indexOf('modal-wrapper') !== -1) {
      return res;
    }
    return findNodeToScroll(el.parentNode);
  }, []);

  // Scroll down, when calendar is not fully visible
  const outOfScreenHandler = useCallback(el => {
    const diff = screenHeight - el.getBoundingClientRect().bottom;
    if (diff < 0) {
      findNodeToScroll(el).scrollBy({
        top: -diff + 10,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, []);

  // returns an array of Moment objects of each day for month/year from the props
  const datesCreator = useCallback((monthForCreator, yearForCreator) => {
    const month = [];
    for (let i = 1; i <= 31; i += 1) {
      month.push(
        moment({
          year: yearForCreator,
          month: monthForCreator - 1,
          day: i
        })
      );
    }
    const validMonth = month.filter(item => item.isValid());
    return validMonth;
  }, []);

  // Creates days before/after current month to fill the week row;
  // Returns an array of Moment objects
  const outDaysCreator = useCallback((beforeOrAfter, currMonth, currYear, weekStartsFromSunday) => {
    const result = [];
    const currentMonthArray = datesCreator(currMonth, currYear);
    // Moment objects with first and last day of current month
    const firstDayOfMonth = currentMonthArray[0];
    const lastDayOfMonth = currentMonthArray[currentMonthArray.length - 1];
    const firstWeekDay = weekStartsFromSunday
      ? 6 - (7 - firstDayOfMonth.isoWeekday()) // sets weekday if sunday is 0 day of 6
      : firstDayOfMonth.isoWeekday() - 1; // sets weekday if monday is 0 day of 6
    const lastWeekDay = weekStartsFromSunday
      ? 6 - (7 - lastDayOfMonth.isoWeekday())
      : lastDayOfMonth.isoWeekday() - 1;

    const prevMonth = firstDayOfMonth.clone().subtract(1, 'day'); // The last day of previous month
    const nextMonth = firstDayOfMonth.clone().add(1, 'months'); // The first day of next month

    // Creation of an array of days before current month to complete the week
    if (beforeOrAfter === 'before') {
      // Counts, how many days we should add before to fill the week
      for (let i = 0; i < firstWeekDay; i += 1) {
        result.push(prevMonth.clone().subtract(i, 'days'));
      }
      result.sort((a, b) => a - b);
    }
    // Creation of an array of days after current month to complete the week
    if (beforeOrAfter === 'after') {
      // Counts, how many days we should add after to fill the week
      for (let i = 0; i < 6 - lastWeekDay; i += 1) {
        result.push(nextMonth.clone().add(i, 'days'));
      }
    }
    return result;
  }, []);

  // type: add / subtract / set
  // count: amount of moth to add/subtract or month to set (from 1 to 12)
  const monthNavigate = useCallback((type, count, currMonth, currYear) => {
    const prevMonth = currMonth;
    let resultData = {
      month: currMonth,
      year: currYear
    };
    // mixin of years, that we have to add/subtract
    const mixin = count <= 12 ? 1 : Math.floor(count / 12);

    switch (type) {
      case 'add': {
        if (prevMonth + count > 12) {
          resultData = {
            month: currMonth + count - 12 * mixin,
            year: currYear + mixin
          };
        } else {
          resultData = {
            month: currMonth + count,
            year: currYear
          };
        }
        break;
      }
      case 'subtract': {
        if (prevMonth - count <= 0) {
          resultData = {
            month: 12 * mixin - (count - currMonth),
            year: currYear - mixin
          };
        } else {
          resultData = {
            month: currMonth - count,
            year: currYear
          };
        }
        break;
      }
      case 'set': {
        if (count >= 1 && count <= 12) {
          resultData = {
            month: count,
            year: currYear
          };
        } else
          resultData = {
            month: moment().month() + 1,
            year: currYear
          };
        break;
      }
      default:
    }
    return resultData;
  }, []);

  const onNavPrevClick = () => {
    if (moment(`${currentMonth}/${currentYear}`, 'MM/YYYY') < moment()) {
      return;
    }
    setCurrentMonth(monthNavigate('subtract', 1, currentMonth, currentYear).month);
    setCurrentYear(monthNavigate('subtract', 1, currentMonth, currentYear).year);
  };
  const onNavNextClick = () => {
    setCurrentMonth(monthNavigate('add', 1, currentMonth, currentYear).month);
    setCurrentYear(monthNavigate('add', 1, currentMonth, currentYear).year);
  };

  // getting data out
  useEffect(() => {
    const fullDate = `${currentDay}/${currentMonth}/${currentYear}`;
    const month = `${currentMonth}/${currentYear}`;
    if (moment(fullDate, 'DD/MM/YYYY').endOf('day') >= moment()) {
      onChange(fullDate);
      return;
    }
    if (moment(month, 'MM YYYY').daysInMonth() < currentDay) {
      onChange(`${moment(month, 'MM/YYYY').daysInMonth()}/${month}`);
    } else {
      onChange(moment().format('DD/MM/YYYY'));
    }
  }, [currentDay, currentMonth, currentYear]);

  const valueShow = useCallback((currDay, currMonth, currYear) => {
    const dayFormatter = currDay.toString().length === 1 ? '0' : '';
    const monthFormatter = currMonth.toString().length === 1 ? '0' : '';
    return {
      valueInTextInput: `${dayFormatter}${currDay}/${monthFormatter}${currMonth}/${currYear}`,
      navigatorMonthAndYear: moment(datesCreator(currMonth, currYear)[0]).format('MMMM YYYY')
    };
  }, []);

  // const textInputKeyPressHandler = e => e.code === 'Enter' && e.target.blur();

  const onCurrentDayClickHandler = value => {
    value >= moment().startOf('day') && setCurrentDay(value.date());
  };

  return (
    <Wrapper>
      <ModalMiddleware opened={calendarOpened} onClick={toggleCalendar} />
      <InputWrapper width={width} className={className} onClick={toggleCalendar}>
        <Header onClick={e => e.stopPropagation()}>
          {label}
          {required && <RequiredStar>*</RequiredStar>}
        </Header>

        <CalendarTextInput
          inputMode="text"
          calendarOpened={calendarOpened}
          className="calendar-body"
          disabled={disabled}
        >
          <DateWrapper>
            <div>
              <CalendarTextInputSpan
                value={valueShow(currentDay, currentMonth, currentYear).valueInTextInput}
                onClick={e => e.stopPropagation()}
                onFocus={openCalendar}
                // onBlur={() => updateCalendar()}
                // onKeyPress={e => textInputKeyPressHandler(e)}
                // onChange={(e) => textInputHandler(e, item)}
                readOnly
                disabled={disabled}
                size=""
              />
            </div>
          </DateWrapper>

          <StyledCalendarPic
            onClick={toggleCalendar}
            disabled={disabled}
            fill={colors.scheduledGray}
          />
        </CalendarTextInput>
      </InputWrapper>

      <CalendarWrapper
        active={calendarOpened}
        className="calendar-body"
        onClick={e => e.stopPropagation()}
        ref={el => {
          if (!el) return;
          outOfScreenHandler(el);
        }}
      >
        <CalendarNavigator>
          {valueShow(currentDay, currentMonth, currentYear).navigatorMonthAndYear}
          <div>
            <CalendarNavItemPrev
              onClick={onNavPrevClick}
              disabled={moment(`${currentMonth}/${currentYear}`, 'MM/YYYY') <= moment()}
            />
            <CalendarNavItemNext onClick={onNavNextClick} />
          </div>
        </CalendarNavigator>

        <WeeksPanel>
          {weeks.map(item => (
            <WeekDay key={`${item}week`}>{item.format('dd')}</WeekDay>
          ))}
        </WeeksPanel>

        <CalendarBody>
          {/* days before current month */}
          {outDaysCreator('before', currentMonth, currentYear, weekFromSunday).map(item => (
            <CalendarDayWrapper key={`before_${moment(item).format('X')}`}>
              <CalendarDayOut
                key={`before_out_${moment(item).format('X')}`}
                onClick={onNavPrevClick}
                disabled={item < moment().startOf('day')}
              >
                {item.date()}
              </CalendarDayOut>
            </CalendarDayWrapper>
          ))}

          {/* days of current month */}
          {datesCreator(currentMonth, currentYear).map(item => (
            <CalendarDayWrapper key={`current_${item}`}>
              <CalendarDayCurrent
                active={item.date() === currentDay}
                onClick={() => onCurrentDayClickHandler(item)}
                disabled={item < moment().startOf('day')}
                key={`day_current_${item}`}
              >
                {item.date()}
              </CalendarDayCurrent>
            </CalendarDayWrapper>
          ))}

          {/* days after current month */}
          {outDaysCreator('after', currentMonth, currentYear, weekFromSunday).map(item => (
            <CalendarDayWrapper key={`after_${moment(item).format('X')}`}>
              <CalendarDayOut
                key={`after_out_${moment(item).format('X')}`}
                onClick={onNavNextClick}
              >
                {item.date()}
              </CalendarDayOut>
            </CalendarDayWrapper>
          ))}
        </CalendarBody>
      </CalendarWrapper>
    </Wrapper>
  );
};

export default Calendar;

const Wrapper = styled.div(() => ({
  position: 'relative'
}));

const ModalMiddleware = styled.div(props => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: props.opened ? 'block' : 'none',
  zIndex: zIndexes.inputBackground
}));

const CalendarTextInput = styled.div(props => ({
  border: 'none',
  boxShadow: `0px 0px 0px 1px ${
    props.calendarOpened ? colors.defaultPurpleBtn : colors.defaultInputBorder
  }`,
  width: '100%',
  height: '40px',
  backgroundColor: props.disabled ? colors.disabledInputBackground : colors.white,
  color: props.disabled ? colors.disabledInputText : colors.defaultInputText,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: '10px',
  zIndex: zIndexes.textInputsLayer,
  cursor: props.disabled ? 'default' : 'pointer',
  marginBottom: '20px'
}));

const InputWrapper = styled.div(props => ({
  width: props.width
}));

const RequiredStar = styled.span(() => ({
  color: colors.defaultInputText,
  marginLeft: '3px'
}));

const Header = styled.div(() => ({
  fontWeight: 500,
  fontSize: '14px',
  padding: ' 0 5px 8px 0'
}));

const DateWrapper = styled.div(() => ({
  width: '100px',
  display: 'flex'
}));
const CalendarTextInputSpan = styled.input(props => ({
  outline: 'none',
  border: 'none',
  width: `${props.value.toString().length * 7.8}px`,
  fontWeight: 400,
  fontSize: '14px',
  backgroundColor: props.disabled ? colors.disabledInputBackground : colors.white,
  color: props.disabled ? colors.disabledInputText : colors.defaultInputText,
  cursor: props.disabled ? 'default' : 'pointer',
  caretColor: 'transparent',
  '&:first-child': {
    textAlign: 'right'
  },
  '&:last-child': {
    textAlign: 'left',
    width: `${props.value.toString().length * 8}px`
  }
}));

const StyledCalendarPic = styled(CalendarPic)(props => ({
  position: 'absolute',
  right: '10px',
  cursor: props.disabled ? 'default' : 'pointer'
}));

const CalendarWrapper = styled.div(props => ({
  boxShadow: '2px 4px 8px rgba(63,48,186, 0.2)',
  border: `1px solid ${colors.defaultPurpleBtn}`,
  backgroundColor: colors.white,
  width: '350px',
  display: props.active ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  padding: '20px',
  marginTop: '5px',
  position: 'absolute',
  zIndex: zIndexes.popUpInput,
  '@media(max-width: 500px)': {
    width: '260px',
    padding: '10px'
  },
  '@media(max-width: 375px)': {
    width: '230px'
  }
}));

const CalendarNavigator = styled.div(() => ({
  display: 'flex',
  width: '245px',
  padding: '5px',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 500,
  fontSize: '16px',
  '@media(max-width: 375px)': {
    width: '200px'
  }
}));

const navStyle = props => ({
  margin: '5px',
  cursor: props.disabled ? 'default' : 'pointer',
  fill: props.disabled ? colors.disabledBtn : colors.defaultBlackBtn,
  width: '10px',
  height: '10px',
  marginLeft: '15px',
  '&:hover': {
    fill: props.disabled ? colors.disabledBtn : colors.hoverPurpleBtn
  }
});
const CalendarNavItemPrev = styled(LeftArrow)(() => navStyle);
const CalendarNavItemNext = styled(RightArrow)(() => navStyle);

const WeeksPanel = styled.div(() => ({
  width: '245px',
  display: 'flex',
  fontWeight: 500,
  margin: '20px 0',
  fontSize: '14px',
  '@media(max-width: 375px)': {
    width: '200px'
  }
}));
const WeekDay = styled.div(() => ({
  width: `${100 / 7}%`,
  textAlign: 'center'
}));
const CalendarBody = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '245px',
  '@media(max-width: 375px)': {
    width: '200px'
  }
}));
const CalendarDayWrapper = styled.div(() => ({
  width: `${100 / 7}%`,
  display: 'flex',
  height: '35px',
  alignItems: 'center',
  justifyContent: 'center'
}));

const CalendarDayCurrent = styled.div(props => ({
  width: '34px',
  height: '34px',
  lineHeight: '34px',
  fontSize: '14px',
  borderRadius: '50%',
  textAlign: 'center',
  // eslint-disable-next-line no-nested-ternary
  color: props.active
    ? colors.white
    : props.disabled
    ? colors.disabledPanelBtn
    : colors.scheduledGray,
  boxShadow: props.active ? `0 0 0 0 1px ${colors.defaultPurpleBtn}` : 'none',
  backgroundColor: props.active ? colors.defaultPurpleBtn : colors.white,
  cursor: props.disabled ? 'default' : 'pointer',
  '@media(max-width: 375px)': {
    width: '25px',
    height: '25px',
    lineHeight: '25px'
  }
}));
const CalendarDayOut = styled.div(props => ({
  width: '35px',
  height: '35px',
  lineHeight: '35px',
  textAlign: 'center',
  color: props.disabled ? colors.disabledPanelBtn : colors.calendarDayOut,
  cursor: props.disabled ? 'default' : 'pointer',
  fontSize: '14px'
}));

Calendar.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  calendarValue: PropTypes.number,
  required: PropTypes.bool
};

Calendar.defaultProps = {
  width: '150px',
  label: 'Date',
  className: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  disabled: false,
  calendarValue: 0,
  required: false
};
