import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { PropTypes } from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { colors, parameters, zIndexes } from '../../Theme/theme';
import { ReactComponent as KogniaLogo } from '../../assets/kognia-logo.svg';
import { ReactComponent as UserPic } from '../../assets/user.svg';
import { ReactComponent as DownArrow } from '../../assets/down-arrow.svg';
import { ReactComponent as HousePic } from '../../assets/house.svg';
import { ReactComponent as CameraPic } from '../../assets/camera-small.svg';
import { ReactComponent as RecordingPic } from '../../assets/camera-with-recording-dot.svg';
import { ReactComponent as ScheduledPic } from '../../assets/calendar.svg';
import { ReactComponent as VideoActivityPic } from '../../assets/upload-to-cloud.svg';
import { lang } from '../../Langs/langSettings';
import { cameraStateByIdSelector } from '../../Redux/devices/selectors';
import UserPanel from './UserPanel';
import { filterArray, findCameraId, findRecordId } from '../../Services/api/common';
import {
  getInProgressListSelector,
  getScheduledListSelector,
  getToBeUploadedListSelector
} from '../../Redux/recordings/selectors';
import { getCameraStateByIdAction } from '../../Redux/devices/actions';

const Header = ({ locale, updateLang }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.slice(1).split('/');

  const { user } = useAuth0();
  // if user logins with login-password system, his sub starts with auth0|
  // if user logins via google, his sub starts with google-oauth
  const createUserName = () => (user.sub.startsWith('auth0|') ? user.nickname : user.name);

  const [openedPane, setOpenedPane] = useState(false);
  const cameraSystemId = findCameraId(location);
  const deviceInfo = useSelector(cameraStateByIdSelector);
  const recordId = findRecordId(location);
  const cameraName = deviceInfo?.cameraName || 'Unknown Camera';

  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, []);

  const toBeUploadedList = useSelector(getToBeUploadedListSelector);
  const scheduledList = useSelector(getScheduledListSelector);
  const inProgressList = useSelector(getInProgressListSelector);

  // Sets recording id/name, if it's in the schedule list
  const scheduleRecord = scheduledList[0]
    ? filterArray(scheduledList, 'recordId', recordId)[0]
    : undefined;
  const scheduleId = scheduleRecord ? recordId : undefined;
  const scheduleName = scheduleRecord ? scheduleRecord.name : undefined;

  // Sets recording id/name, if it's in the toBeUploaded list
  const toBeUploadedRecord = toBeUploadedList[0]
    ? filterArray(toBeUploadedList, 'recordId', recordId)[0]
    : undefined;
  const toBeUploadedId = toBeUploadedRecord ? recordId : undefined;
  const toBeUploadedName = toBeUploadedRecord
    ? toBeUploadedRecord?.name ||
      moment(toBeUploadedRecord.startRecordingTime).format('DD/MM/YYYY HH:mm:ss')
    : undefined;
  const inProgressRecord = inProgressList[0] ? inProgressList[0] : undefined;
  const inProgressId = inProgressRecord?.recordId || undefined;
  const inProgressName = inProgressRecord?.name || undefined;

  const generateRoute = {
    'device-list': {
      name: `${lang(locale).home} `,
      nav: '/device-list',
      pic: <StyledHousePic />,
      type: 'device-list'
    },
    [`${cameraSystemId}`]: {
      name: ` / ${cameraName} `,
      nav: `/device-list/${cameraSystemId}`,
      pic: <StyledCameraPic />,
      type: cameraSystemId
    },
    'scheduled-recordings': {
      name: ` / ${lang(locale)['scheduled recordings']} `,
      nav: `/device-list/${cameraSystemId}/scheduled-recordings`,
      pic: <StyledScheduledPic />,
      type: 'scheduled-recordings'
    },
    recording: {
      name: ` / ${lang(locale).recording} `,
      nav: `/device-list/${cameraSystemId}/recording`,
      pic: <StyledRecordingPic />,
      type: 'recording'
    },
    'video-library': {
      name: ` / ${lang(locale)['video library']} `,
      nav: `/device-list/${cameraSystemId}/video-library`,
      pic: <StyledVideoActivityPic />,
      type: 'video-library'
    },

    scheduled: {
      name: '',
      nav: '',
      pic: null,
      type: 'scheduled'
    },

    watch: {
      name: '',
      nav: '',
      pic: null,
      type: 'watch'
    },

    [`${scheduleId}`]: {
      name: ` / ${scheduleName}`,
      nav: `/device-list/${cameraSystemId}/scheduled/${scheduleId}`,
      pic: <StyledCameraPic />,
      type: scheduleId
    },
    [`${toBeUploadedId}`]: {
      name: ` / ${toBeUploadedName}`,
      nav: `/device-list/${cameraSystemId}/watch/${toBeUploadedId}`,
      pic: <StyledCameraPic />,
      type: toBeUploadedId
    },
    [`${inProgressId}`]: {
      name: ` / ${inProgressName}`,
      nav: `/device-list/${cameraSystemId}/watch/${inProgressId}`,
      pic: <StyledRecordingPic />,
      type: inProgressId
    }
  };
  const route = path.map(item => generateRoute[item]);

  // When user panel is opened, click target is checked.
  // If it has class 'user-panel', panel will not be closed
  const checkId = useCallback((target, id) => {
    if (!target) {
      return true;
    }
    if (target?.id === 'root') {
      return true;
    }
    if (target?.id === id) {
      return false;
    }
    return checkId(target?.parentNode, id);
  }, []);

  const openPane = useCallback(() => setOpenedPane(true));
  const closePane = useCallback(
    event => checkId(event.target, 'user-panel') && setOpenedPane(false),
    [checkId, setOpenedPane]
  );
  const forcedClosePane = useCallback(() => setOpenedPane(false), []);

  useEffect(() => {
    if (openedPane) {
      document.addEventListener('click', closePane);
    } else {
      document.removeEventListener('click', closePane);
    }
    return () => document.removeEventListener('click', closePane);
  }, [closePane, openedPane]);

  return (
    <HeaderWrapper>
      <HeaderInner>
        {route.length <= 1 ? (
          <KogniaLogoImg onClick={() => navigate('/device-list')} />
        ) : (
          <NavPane>
            {route.map(item => (
              <div key={`${item?.type}`}>
                <NavItem
                  key={`${item?.type}_text`}
                  onClick={() => navigate(item?.nav)}
                  // if the last, should has not bold font
                  isLast={path[path.length - 1] === item?.type}
                >
                  <span>{item?.name?.toUpperCase()}</span>
                </NavItem>

                <NavPic key={`${item?.type}_pic`} onClick={() => navigate(item?.nav)}>
                  <span>
                    {/* plug to exclude doubled slash */}
                    {item?.type !== 'device-list' &&
                      item?.type !== 'scheduled' &&
                      item?.type !== 'watch' &&
                      '/'}
                  </span>
                  {item?.pic}
                </NavPic>
              </div>
            ))}
          </NavPane>
        )}
        <PanelWrapper onClick={openPane}>
          <UserPic />
          <UserName>
            {user ? createUserName().toUpperCase() : lang(locale).user.toUpperCase()}
          </UserName>
          <Arrow>
            <StyledDownArrow opened={openedPane ? 1 : 0} />
          </Arrow>
          <UserPanel
            openedPanel={openedPane}
            updateLang={updateLang}
            locale={locale}
            closePanel={forcedClosePane}
            userName={user ? createUserName() : lang(locale).user}
          />
        </PanelWrapper>
      </HeaderInner>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div(() => ({
  position: 'fixed',
  top: 0,
  width: '100vw',
  minWidth: '240px',
  height: `${parameters.headerHeight}px`,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: colors.white,
  zIndex: zIndexes.headerWrapper,
  '@media (max-width: 535px)': {
    height: '50px'
  },
  '@media (max-height: 420px)': {
    height: '35px'
  }
}));

const HeaderInner = styled.div(() => ({
  width: '80vw',
  height: '100%',
  maxWidth: '1600px',
  minWidth: '240px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 420px)': {
    width: '96vw'
  }
}));

const KogniaLogoImg = styled(KogniaLogo)(() => ({
  width: '170px',
  height: '50px',
  cursor: 'pointer',
  marginRight: '5px',
  '@media (max-width: 535px)': {
    width: '130px',
    height: '39px'
  }
}));

const UserName = styled.div(() => ({
  marginLeft: '10px',
  fontSize: '11px',
  letterSpacing: '1.6px',
  fontWeight: 500,
  '@media (max-width: 535px)': {
    display: 'none'
  }
}));

const Arrow = styled.div(() => ({
  cursor: 'pointer',
  paddingLeft: '10px',
  display: 'flex',
  position: 'relative'
}));

const StyledDownArrow = styled(DownArrow)(props => ({
  transform: props.opened && 'rotateX(180deg)',
  fill: colors.defaultBlackBtn
}));

const PanelWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}));

const NavPane = styled.div(() => ({
  width: '80%',
  maxWidth: '80%',
  display: 'flex',
  cursor: 'pointer'
}));

const NavItem = styled.div(props => ({
  marginRight: '5px',
  fontWeight: 500,
  fontSize: '11px',
  color: colors.defaultInputText,
  letterSpacing: 1.6,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '@media(max-width: 700px)': {
    display: 'none'
  },
  '& > span': {
    color: props.isLast && colors.panelsText,
    fontWeight: props.isLast && 400,
    letterSpacing: props.isLast && 1.65,
    minWidth: 'fit-content'
  },
  '&:first-child': {
    color: colors.panelsText,
    letterSpacing: 1.65,
    minWidth: 'fit-content'
  },
  '&:last-child': {
    color: colors.panelsText,
    fontWeight: 400,
    letterSpacing: 1.65,
    minWidth: 'fit-content'
  }
}));

const NavPic = styled.div(() => ({
  display: 'flex',
  color: colors.panelsText,

  '@media(min-width: 700px)': {
    display: 'none'
  },
  '& > span': {
    margin: '0, 15px'
  }
}));

const picsParameters = () => ({
  fill: colors.panelsText,
  width: '20px',
  height: '20px',
  margin: '0 10px'
});

const StyledHousePic = styled(HousePic)({
  ...picsParameters(),
  margin: '2px 10px 0 0'
});
const StyledCameraPic = styled(CameraPic)(picsParameters);
const StyledRecordingPic = styled(RecordingPic)(picsParameters);
const StyledScheduledPic = styled(ScheduledPic)({
  fill: colors.panelsText,
  width: '15px',
  height: '15px',
  margin: '2px 10px 0 10px'
});
const StyledVideoActivityPic = styled(VideoActivityPic)({
  display: 'flex',
  fill: colors.panelsText,
  width: '17px',
  height: '17px',
  margin: '1px 10px 0 10px'
});

Header.propTypes = {
  locale: PropTypes.string,
  updateLang: PropTypes.func
};
Header.defaultProps = {
  locale: 'en-US',
  updateLang: () => {}
};
