import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { lang } from '../../Langs/langSettings';
import { validateScheduled } from './validation';
import TextInput from '../../Components/Inputs/TextInput';
import RadioButton from '../../Components/Inputs/RadioButton';
import Calendar from '../../Components/Inputs/Calendar';
import ModalContainer from '../../Components/ModalContainer';
import { ReactComponent as ClockPic } from '../../assets/timer.svg';
import { getEventDataSelector } from '../../Redux/recordings/selectors';
import { createScheduleAction, editScheduleAction } from '../../Redux/recordings/actions';
import { isNumber } from '../../Services/validation/validationRules';
import TimeScrollPicker from '../../Components/Inputs/ScrollPickerInput/TimeScrollPicker';
import { zIndexes } from '../../Theme/theme';
import { getCameraStateByIdAction } from '../../Redux/devices/actions';
import { cameraStateByIdSelector } from '../../Redux/devices/selectors';
import SelectInput from '../../Components/Inputs/SelectInput';
import { getEnvironmentSelector } from '../../Redux/user/selectors';

const defaultErrors = {
  startRecordingTime: '',
  duration: '',
  endRecordingTime: '',
  homeTeamId: '',
  awayTeamId: '',
  name: '',
  competitionName: '',
  matchDay: ''
};

const ScheduledModal = ({
  modalOpened,
  closeModal,
  isNew, // schedule new recording or edit an existing one
  defaultValue,
  cameraSystemId,
  locale
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventDataSelector = useSelector(getEventDataSelector);
  const deviceInfo = useSelector(cameraStateByIdSelector);
  const [validErrors, setValidErrors] = useState({ ...defaultErrors });
  const [value, setValue] = useState({ ...defaultValue });
  const environmentList = useSelector(getEnvironmentSelector);
  const [times, setTimes] = useState({
    date: moment(value.startRecordingTime).format('DD/MM/YYYY'),
    time: isNew ? ':' : moment(value.startRecordingTime).format('HH:mm')
  });

  // console.log(value);
  // option to edit schedule recording should be disabled in 5 minutes before start
  const editDisable = useRef(
    !isNew &&
      defaultValue.startRecordingTime <=
        moment()
          .add(4, 'minute')
          .valueOf()
  );
  // update of camera state on sse message
  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventDataSelector]);

  const commonRecordList = deviceInfo?.cameraSystemId
    ? [...deviceInfo.inProgressList, ...deviceInfo.scheduleList]
    : [];

  // Edit disable in 5 min before record start
  useEffect(() => {
    editDisable.current =
      !isNew &&
      defaultValue.startRecordingTime <=
        moment()
          .add(4, 'minute')
          .valueOf();
  }, [modalOpened]);
  useEffect(() => {}, [editDisable.current]);

  // Set values startRecordingTime and endRecordingTime when date/time/duration changes
  useEffect(() => {
    setValue(val => ({
      ...val,
      startRecordingTime: moment(`${times.date} ${times.time}`, 'DD/MM/YYYY HH:mm').valueOf(),
      endRecordingTime: moment(`${times.date} ${times.time}`, 'DD/MM/YYYY HH:mm')
        .add(value.duration, 'minute')
        .valueOf()
    }));
  }, [value.duration, times]);

  // Change while input
  const onInputChange = useCallback(
    (input, val) => {
      if (input === 'date' || input === 'time') {
        val && setTimes(tm => ({ ...tm, [input]: val }));
        return;
      }
      if (input === 'duration' && !isNumber(val)) {
        return;
      }
      if (input === 'duration' && val?.toString()[0] === '0') {
        return;
      }
      setValue(vl => ({ ...vl, [input]: val }));
    },
    [setTimes, setValue]
  );

  // Validation start
  const validateValue = useCallback(
    field => {
      const validRes = validateScheduled(
        field,
        value,
        times,
        defaultValue,
        validErrors,
        defaultErrors,
        commonRecordList,
        locale,
        editDisable.current
      );
      setValidErrors({ ...validRes.schedValidErrors });
      return validRes.validation;
    },
    [value, times, setValidErrors, validateScheduled]
  );

  // Validation errors clear
  const clearError = useCallback(
    field => {
      field === 'all'
        ? setValidErrors({ ...defaultErrors })
        : setValidErrors(val => ({ ...val, [field]: '' }));
    },
    [setValidErrors, validErrors, defaultErrors]
  );

  // if recording.type is TRAINING, homeTeamId and awayTeamId fields should be empty and disabled
  useEffect(() => {
    if (value.type === 'TRAINING') {
      setValue(val => ({
        ...val,
        homeTeamId: '',
        awayTeamId: '',
        matchDay: '',
        competitionName: ''
      }));
      clearError('homeTeamId');
      clearError('awayTeamId');
    }
  }, [value.type]);

  // Handlers on modal open: clear error messages,
  // Set default date and time:
  // current ones if schedule is new, and predefined ones if it's an edition of existing schedule
  useEffect(() => {
    clearError('all');
    if (modalOpened) {
      setTimes(() => ({
        date: moment(defaultValue.startRecordingTime).format('DD/MM/YYYY'),
        time: isNew ? ':' : moment(defaultValue.startRecordingTime).format('HH:mm')
      }));
      setValue(() => ({
        ...defaultValue,
        duration: Math.floor(
          (defaultValue.endRecordingTime - defaultValue.startRecordingTime) / 60000
        )
      }));
    }
  }, [modalOpened]);

  // On modal close
  const onModalClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  // On apply click
  const applyButton = {
    label: isNew ? lang(locale).create : lang(locale).update,
    onClick: isNew
      ? () => {
          validateValue('all') &&
            dispatch(
              createScheduleAction({
                cameraSystemId: value.cameraSystemId,
                type: value.type,
                startRecordingTime: value.startRecordingTime,
                endRecordingTime: value.endRecordingTime,
                homeTeamId: value.homeTeamId?.trim() || '',
                awayTeamId: value.awayTeamId?.trim() || '',
                name: value.name?.trim(),
                client: value.client || '',
                matchDay: value.matchDay?.trim() || '',
                competitionName: value.competitionName?.trim() || ''
              })
            );
          validateValue('all') && closeModal();
          validateValue('all') && navigate(`/device-list/${cameraSystemId}/scheduled-recordings`);
        }
      : () => {
          validateValue('all') &&
            dispatch(
              editScheduleAction({
                recordId: value.recordId,
                type: value.type,
                startRecordingTime: value.startRecordingTime,
                endRecordingTime: value.endRecordingTime,
                homeTeamId: value.homeTeamId?.trim() || '',
                awayTeamId: value.awayTeamId?.trim() || '',
                name: value.name?.trim(),
                client: value.client || '',
                matchDay: value.matchDay?.trim() || '',
                competitionName: value.competitionName?.trim() || ''
              })
            );
          validateValue('all') && closeModal();
        }
  };

  return (
    <ModalContainer
      isOpened={modalOpened}
      headerText={
        isNew ? lang(locale)['schedule new recording'] : lang(locale)['edit scheduled recording']
      }
      subheader={
        editDisable.current
          ? lang(locale)['the schedule time is not to be changed 5 min till the start']
          : ''
      }
      closeModal={onModalClose}
      width="80%"
      footerButton={applyButton}
      locale={locale}
    >
      <StyledModalInner>
        <TextInput
          label={lang(locale).device}
          width="100%"
          value={value.cameraName}
          disabled
          readOnly
        />
        <TextInput
          label={lang(locale)['recording name']}
          width="100%"
          value={value.name}
          onChange={e => onInputChange('name', e.target.value)}
          onFocus={() => clearError('name')}
          onBlur={() => validateValue('name')}
          errorText={validErrors.name}
          type={validErrors.name ? 'error' : 'default'}
          required
        />
        <SelectInput
          label={lang(locale).environment}
          options={environmentList?.map(client => client.name)}
          width="100%"
          chosenOpt={environmentList?.find(client => client.id === value.client)?.name}
          onChange={e =>
            onInputChange('client', environmentList?.find(client => client.name === e)?.id)
          }
        />
        <RadioButton
          options={[lang(locale).training, lang(locale).game]}
          activeOption={value.type === 'TRAINING' ? lang(locale).training : lang(locale).game}
          onChange={e => onInputChange('type', e === lang(locale).training ? 'TRAINING' : 'GAME')}
        />
        <StyledCalendar
          label={lang(locale).date}
          className="scheduled-calendar"
          calendarValue={moment(times.date, 'DD/MM/YYYY').valueOf()}
          onChange={e => onInputChange('date', e)}
          onFocus={() => {
            clearError('startRecordingTime');
            clearError('duration');
          }}
          onBlur={() => validateValue('startRecordingTime')}
          disabled={editDisable.current}
          required
        />
        <Panel>
          <TimeScrollPicker
            label={lang(locale)['start time']}
            value={times.time}
            onChange={e => {
              onInputChange('time', e);
            }}
            width="48%"
            onFocus={() => {
              clearError('startRecordingTime');
              clearError('duration');
            }}
            onBlur={() => validateValue('startRecordingTime')}
            errorText={validErrors.startRecordingTime}
            type={validErrors.startRecordingTime ? 'error' : 'default'}
            disabled={editDisable.current}
            required
          />

          <TextInput
            label={lang(locale).duration}
            width="48%"
            value={value.duration}
            onChange={e => onInputChange('duration', e.target.value)}
            onFocus={() => clearError('duration')}
            onBlur={() => validateValue('duration')}
            errorText={validErrors.duration}
            type={validErrors.duration ? 'error' : 'default'}
            disabled={editDisable.current}
            required
          >
            <StyledClockPic />
          </TextInput>
        </Panel>
        <TextInput
          label={lang(locale)['competition name']}
          width="100%"
          value={value.competitionName || ''}
          onChange={e => onInputChange('competitionName', e.target.value)}
          onFocus={() => clearError('competitionName')}
          onBlur={() => validateValue('competitionName')}
          errorText={validErrors.competitionName}
          type={validErrors.competitionName ? 'error' : 'default'}
          disabled={value.type === 'TRAINING'}
          required={value.type === 'GAME'}
        />
        <TextInput
          label={lang(locale).matchDay}
          width="100%"
          value={value.matchDay || ''}
          onChange={e => onInputChange('matchDay', e.target.value)}
          onFocus={() => clearError('matchDay')}
          onBlur={() => validateValue('matchDay')}
          errorText={validErrors.matchDay}
          type={validErrors.matchDay ? 'error' : 'default'}
          disabled={value.type === 'TRAINING'}
          required={value.type === 'GAME'}
        />
        <TextInput
          label={lang(locale)['home team name']}
          width="100%"
          value={value.homeTeamId}
          onChange={e => onInputChange('homeTeamId', e.target.value)}
          onFocus={() => clearError('homeTeamId')}
          onBlur={() => validateValue('homeTeamId')}
          errorText={validErrors.homeTeamId}
          type={validErrors.homeTeamId ? 'error' : 'default'}
          required={value.type === 'GAME'}
          disabled={value.type === 'TRAINING'}
        />
        <TextInput
          label={lang(locale)['away team name']}
          width="100%"
          value={value.awayTeamId}
          onChange={e => onInputChange('awayTeamId', e.target.value)}
          onFocus={() => clearError('awayTeamId')}
          onBlur={() => validateValue('awayTeamId')}
          errorText={validErrors.awayTeamId}
          type={validErrors.awayTeamId ? 'error' : 'default'}
          required={value.type === 'GAME'}
          disabled={value.type === 'TRAINING'}
        />
      </StyledModalInner>
    </ModalContainer>
  );
};

export default ScheduledModal;

const StyledModalInner = styled.div(() => ({
  width: '100%',
  minWidth: '220px',
  '& > div': {
    // marginBottom: '25px',
    minWidth: '220px'
  }
}));

const Panel = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  '& > div': {
    minWidth: '220px',
    position: 'relative',
    marginBottom: '15px',

    '@media(max-width: 650px)': {
      width: '100%',
      marginBottom: '25px',

      '&:last-child': {
        marginBottom: 0
      }
    }
  }
}));

const StyledClockPic = styled(ClockPic)(() => ({
  position: 'absolute',
  right: '12px',
  top: '12px',
  zIndex: zIndexes.cloudPicInModals
}));

const StyledCalendar = styled(Calendar)(() => ({
  '&.scheduled-calendar': {
    width: '48%',
    '@media(max-width: 650px)': {
      width: '100%'
    }
  }
}));

ScheduledModal.propTypes = {
  modalOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  isNew: PropTypes.bool,
  cameraSystemId: PropTypes.string,
  locale: PropTypes.string,
  defaultValue: PropTypes.shape({
    cameraSystemId: PropTypes.string,
    cameraName: PropTypes.string,
    isTraining: PropTypes.bool,
    homeTeamId: PropTypes.string,
    awayTeamId: PropTypes.string,
    startRecordingTime: PropTypes.number,
    endRecordingTime: PropTypes.number,
    recordId: PropTypes.string,
    matchDay: PropTypes.string,
    competitionName: PropTypes.string
  })
};

ScheduledModal.defaultProps = {
  modalOpened: false,
  closeModal: () => {},
  isNew: false,
  cameraSystemId: '',
  locale: 'en-US',
  defaultValue: {
    cameraSystemId: '',
    cameraName: '',
    type: 'training',
    homeTeamId: '',
    awayTeamId: '',
    startRecordingTime: 0,
    endRecordingTime: 0,
    recordId: '',
    matchDay: '',
    competitionName: ''
  }
};
