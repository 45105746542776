import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Button from '../../Components/Button/Button';
import { panelParameters } from '../../Components/TileWrapper/TileWrapper';
import SubHeader from '../../Components/SubHeader/SubHeader';
import { lang } from '../../Langs/langSettings';
import {
  filterInProgressListByCameraIdAction,
  filterToBeUploadedListByCameraIdAction,
  uploadAllRecordingsAction,
  uploadRecordingAction
} from '../../Redux/recordings/actions';
import {
  getInProgressListFilteredByCameraIdSelector,
  getToBeUploadedListFilteredByCameraIdSelector,
  getEventDataSelector
} from '../../Redux/recordings/selectors';
import { colors } from '../../Theme/theme';
import { findCameraId } from '../../Services/api/common';
import RecordTile from '../../Components/RecordTile/RecordTile';
import { getCameraStateByIdAction } from '../../Redux/devices/actions';
import { cameraStateByIdSelector } from '../../Redux/devices/selectors';

const VideoActivity = ({ locale, BodyInner }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const cameraSystemId = findCameraId(location);
  const deviceInfo = useSelector(cameraStateByIdSelector);
  const [showError, setShowError] = useState(false);
  const eventDataSelector = useSelector(getEventDataSelector);

  useEffect(() => {
    dispatch(getCameraStateByIdAction(cameraSystemId));
  }, [eventDataSelector]);

  // const singleRecordUploading = useCallback((recordId) => {
  //   dispatch(uploadRecordingAction(recordId));
  // },[]);
  // const allRecordsUploading = useCallback(() => {
  //   setShowError(true);
  //   dispatch(uploadAllRecordingsAction(cameraSystemId));
  // },[]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }, [showError]);

  useEffect(() => () => window.scrollTo(0, 0), []);

  return (
    <BodyInner>
      <SubHeader subheader={lang(locale)['video library']}>
        {/* <StyledButton
          label={lang(locale)['upload all']}
          onClick={allRecordsUploading}
          width="150px"
          disabled={!(toBeUploadedList.length > 0)}
          marginRight={0}
        /> */}
      </SubHeader>
      <DeviceListBody>
        <PartHeader>{lang(locale)['to be uploaded'].toUpperCase()}</PartHeader>
        {deviceInfo?.toBeUploadedList?.length > 0 ? (
          <>
            {deviceInfo?.toBeUploadedList?.map(item => (
              <RecordTile
                data={item}
                key={item.recordId}
                panelType="toBeUpload"
                // onUploadClick={() => singleRecordUploading(item.recordId)}
                locale={locale}
                showError={showError}
                onPicClickNavLink={`/device-list/${cameraSystemId}/watch/${item.recordId}`}
              />
            ))}
          </>
        ) : (
          <EmptyWarning>{lang(locale)['you have no to-be-uploaded recordings']}</EmptyWarning>
        )}

        <PartHeader>{lang(locale)['in progress'].toUpperCase()}</PartHeader>
        {deviceInfo?.inProgressList?.length > 0 ? (
          <>
            {deviceInfo?.inProgressList?.map(item => (
              <RecordTile
                data={item}
                key={item.recordId}
                panelType="inProgress"
                onPicClickNavLink={`/device-list/${cameraSystemId}/watch/${item.recordId}`}
                locale={locale}
              />
            ))}
          </>
        ) : (
          <EmptyWarning>{lang(locale)['you have no in-progress recordings']}</EmptyWarning>
        )}
      </DeviceListBody>
    </BodyInner>
  );
};

export default VideoActivity;

const DeviceListBody = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  width: '100%',
  minWidth: '230px',
  boxSizing: 'border-box'
}));

const PartHeader = styled.div(() => ({
  width: '100%',
  fontWeight: 500,
  fontSize: '14px',
  letterSpacing: '1.6px',
  color: colors.scheduledGray,
  margin: '24px 0',
  paddingRight: '10px',

  '@media(max-width: 535px)': {
    marginBottom: '15px',
    padding: '0 5px'
  }
}));

const EmptyWarning = styled.div(() => ({
  width: '100%',
  color: colors.disabledBtn,
  padding: '0 10px 20px 0',
  fontSize: '24px',
  height: `${panelParameters.width * (2 / 3) + 20}px`
}));

const StyledButton = styled(Button)(() => ({
  margin: '5px 0'
}));

VideoActivity.propTypes = {
  locale: PropTypes.string
};
VideoActivity.defaultProps = {
  locale: 'en-US'
};
