import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleWare from 'redux-saga';
import devicesReducer from './devices/reducers';
import videosReducer from './recordings/reducers';
import respReducer from './responseHandler/reducers';
import userReducer from './user/reducers';
import { sagaWatcher } from './sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const saga = createSagaMiddleWare();

const store = createStore(
  combineReducers({
    devicesReducer,
    videosReducer,
    respReducer,
    userReducer,
  }),
  composeEnhancers(applyMiddleware(saga))
);

export const state = store.getState();

saga.run(sagaWatcher);

export default store;
