import HttpClient from './httpClient';

const baseURL = process.env.REACT_APP_SERVER_URL;
const authURL = process.env.REACT_APP_AUTH0_AUDIENCE;
const timeout = 30000;

const httpCore = new HttpClient({ baseURL, timeout });
export default httpCore;

export const auth0Core = new HttpClient({ baseURL: authURL, timeout });
