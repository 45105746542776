import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors } from '../../Theme/theme';

const btnParams = {
  default: {
    color: colors.white,
    background: colors.defaultPurpleBtn,
    defaultBorder: 'none',
    hover: colors.hoverPurpleBtn,
    pressedBackground: colors.activePurpleBtn,
    pressedBorder: `0px 0px 0px 4px ${colors.pressedBtnBorder}`,
    disabledBackground: colors.disabledBtn,
    disabledColor: colors.white,
    disabledBorder: 'none',
    borderRadius: '2px',
  },
  white: {
    color: colors.panelsText,
    background: colors.white,
    defaultBorder: `0px 0px 0px 1px ${colors.disabledBtn}`,
    hover: colors.hoverWhiteBtn,
    pressedBackground: colors.disabledPanel,
    pressedBorder: `0px 0px 0px 1px ${colors.disabledBtn}`,
    disabledBackground: colors.disabledBtn,
    disabledColor: colors.pressedBtnBorder,
    disabledBorder: 'none',
    borderRadius: '2px',
  },
  black: {
    color: colors.white,
    background: colors.defaultBlackBtn,
    defaultBorder: 'none',
    hover: colors.hoverBlackBtn,
    pressedBackground: colors.defaultBlackBtn,
    pressedBorder: `0px 0px 0px 4px ${colors.pressedBtnBorder}`,
    disabledBackground: colors.disabledBtn,
    disabledColor: colors.white,
    disabledBorder: 'none',
    borderRadius: '2px',
  },
  panel: {
    color: colors.panelsText,
    background: colors.defaultPanelsButton,
    defaultBorder: 'none',
    hover: colors.hoverPanelsBtn,
    pressedBackground: colors.defaultPanelsButton,
    pressedBorder: 'none',
    disabledBackground: colors.disabledPanelBtn,
    disabledColor: colors.disabledBtn,
    disabledBorder: 'none',
    borderRadius: '2px',
  },
  connect: {
    color: colors.panelsText,
    background: colors.white,
    defaultBorder: `0px 0px 0px 1px ${colors.panelsText}`,
    hover: colors.hoverWhiteBtn,
    pressedBackground: colors.white,
    pressedBorder: `0px 0px 0px 1px ${colors.focusedInputBorder}`,
    disabledBackground: colors.disabledBtn,
    disabledColor: colors.pressedBtnBorder,
    disabledBorder: `0px 0px 0px 1px ${colors.disabledBtn}`,
    borderRadius: '25px',
  },
  stopRecording: {
    color: colors.recordingDot,
    background: colors.white,
    defaultBorder: `0px 0px 0px 1px ${colors.recordingDot}`,
    hover: '#FFEFF0',
    pressedBackground: colors.white,
    pressedBorder: `0px 0px 0px 1px ${colors.recordingDot}`,
    disabledBackground: colors.disabledBtn,
    disabledColor: colors.white,
    disabledBorder: `0px 0px 0px 1px ${colors.disabledBtn}`,
    borderRadius: '0',
  }
};

const Button = ({
  width, height, label, onClick, type, disabled, lettersSpaced, className, visible
}) => (
  <StyledButton
    width={width}
    height={height}
    onClick={onClick}
    type={type}
    disabled={disabled}
    lettersSpaced={lettersSpaced}
    className={className}
    visible={visible}
  >
    {label}
  </StyledButton>
);
export default Button;

const StyledButton = styled.div((props) => ({
  display: props.visible ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: btnParams[props.type].borderRadius,
  boxShadow: btnParams[props.type].defaultBorder,
  cursor: 'pointer',
  fontSize: props.lettersSpaced ? '11px' : '14px',
  fontWeight: 500,
  color: btnParams[props.type].color,
  backgroundColor: btnParams[props.type].background,
  width: props.width,
  height: props.height,
  margin: '5px',
  padding: '0 7px',
  letterSpacing: props.lettersSpaced ? '1.6px' : 'none',

  '&:hover:not([disabled])': {
    backgroundColor: btnParams[props.type].hover,
    transition: '0.3s',
  },

  '&:active:not([disabled]), &:active:hover:not([disabled])': {
    backgroundColor: btnParams[props.type].pressedBackground,
    boxShadow: btnParams[props.type].pressedBorder,
    color: props.type === 'connect' && colors.focusedInputBorder,
  },

  '&[disabled]': {
    backgroundColor: btnParams[props.type].disabledBackground,
    color: btnParams[props.type].disabledColor,
    cursor: 'default',
    boxShadow: btnParams[props.type].disabledBorder,
  },

}));

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), 
  height: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['default', 'white', 'black', 'panel', 'connect', 'stopRecording']), // style type
  disabled: PropTypes.bool,
  lettersSpaced: PropTypes.bool,
  className: PropTypes.string,
  visible: PropTypes.bool,
};

Button.defaultProps = {
  label: 'Button label',
  height: '35px',
  width: 'fit-content',
  onClick: () => {},
  type: 'default',
  disabled: false,
  lettersSpaced: false,
  className: '',
  visible: true,
};
