const videosType = {
  filterSchedule: 'FILTER_SCHEDULE',
  updateNearestSchedules: 'UPDATE_NEAREST_SCHEDULES',
  // getScheduleList: 'GET_SCHEDULE_LIST',
  updateScheduleList: 'UPDATE_SCHEDULE_LIST',
  createSchedule: 'CREATE_SCHEDULE',
  deleteSchedule: 'DELETE_SCHEDULED',
  editSchedule: 'EDIT_SCHEDULED',
  updateEventData: 'UPDATE_EVENT_DATA',
  filterScheduleListByCameraId: 'FILTER_SCHEDULE_LIST_BY_CAMERA_ID',
  filterInProgressListByCameraId: 'FILTER_IN_PROGRESS_LIST_BY_CAMERA_ID',
  filterToBeUploadedListByCameraId: 'FILTER_TO_BE_UPLOADED_LIST_BY_CAMERA_ID',

  // getRecordingList: 'GET_RECORDING_LIST',
  updateRecordingList: 'UPDATE_RECORDING_LIST',
  startRecording: 'START_RECORDING',
  stopRecording: 'STOP_RECORDING',
  // editRecording: 'EDIT_RECORDING',
  uploadRecording: 'UPLOAD_RECORDING',
  uploadAllRecordings: 'UPLOAD_ALL_RECORDINGS',
  deleteRecording: 'DELETE_RECORDING',
  findScheduledRecordingById: 'FIND_SCHEDULED_RECORDING_BY_ID',
  findProcessingRecordingById: 'FIND_PROCESSING_RECORDING_BY_ID'
};

export default videosType;
