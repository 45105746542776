import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors } from '../../Theme/theme';

// Screen part with page name 
const SubHeader = ({ subheader, children }) => (
  <StyledSubHeader>
    <div>{subheader}</div>
    <div>{children}</div>
  </StyledSubHeader>
);

export default SubHeader;

const StyledSubHeader = styled.div(() => ({
  width: '100%',
  height: '80px',
  fontSize: '24px',
  color: colors.panelsText,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  
  '& > div:first-child':{
    marginRight: '7px',
    fontWeight: 400,
  },

  '@media (max-width: 535px)': {
    height: '60px',
    lineHeight: '30px',
    textAlign: 'center',
    fontSize: '20px',
  },
}));

SubHeader.propTypes = {
  subheader: PropTypes.string,
  children: PropTypes.node,
};

SubHeader.defaultProps = {
  subheader: '',
  children: React.createElement('div'),
};