import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors, zIndexes } from '../../../Theme/theme';
import { ReactComponent as Arrow } from '../../../assets/big-panorama-right-button.svg';

const MainScrollPicker = ({ options, onChange, pickerValue }) => {
  const [ind, setInd] = useState(options.indexOf(pickerValue));
  const [moveState, setMoveState] = useState('stop');   // up, down or stop
  const screenHeight = window.innerHeight;

  const value = useRef(pickerValue);
  const counter = useRef(null);

  useEffect(() => {
    value.current = options[ind];
  }, [ind]);

  useEffect(() => {
    onChange(value.current);
  }, [value.current]);

  useEffect(() => {
    setInd(options.indexOf(pickerValue));
  }, [pickerValue]);

  // creates an array: [previous value, chosen value, next value]
  const showArray = () => [
    ind === 0 ? options[options.length - 1] : options[ind - 1],
    options[ind],
    ind === options.length - 1 ? options[0] : options[ind + 1]
  ];
  // functions to change value
  const moveDown = useCallback(() => {
    setInd(prev => (options.length > prev + 1 ? prev + 1 : 0));
  }, []);

  const moveUp = useCallback(() => {
    setInd(prev => (prev > 0 ? prev - 1 : options.length - 1));
  }, []);

  // function to auto change value if hold mouse down
  const repeater = upOrDown => {
    upOrDown === 'up' ? moveUp() : moveDown();
    counter.current = setTimeout(() => {
      repeater(upOrDown);
    }, 200);
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (moveState === 'up') {
      repeater('up');
    }
    if (moveState === 'down') {
      repeater('down');
    }
    if (moveState === 'stop') {
      clearTimeout(counter.current);
    }
  }, [moveState]);

  const findNodeToScroll = el => {
    const res = el;
    if (el.classList.value.split(' ').indexOf('modal-wrapper') !== -1) {
      return res;
    }
    return findNodeToScroll(el.parentNode);
  };

  // moves the screen if picker is overflowed
  const outOfScreenHandler = el => {
    const diff = screenHeight - el.getBoundingClientRect().bottom;
    if (diff < 0) {
      findNodeToScroll(el).scrollBy({
        top: -diff + 50,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <PickerField>
      <UpArrow
        onMouseDown={() => setMoveState('up')}
        onMouseUp={() => setMoveState('stop')}
        onMouseOver={() => setMoveState('stop')}
      />
      <PickerBody
        ref={el => {
          if (!el) return;
          outOfScreenHandler(el);
        }}
      >
        <CoverPane />
        {showArray().map(val => (
          <PickerValue key={`${val}value`} active={val === pickerValue}>
            {val}
          </PickerValue>
        ))}
      </PickerBody>
      <DownArrow
        onMouseDown={() => setMoveState('down')}
        onMouseUp={() => setMoveState('stop')}
        onMouseOver={() => setMoveState('stop')}
      />
    </PickerField>
  );
};

const PickerField = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.white,
  width: 'fit-content'
}));

const PickerBody = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50px',
  position: 'relative'
}));

const CoverPane = styled.div(() => ({
  background:
    'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%)',
  position: 'absolute',
  zIndex: zIndexes.inputBackground,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}));

const PickerValue = styled.div(props => ({
  width: '100%',
  height: '30px',
  color: props.active ? colors.activePurpleBtn : colors.defaultBlackBtn,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: props.active ? 600 : 400
}));

const UpArrow = styled(Arrow)(() => ({
  height: '20px',
  cursor: 'pointer',
  fill: colors.defaultBlackBtn,
  transform: 'rotate(-90deg)'
}));
const DownArrow = styled(Arrow)(() => ({
  height: '20px',
  cursor: 'pointer',
  fill: colors.defaultBlackBtn,
  transform: 'rotate(90deg)'
}));

export default MainScrollPicker;

MainScrollPicker.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  pickerValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
MainScrollPicker.defaultProps = {
  options: [],
  onChange: () => {},
  pickerValue: ''
};
