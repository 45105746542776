import { filterArray } from '../../Services/api/common';
import videosType from './consts';

const scheduledList = [];
const filteredSchedule = [];
const nearestSchedule = [];
const toBeUploadedList = [];
const inProgressList = [];

const eventData = {};
const scheduledRecordingById = null;
const processedRecordingById = null;

const defaultState = {
  scheduledList,
  filteredSchedule,
  nearestSchedule,
  toBeUploadedList,
  inProgressList,
  eventData,
  scheduledRecordingById,
  processedRecordingById
};

// eslint-disable-next-line default-param-last
function videosReducer(state = defaultState, action) {
  switch (action.type) {
    case videosType.updateScheduleList: {
      const res =
        action.payload.length > 0
          ? action.payload.sort((a, b) => a.startRecordingTime - b.startRecordingTime)
          : [];
      return { ...state, scheduledList: res, nearestSchedule: res };
    }

    case videosType.updateRecordingList: {
      const toBeUpload =
        action.payload.length > 0
          ? action.payload
              .filter(item => item.recorded)
              ?.sort((a, b) => a.startRecordingTime - b.startRecordingTime)
          : [];
      const inProgress =
        action.payload.length > 0
          ? action.payload
              .filter(item => !item.recorded)
              ?.sort((a, b) => a.startRecordingTime - b.startRecordingTime)
          : [];
      return {
        ...state,
        toBeUploadedList: toBeUpload,
        inProgressList: inProgress
      };
    }
    case videosType.updateEventData: {
      return {
        ...state,
        eventData: action.payload
      };
    }

    case videosType.findScheduledRecordingById: {
      const res = filterArray(state.scheduledList, 'recordId', action.payload);
      return {
        ...state,
        scheduledRecordingById: res
      };
    }
    case videosType.findProcessingRecordingById: {
      const processingArray = [state.toBeUploadedList, state.inProgressList];
      const res = filterArray(processingArray, 'recordId', action.payload);
      return {
        ...state,
        processedRecordingById: res
      };
    }

    default:
      return state;
  }
}

export default videosReducer;