import videosType from './consts';

export const filterScheduleAction = payload => ({
  type: videosType.filterSchedule,
  payload
});

export const updateNearestScheduleAction = () => ({
  type: videosType.updateNearestSchedules
});

export const getScheduleListAction = () => ({
  type: videosType.getScheduleList
});
export const updateScheduleListAction = payload => ({
  type: videosType.updateScheduleList,
  payload
});

export const createScheduleAction = payload => ({
  type: videosType.createSchedule,
  payload
});

export const deleteScheduleAction = payload => ({
  type: videosType.deleteSchedule,
  payload
});

export const editScheduleAction = payload => ({
  type: videosType.editSchedule,
  payload
});

export const getRecordingListAction = payload => ({
  type: videosType.getRecordingList,
  payload
});

export const updateRecordingListAction = payload => ({
  type: videosType.updateRecordingList,
  payload
});

export const updateEventDataAction = payload => ({
  type: videosType.updateEventData,
  payload
});

export const filterInProgressListByCameraIdAction = payload => ({
  type: videosType.filterInProgressListByCameraId,
  payload
});
export const filterScheduleListByCameraIdAction = payload => ({
  type: videosType.filterScheduleListByCameraId,
  payload
});
export const filterToBeUploadedListByCameraIdAction = payload => ({
  type: videosType.filterToBeUploadedListByCameraId,
  payload
});

export const startRecordingAction = payload => ({
  type: videosType.startRecording,
  payload
});

export const stopRecordingAction = payload => ({
  type: videosType.stopRecording,
  payload
});

export const editRecordingAction = payload => ({
  type: videosType.editRecording,
  payload
});

export const uploadRecordingAction = payload => ({
  type: videosType.uploadRecording,
  payload
});

export const uploadAllRecordingsAction = payload => ({
  type: videosType.uploadAllRecordings,
  payload
});

export const findScheduledRecordingByIdAction = payload => ({
  type: videosType.findScheduledRecordingById,
  payload
});
export const findProcessingRecordingByIdAction = payload => ({
  type: videosType.findProcessingRecordingById,
  payload
});
