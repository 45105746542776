import respType from './consts';

const message = {
  type: '',
  request: ''
};
const defaultState = {
  message
};

function respReducer(state = defaultState, action) {
  switch (action.type) {
    case respType.handleResponse:
      return { ...state, message: action.payload };
    default:
      return state;
  }
}

export default respReducer;
