import moment from 'moment';

export const isNumber = string => {
  const str = `${string}`;
  const invalidChars = ['-', '+', 'e', '.'];
  return Number.isFinite(+string) && invalidChars.findIndex(item => str.includes(item)) === -1;
};

export const isEmpty = string => (string === '' ? 'isEmptyError' : '');

export const numberInBetween = (value, min, max) => {
  if (value < min) return 'Less then min error';
  if (value > max) return 'More than max error';
  return '';
};

export const isInThePast = time => (moment().valueOf() >= time ? 'pastError' : '');

export const isOverlapping = (id, time, scheduleList) => {
  const filteredList = scheduleList.filter(item => item.recordId !== id) || [];
  const ind = filteredList.findIndex(
    item => item.startRecordingTime - 5 * 60000 <= time && item.endRecordingTime + 5 * 60000 >= time
  );
  return ind !== -1 ? { isOverlap: true, record: filteredList[ind] } : { isOverlap: false };
};
export const isAroundOverlapping = (id, start, end, scheduleList) => {
  const filteredList = scheduleList.filter(item => item.recordId !== id);
  const ind = filteredList.findIndex(
    item => item.startRecordingTime >= start && item.endRecordingTime <= end
  );
  return ind !== -1 ? { isOverlap: true, record: filteredList[ind] } : { isOverlap: false };
};

export const isStringStartsWith = (string, symbol) => {
  if (!string || !symbol) {
    return false;
  }
  return string.toString()[0] === symbol.toString();
};
