import Eng from './en-US.json';
import Esp from './es-ES.json';
import Deu from './de-DE.json';

// eslint-disable-next-line import/no-mutable-exports
export const lang = (local = 'en-US') => {
  let res;
  switch (local) {
    case 'es-ES': {
      res = Esp;
      break;
    }
    case 'de-DE': {
      res = Deu;
      break;
    }
    default:
      res = Eng;
  }
  return res;
};
