import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import ModalContainer from '../../Components/ModalContainer';
import { lang } from '../../Langs/langSettings';
import { stopRecordingAction } from '../../Redux/recordings/actions';

const StopRecordModal = ({ modalOpened, closeModal, recordId, locale, handleDisable }) => {
  const dispatch = useDispatch();
  const applyButton = {
    label: lang(locale).stop,
    onClick: () => {
      dispatch(stopRecordingAction({ recordId }));
      handleDisable();
      closeModal();
    }
  };
  return (
    <ModalContainer
      isOpened={modalOpened}
      closeModal={closeModal}
      headerText={lang(locale)['are you sure you want to stop this record']}
      footerButton={applyButton}
      width="460px"
      locale={locale}
    />
  );
};

export default StopRecordModal;

StopRecordModal.propTypes = {
  modalOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  recordId: PropTypes.string,
  locale: PropTypes.string
};

StopRecordModal.defaultProps = {
  modalOpened: false,
  closeModal: () => {},
  recordId: '',
  locale: 'en-US'
};
