import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, zIndexes } from '../../../Theme/theme';
import { ReactComponent as BigPanoramaLeftArrow } from '../../../assets/big-panorama-left-button.svg';
import msePlayer from '../PlayerSystems/Mse-player';

const DifferentCameras = ({ autoplay, streamLinks, videoIds, isVisible = true }) => {
  const minScreenWidth = 180;
  const widthHeightRatio = 3 / 5;
  const [windowParams, setWindowParams] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [activePanel, setActivePanel] = useState(1);
  const [pos, setPos] = useState(0);

  const updateWidthAndHeight = useCallback(() => {
    setWindowParams({ width: window.innerWidth, height: window.innerHeight });
  }, [window]);

  const resizeWindow = useCallback(() => {
    if (windowParams.height < 300) {
      return minScreenWidth;
    }
    switch (true) {
      case windowParams.width >= minScreenWidth * 3:
        return windowParams.width / 3;
      default:
        return minScreenWidth;
    }
  }, [windowParams]);

  const [width, setWidth] = useState(resizeWindow());
  const [height, setHeight] = useState(resizeWindow() * widthHeightRatio);

  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  }, []);

  useEffect(() => {
    setWidth(resizeWindow());
    setHeight(resizeWindow() * widthHeightRatio);
    if (windowParams.width >= minScreenWidth * 3) {
      setPos(0);
      setActivePanel(1);
    }
  }, [resizeWindow, windowParams.width]);

  const moveFrame = useCallback(
    side => {
      if (side === 'right' && activePanel < 2) {
        setActivePanel(prev => prev + 1);
        setPos(prev => prev + minScreenWidth);
      }
      if (side === 'left' && activePanel > 0) {
        setActivePanel(prev => prev - 1);
        setPos(prev => prev - minScreenWidth);
      }
    },
    [setActivePanel, activePanel]
  );

  useEffect(() => {
    try {
      streamLinks && streamLinks[0] && msePlayer(streamLinks, videoIds);
    } catch (error) {
      console.log(error);
    }
  }, [streamLinks]);

  return (
    <PanelsInner pos={pos} height={height} isVisible={isVisible}>
      <Panel width={width} height={height} minScreenWidth={minScreenWidth}>
        <OverflowCover // shadow with move button appears on side screen when small width
          visible={windowParams.width < minScreenWidth * 3 && activePanel <= 1}
          windowParams={windowParams.width}
          minScreenWidth={minScreenWidth}
          widthHeightRatio={widthHeightRatio}
          pos="left"
        >
          <PanelMoveButton pos="left" onClick={() => moveFrame('right')} />
        </OverflowCover>
        <video id="live1" autoPlay={autoplay} playsInline muted width={width} height={height} />
      </Panel>
      <Panel width={width} height={height} minScreenWidth={minScreenWidth}>
        <OverflowCover
          visible={windowParams.width < minScreenWidth * 3 && activePanel === 0}
          windowParams={windowParams.width}
          minScreenWidth={minScreenWidth}
          widthHeightRatio={widthHeightRatio}
          pos="left"
        >
          <PanelMoveButton pos="left" onClick={() => moveFrame('right')} />
        </OverflowCover>
        <OverflowCover
          visible={windowParams.width < minScreenWidth * 3 && activePanel === 2}
          windowParams={windowParams.width}
          minScreenWidth={minScreenWidth}
          widthHeightRatio={widthHeightRatio}
          pos="right"
        >
          <PanelMoveButton pos="right" onClick={() => moveFrame('left')} />
        </OverflowCover>

        <video id="live2" autoPlay={autoplay} playsInline muted width={width} height={height} />
      </Panel>
      <Panel width={width} height={height} minScreenWidth={minScreenWidth}>
        <OverflowCover
          visible={windowParams.width < minScreenWidth * 3 && activePanel >= 1}
          windowParams={windowParams.width}
          minScreenWidth={minScreenWidth}
          widthHeightRatio={widthHeightRatio}
          pos="right"
        >
          <PanelMoveButton pos="right" onClick={() => moveFrame('left')} />
        </OverflowCover>

        <video id="live3" autoPlay={autoplay} playsInline muted width={width} height={height} />
      </Panel>
    </PanelsInner>
  );
};

export default DifferentCameras;

const PanelsInner = styled.div(props => ({
  display: props.isVisible ? 'flex' : 'none',
  flexDirection: 'row',
  width: '100%',
  minWidth: '600px',
  justifyContent: 'center',
  alignItems: 'center',
  left: props.pos,
  height: `${props.height}px`
}));

const Panel = styled.div(props => ({
  width: props.width,
  height: props.height,
  minWidth: props.minScreenWidth,
  // boxShadow: '0px 10px 30px rgb(38 41 64 / 15%)',
  position: 'relative'
}));

const OverflowCover = styled.div(props => ({
  display: props.visible ? 'flex' : 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: `linear-gradient(${
    props.pos === 'left' ? '90deg' : '270deg'
  }, rgba(0, 0, 0, 0.58) 0%, rgba(255, 255, 255, 0) 100%)`,
  zIndex: 1,
  '@media(max-width: 400px)': {
    background: `linear-gradient(${
      props.pos === 'left' ? '90deg' : '270deg'
    }, rgba(0, 0, 0, 0.58) 50%, rgba(255, 255, 255, 0) 100%)`
  },
  '@media(max-width: 300px)': {
    background: `linear-gradient(${
      props.pos === 'left' ? '90deg' : '270deg'
    }, rgba(0, 0, 0, 0.58) 70%, rgba(255, 255, 255, 0) 100%)`
  }
}));

const PanelMoveButton = styled(BigPanoramaLeftArrow)(props => ({
  right: props.pos === 'right' && '50%',
  left: props.pos === 'left' && '50%',
  transform: props.pos === 'right' && 'rotate(180deg)',
  fill: colors.white,
  borderRadius: '50%',
  position: 'absolute',
  top: '40%',
  width: '35px',
  height: '35px',
  cursor: 'pointer',
  zIndex: zIndexes.videoPlayerMovePaneButton,
  padding: ' 5px 5px 5px 0',
  '@media(max-width: 400px)': {
    right: props.pos === 'right' && '70%',
    left: props.pos === 'left' && '70%'
  },
  '@media(max-width: 300px)': {
    right: props.pos === 'right' && '80%',
    left: props.pos === 'left' && '80%'
  },

  '@media(orientation: landscape)': {
    '&:hover': {
      transition: '0.3s',
      backgroundColor: 'RGBA(0, 0, 0, 0.3)'
    }
  },
  '&:active:hover': {
    transition: '0.3s',
    backgroundColor: 'RGBA(0, 0, 0, 0.5)'
  }
}));
