import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../Components/ModalContainer';
import { lang } from '../../Langs/langSettings';
import { deleteScheduleAction } from '../../Redux/recordings/actions';

const DeleteRecordModal = ({
  modalOpened,
  closeModal,
  recordId,
  isSchedule,
  locale,
  cameraSystemId,
  nearestScheduled
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applyButton = {
    label: lang(locale).delete,
    onClick: () => {
      if (isSchedule) {
        dispatch(deleteScheduleAction({ recordId }));
        !nearestScheduled && navigate(`/device-list/${cameraSystemId}/scheduled-recordings`);
        closeModal();
      }
      // dispatch(deleteRecordingAction({ recordId }));
      // navigate(`/device-list/${cameraSystemId}/video-library`);
      // closeModal();
    }
  };
  return (
    <ModalContainer
      isOpened={modalOpened}
      closeModal={closeModal}
      headerText={lang(locale)['are you sure you want to delete this record']}
      footerButton={applyButton}
      width="460px"
      locale={locale}
    />
  );
};

export default DeleteRecordModal;

DeleteRecordModal.propTypes = {
  modalOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  recordId: PropTypes.string,
  isSchedule: PropTypes.bool,
  locale: PropTypes.string,
  cameraSystemId: PropTypes.string,
};

DeleteRecordModal.defaultProps = {
  modalOpened: false,
  closeModal: () => {},
  recordId: '',
  isSchedule: false,
  locale: 'en-US',
  cameraSystemId: ''
};
