import React, { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as ExitPic } from '../../assets/logout.svg';
import { lang } from '../../Langs/langSettings';
import { colors } from '../../Theme/theme';
import SelectInput from '../Inputs/SelectInput';

// converts URL to http-format for Auth0
const convertSymbols = (string, symbol, replacement) => {
  const res = string.split('');
  if (res.indexOf(symbol) === -1) {
    return res.join('');
  }
  res.splice(res.indexOf(symbol), 1, replacement);
  return convertSymbols(res.join(''), symbol, replacement);
};
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const symbolsAndReplacement = [
  {
    symbol: ':',
    replacement: '%3A'
  },
  {
    symbol: '/',
    replacement: '%2F'
  }
];
const UserPanel = ({ openedPanel, updateLang, locale, userName }) => {
  const { user } = useAuth0();
  const convertLocale = {
    'en-US': lang(locale).english,
    'es-ES': lang(locale).spanish,
    'de-DE': lang(locale).german
  };

  let convertedUrl = window.location.origin;
  symbolsAndReplacement.forEach(
    // eslint-disable-next-line no-return-assign
    item => (convertedUrl = convertSymbols(convertedUrl, item.symbol, item.replacement))
  );

  const logOut = useCallback(async () => {
    window.location.assign(
      `${domain.startsWith('http') ? '' : 'https://'}${domain}/v2/logout?returnTo=${convertedUrl}`
    );
  }, []);

  const phrases = {
    hiUserPh: `${lang(locale).hi}, ${userName || lang(locale).user}!`,
    emailPh: lang(locale).email.toUpperCase(),
    langSettingsPh: lang(locale)['language settings'].toUpperCase(),
    sessionPh: lang(locale).session.toUpperCase(),
    logoutPh: lang(locale).logout
  };

  return (
    <UserPane opened={openedPanel} id="user-panel">
      <span>
        {phrases.hiUserPh.slice(0, 23)}
        {phrases.hiUserPh.length > 23 && '..!'}
      </span>
      <PaneSection>
        <span>{phrases.emailPh}</span>
        {user?.email?.slice(0, 28) || 'user@kognia.com'}
        {user?.email?.length > 28 && '...'}
      </PaneSection>

      <PaneSection>
        <span>{phrases.langSettingsPh}</span>
        <SelectInput
          width="100%"
          options={[lang(locale).english, lang(locale).spanish, lang(locale).german]}
          chosenOpt={convertLocale[locale]}
          onChange={e => updateLang(e)}
        />
      </PaneSection>

      <PaneSection>
        <span>{phrases.sessionPh}</span>
        <LogOutSection onClick={logOut}>
          <ExitPic />
          <span>{phrases.logoutPh}</span>
        </LogOutSection>
      </PaneSection>
    </UserPane>
  );
};

export default UserPanel;

const UserPane = styled.div(props => ({
  display: props.opened ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'space-around',
  position: 'absolute',
  top: '35px',
  right: '0',
  width: '300px',
  height: '250px',
  border: `1px solid ${colors.disabledBtn}`,
  borderRadius: '4px',
  backgroundColor: colors.white,
  padding: '10px 20px',
  cursor: 'default',
  '@media(max-width: 320px)': {
    width: '230px',
    padding: '10px'
  },
  '& > span': {
    fontSize: '20px'
  }
}));

const PaneSection = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& > span': {
    fontSize: '11px',
    lineHeight: '15px',
    fontWeight: 500,
    color: colors.scheduledGray,
    marginBottom: '7px'
  }
}));

const LogOutSection = styled.div(() => ({
  fontWeight: 400,
  fontSize: '14px',
  color: colors.scheduledGray,
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  cursor: 'pointer',
  '&:hover': {
    fontWeight: 500
  },
  '&:active': {
    color: colors.defaultBlackBtn
  },
  '& > span': {
    paddingLeft: '8px'
  }
}));

UserPanel.propTypes = {
  locale: PropTypes.string,
  updateLang: PropTypes.func,
  openedPanel: PropTypes.bool,
  userName: PropTypes.string
};
UserPanel.defaultProps = {
  locale: 'en-US',
  updateLang: () => {},
  openedPanel: false,
  userName: ''
};
